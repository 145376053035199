import * as React from 'react';
import { Radio } from '@mui/material';
import useScreen from '../hooks/useScreen';

const RadioWrapper = (props) => {
  const { width } = useScreen();
  return (
    <Radio
      {...props}
      sx={{
        color: 'yellow',
        '&.Mui-checked': {
          color: 'yellow',
        },
      }}
    />
  );
};

export default RadioWrapper;
