import React from "react";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();

  const redirectBack = () => {
    return navigate(-1);
  };

  return (
    <div
      className=" d-flex flex-column justify-content-center align-items-center pageNotFound-404 cursor-pointer"
      onClick={() => redirectBack()}
    >
      <h1 className="theme-text-color">404</h1>
      <h2 className="theme-text-color">Page Not Found!</h2>
    </div>
  );
}
