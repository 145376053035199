import API from '../config/api.config';

const login = async (data) => {
  return await API.post('/user/signin', data);
};

const saveWallet = async (data) => {
  return await API.post('/user/signin', data);
};

const getAllWallets = () => {
  return API.get(`/user/user-wallets`).then((res) => {
    return res.data;
  });
};

const getWalletsFromAdressBook = (current_page = 1, limit = 4) => {
  return API.get(
    `/user/get-wallets-from-adress-book/${current_page}/${limit}`
  ).then((res) => {
    return res.data;
  });
};

const getAllWalletsFromAdressBook = () => {
  return API.get(`/user/get-all-wallets-from-adress-book`).then((res) => {
    return res.data;
  });
};

const storeWalletsFromAdressBook = (data) => {
  return API.patch('/user/add-wallet-in-adress-book', data).then((res) => {
    return res.data;
  });
};

const removeWallet = (data) => {
  return API.patch(`/user/remove-wallet`, data).then((res) => {
    return res.data;
  });
};

const removeWalletFromAddressBook = (wallet_address) => {
  return API.delete(
    `/user/remove-wallets-from-adress-book/${wallet_address}`
  ).then((res) => {
    return res.data;
  });
};

const WalletService = {
  getAllWallets,
  removeWallet,
  getWalletsFromAdressBook,
  storeWalletsFromAdressBook,
  removeWalletFromAddressBook,
  getAllWalletsFromAdressBook,
};

export default WalletService;
