import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { NftSwiper, PreLoaderComponent2 } from '../../components';
import {
  Chip,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Tabs,
  Tab,
  Typography,
  TextField,
  Fab,
  IconButton,
  Button,
  Autocomplete,
  Modal,
  Avatar,
} from '@mui/material';
import PropTypes from 'prop-types';
import { HuePicker, SketchPicker } from 'react-color';
import {
  backgroundIcon,
  deleteIcon,
  deselectIcon,
  flipHorizontalIcon,
  flipVerticalIcon,
  nft,
  textIcon,
  noImageSelected,
} from '../../utils/images';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  AuthService,
  WalletService,
  ThirdPartyService,
  NftService,
  MergeService,
  UserService,
  ArtworkService,
} from '../../services';
import Swal from 'sweetalert2';
import ErrorFormat from '../../utils/ErrorFormat;';
import { fabric } from 'fabric';
import {
  sendBackIcon,
  sendBackwardIcon,
  bringForwardIcon,
  bringFrontIcon,
} from '../../utils/images';
import FormValidator from '../../utils/validation';
import { downloadIcon } from '../../utils/images';
import { Swiper, SwiperSlide } from 'swiper/react';
import dateFormat from 'dateformat';
import { StorageService } from '../../services';
import useScreen from '../../hooks/useScreen';
import TextFieldWrapper from '../../components/TextFieldWrapper';
import TextFieldWrapperDark from '../../components/TextFieldWrapperDark';
import TooltipWrapper from '../../components/TooltipWrapper';
import { wait } from '../../utils/constants';
import AutocompleteField from '../../components/AutocompleteField';
import {
  Cancel,
  CheckCircle,
  Clear,
  ExpandMore,
  HighlightOff,
} from '@mui/icons-material';
import { Navigation } from 'swiper';

const style = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: '4rem',
  width: '90%',
  bgcolor: '#ffff00',
  boxShadow: 24,
  p: 3,
  borderRadius: '15px',
  '& .MuiTextField-root': { my: 1 /*  width: '25ch' */ },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CreateMerger = () => {
  const navigate = useNavigate();
  const { width } = useScreen();
  const [value, setValue] = useState(0);
  const [color, setColor] = useState('#ffff00');
  const [colors, setColors] = useState(['#faff00', '#a600ff']);
  const [description, setDescription] = useState('');
  const [hashTags, setHashTags] = useState([]);
  const [gradientType, setGradientType] = useState('linear');
  const [format, setFormat] = useState('square');
  const [wallets, setWallets] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [noNftsMessage] = useState('No NFT has been found');
  const [fetchNftsMessage] = useState('Loading!');
  const [nftsLoadingMessage, setNftsLoadingMessage] = useState('noNftsMessage');
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pagenations, setPagenations] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [minNftIndex, setMinNftIndex] = useState(null);
  const [maxNftIndex, setMaxNftIndex] = useState(null);
  const [bgType, setBgType] = useState('color');
  const [backgroundPreview, setBackgroundPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [alias, setAlias] = useState([]);
  const [friendTags, setFriendTags] = useState([]);
  const [toggleFrndTag, setToggleFrndTag] = useState(false);
  const [frndTagsPreview, setFrndTagsPreview] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (bgType === 'picture' && !selectedArtWork._id) {
      return Swal.fire(
        'Error',
        'Please select an artwork, or color, or radiant',
        'error'
      );
    }
    setOpen(true);
  };
  const [image, setImage] = useState(null);
  const [uploadedImgDimensions, setUploadedImgDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [bringForward, setBringForward] = useState(false);
  const [bringBackward, setBringBackward] = useState(false);
  const [bringFront, setBringFront] = useState(false);
  const [bringBack, setBringBack] = useState(false);
  const [canvas, setCanvas] = useState('');
  const [canvasActiveObj, setCanvasActiveObj] = useState({});
  const [selectedType, setSelectedType] = useState(false);
  const [mergePreview, setMergePreview] = useState('');
  const [errors, setErrors] = useState({});
  const [personalSelectedWallet, setPersonalSelectedWallet] = useState([]);
  const fixedOptions = [];
  const [multiValue, setMultiValue] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [newWallet, setNewWallet] = useState('');
  const [walletAlias, setWalletAlias] = useState('');
  const [artworks, setArtworks] = useState([]);
  const [selectedArtWork, setSelectedArtwork] = useState({});
  const [selectedWallets, setSelectedWallets] = useState([]);
  const [canvasWidth, setCanvasWidth] = useState(
    window.innerWidth >= 1800
      ? window.innerWidth / 2.5
      : window.innerWidth >= 1440
      ? window.innerWidth / 3
      : window.innerWidth >= 1024
      ? window.innerWidth / 3
      : window.innerWidth >= 592
      ? window.innerWidth / 2
      : window.innerWidth - 50
  );
  const [canvasHeight, setCanvasHeight] = useState(
    window.innerWidth >= 1800
      ? window.innerWidth / 2.5
      : window.innerWidth >= 1440
      ? window.innerWidth / 3
      : window.innerWidth >= 1024
      ? window.innerWidth / 3
      : window.innerWidth >= 592
      ? window.innerWidth / 2
      : window.innerWidth - 50
  );
  const [swiperRef, setSwiperRef] = useState(null);
  const [nftPagenation, setNftPagenation] = useState({});
  const [nftCurntPage, setNftCurntPage] = useState(1);
  const [nftsLimit, setNftsLimit] = useState(10);
  const [newWalletErrors, setNewWalletErrors] = useState({});
  let renderCount = 0;
  const [hashvalue, setHashValue] = useState('');
  const [artworksLimit, setArtworksLimit] = useState(5);
  const [artworkCurrentPage, setArtworkCurrentPage] = useState(1);
  const [artworkFirstPage, setArtworkFirstPage] = useState(1);
  const [artworkLastPage, setArtworkLastPage] = useState(1);
  const [totalArtworks, setTotalArtworks] = useState(0);
  const [hashValueError, setHashValueError] = useState('');
  const [aliasMessage, setAliasMessage] = useState('');
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [newWalletSubmit, setNewWalletSubmit] = useState(false);
  const [aliasVal, setAliasVal] = useState('');
  const [picsArtKeyInfo, setPicsArtkeyInfo] = useState({});

  const getPicsArtKey = () => {
    ThirdPartyService.getPicsArtApiKey()
      .then((res) => {
        if (res.status) {
          setPicsArtkeyInfo(res.data);
        }
      })
      .catch((error) => {});
  };

  const increasePicsArtAPIHits = () => {
    if (Object.keys(picsArtKeyInfo).length > 0) {
      ThirdPartyService.increaseHitsPicsArtApi(picsArtKeyInfo._id)
        .then((res) => {
          if (res.status) {
            if (res.data.hits > 99) {
              getPicsArtKey();
            }
          }
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    getPicsArtKey();
    if (AuthService.isLoggedIn()) {
      setIsLoading(true);
      UserService.checkUserActiveStatus()
        .then((res) => {
          setIsLoading(false);
          if (res.status === false) {
            StorageService.clearSession();
            navigate('/connect-to-wallet', { reload: true });
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: `<p>Oh no, account deactivated. Please contact to admin at info@nftneighbors.com </p>`,
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === 'User account deactivated'
          ) {
            setIsLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        });
    }
  }, []);

  const toggleModal = () => {
    setOpen(!open);
  };

  const initCanvas = () => {
    let myCanvas = new fabric.Canvas('merge-previwer', {
      width: canvasWidth,
      height: canvasHeight,
    });

    return myCanvas;
  };

  useEffect(() => {
    setCanvas(initCanvas());
  }, []);

  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      navigate('/connect-to-wallet');
    }
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseTag = (tag) => {
    const newTags = hashTags.filter((item) => tag !== item);
    setHashTags(newTags);
  };

  const handleSetRadiant = (color_value, present_index) => {
    if (colors.length > 0) {
      let color_already_exist_on_index = false;
      colors.map((color, index) => {
        if (index === present_index) {
          color_already_exist_on_index = true;
        }
      });
      if (color_already_exist_on_index) {
        let allColors = [];
        for (let i = 0; i < colors.length; i++) {
          if (i === present_index) {
            allColors.push(color_value.hex);
          } else {
            allColors.push(colors[i]);
          }
        }
        return setColors(allColors);
      } else {
        return setColors((oldColors) => [...oldColors, color_value.hex]);
      }
    }
  };

  const [colorMenuIndex, setColorMenuIndex] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMeue = (e, index) => {
    e.preventDefault();
    setOpenMenu(!openMenu);
    setColorMenuIndex(index);
  };
  const menuReference = useRef();
  const handleOpenMenu = (e, index) => {
    e.preventDefault();
    setOpenMenu(true);
    setColorMenuIndex(index);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
    setColorMenuIndex(-1);
  };

  /* useEffect(() => {
    var closeColorMenu = (event) => {
      if (
        menuReference.current &&
        !menuReference.current.contains(event.target)
      ) {
        handleCloseMenu();
      console.log('sasadsa')
      }
    };
    window.addEventListener('mousedown', closeColorMenu);
    return () => {
      window.removeEventListener('mousedown', closeColorMenu);
    };
  }, []); */

  const colorPalletGenerator = () => {
    return (
      colors.length > 0 &&
      colors.map((color, index) => (
        <div
          className='d-flex gap-1 justify-content-between align-items-center py-1'
          key={index}
        >
          <HuePicker
            color={color}
            onChange={(e) => handleSetRadiant(e, index)}
            className='w-100 color-picker'
          />

          <div
            ref={menuReference}
            className='dropdown_container'
            style={{ position: 'relative' }}
            onClick={(e) => {
              index > 1 && toggleMeue(e, index);
            }}
          >
            <div>{selectedColorViewer(index)}</div>
            <div style={{ position: 'absolute', top: 12, right: 12 }}>
              {index > 1 && (
                <TooltipWrapper title='Dropdown'>
                  <ExpandMore className='text-yellow opacity-5 bg-dark border-circle cursor-pointer' />
                </TooltipWrapper>
              )}
            </div>
            {openMenu && colorMenuIndex === index && (
              <div class='dropdown_menu'>
                <ul>
                  <li
                    onClick={(e) => {
                      let colorArray = [...colors];
                      colorArray[index] = '#faff00';
                      setColors([...colorArray]);
                      toggleMeue(e, -1);
                    }}
                  >
                    Reset
                  </li>
                  <li
                    onClick={(e) => {
                      setColors((oldColors) =>
                        oldColors.filter((item, _index) => _index !== index)
                      );
                      toggleMeue(e, -1);
                    }}
                  >
                    Remove
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      ))
    );
  };

  const selectedColorViewer = (index) => {
    if (index !== undefined) {
      if (colors[index] != undefined) {
        return (
          <div
            className='selected-color'
            id={`selected-clr-${index}`}
            style={{
              backgroundColor:
                colors.length > 0 && colors[index] ? colors[index] : '#ffffff',
            }}
          ></div>
        );
      } else {
        return (
          <div className='selected-color' id={`selected-clr-${index}`}></div>
        );
      }
    }
  };

  // useEffect(() => {
  //   setSelectedNfts([]);
  // }, [multiValue])

  useEffect(() => {
    colorPalletGenerator();
  }, [colors]);

  const handlePagenation = (e) => {
    setIsLoading(true);
    const pagenation = document.getElementById('pagenation');
    const nftCards = document.getElementById('nfts-image-cards');
    let pagenationItems = document.getElementsByClassName('pagenation-item');

    nftCards.classList.add('fade');

    for (let i = 0; i < pagenationItems.length; i++) {
      pagenationItems[i].classList.remove('active');
    }

    for (let i = 0; i < pagenationItems.length; i++) {
      e.target.classList.add('active');
    }

    setTimeout(() => {
      nftCards.classList.remove('fade');
    }, 500);
    setIsLoading(false);
  };

  const getAllWallets = async () => {
    setIsLoading(true);
    WalletService.getAllWalletsFromAdressBook()
      .then((res) => {
        setIsLoading(false);
        if (res.status && res.data) {
          setWallets(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        }
      });
  };

  useEffect(() => {
    getAllWallets();
  }, []);

  // refresh nfts
  const getNfts = (wallet_info, wallet_address) => {
    setIsLoading(true);
    setNftsLoadingMessage(fetchNftsMessage);
    if (wallet_address !== undefined) {
      ThirdPartyService.refreshNFT(wallet_address)
        .then((nfts) => {
          setIsLoading(false);
          if (nfts) {
            const data = {
              wallet_info: wallet_info,
              wallet_address: wallet_address,
              assets: nfts.assets,
            };
            setIsLoading(true);
            NftService.storeNFTList(data)
              .then((res) => {
                setIsLoading(false);
              })
              .catch((error) => {
                setNftsLoadingMessage(noNftsMessage);
                setIsLoading(false);
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                ) {
                  if (
                    error.response.data.message === 'User account deactivated'
                  ) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Inactive account!',
                      html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
                    }).then(() => {
                      StorageService.clearSession();
                      navigate('/connect-to-wallet');
                      return window.location.reload();
                    });
                  }
                }
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.message === 'User account deactivated') {
              Swal.fire({
                icon: 'warning',
                title: 'Inactive account!',
                html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
              }).then(() => {
                StorageService.clearSession();
                navigate('/connect-to-wallet');
                return window.location.reload();
              });
            }
          }
        });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    FormValidator.handleOnSubmit('title', setErrors);
    FormValidator.handleOnSubmit('description', setErrors);
    return setIsFormSubmit(true);
  };

  const saveMergeRequest = (e) => {
    setIsFormSubmit(false);
    let noOfErrors = 0;
    let firstKey;

    const defaultHeight = canvas.height;
    const defaultWidth = canvas.width;

    if (selectedWallets.length === 0) {
      return Swal.fire('Error', 'Please select wallet which has NFTs', 'error');
    } else if (nfts.length === 0) {
      return Swal.fire('Error', 'Your wallets have no NFTs', 'error');
    } else if (selectedNfts.length === 0) {
      return Swal.fire('Error', 'You have selected 0 NFT', 'error');
    } else if (format == 'square') {
      if (selectedNfts.length < 1 && selectedNfts.length > 5) {
        let nftCards = document.getElementById('nfts-image-cards');
        nftCards.focus();
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please select upto 5 nfts for square',
        });
      }
    } else if (format == 'twitter banner 1500x1500') {
      if (selectedNfts.length < 1 && selectedNfts.length > 10) {
        let nftCards = document.getElementById('nfts-image-cards');
        nftCards.focus();
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please select upto 10 nfts for banner',
        });
      }
    } else if (bgType === 'picture' && !selectedArtWork._id) {
      /*   handleChangeTab({}, 0);
      setBgType('color'); */
      if (artworks.length > 0) {
        return Swal.fire('Error', 'Please select an artwork', 'error');
      } else {
        return Swal.fire(
          'Error',
          'Please select an artwork, or color, or radiant',
          'error'
        ).then((res) => {
          setValue(0);
        });
      }
    }

    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      if (hashTags.length > 5) {
        let hasTagsElement = document.getElementById('hashtags');
        hasTagsElement.focus();
        return;
      }

      canvas.setHeight(400);
      canvas.setWidth(400);
      const thumnailZoomValue = 400 / canvasWidth;
      canvas.setZoom(thumnailZoomValue);
      canvas.renderAll();
      let mergeThumnailUrl = canvas.toDataURL('image/png');

      canvas.setHeight(2000);
      canvas.setWidth(2000);
      const zoomValue = 2000 / canvasWidth;
      canvas.setZoom(zoomValue);
      canvas.renderAll();

      const formData = {
        format: format,
        wallets: selectedWallets,
        nfts: selectedNfts,
        bg_type: bgType,
        description: description.trim(),
        tags: hashTags,
        title: title.trim(),
        friend_tags: friendTags,
        merge_preview: canvas.toDataURL('image/png'),
        merge_thumnail_url: mergeThumnailUrl,
      };

      if (bgType === 'color') {
        formData.background_color = color;
      } else if (bgType === 'radiant') {
        formData.gradient_type = gradientType;
        formData.gradient_colors = colors;
      } else if (bgType === 'picture') {
        formData.artist_image = selectedArtWork._id;
      }

      Swal.fire({
        title: 'Select to save as format ?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Picture',
        denyButtonText: `NFT`,
      })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // download nft as a picture and then store it into db
            var anchor = document.createElement('a');
            setMergePreview(mergePreview);
            anchor.download = 'IMAGE.PNG';
            anchor.click();

            formData.merge_type = 'picture';
            setIsLoading(true);
            MergeService.storeMergeRequest(formData)
              .then((res) => {
                setIsLoading(false);
                if (res.status) {
                  Swal.fire('success', res.message, 'success').then(
                    (result) => {
                      navigate('/dashboard');
                    }
                  );
                  /* wait(1000)
                    .then((res) => navigate('/dashboard'))
                    .catch(() => navigate('/dashboard')); */
                }
              })
              .catch((error) => {
                setIsLoading(false);
                // if (error.response.data && error.response.data.errors) {
                //   return Swal.fire({
                //     icon: "error",
                //     title: "Error occurred!",
                //     html: ErrorFormat.validationErrors(
                //       error.response.data.errors
                //     ),
                //   });
                // } else
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                ) {
                  if (
                    error.response.data.message === 'User account deactivated'
                  ) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Inactive account!',
                      html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
                    }).then(() => {
                      StorageService.clearSession();
                      navigate('/connect-to-wallet');
                      return window.location.reload();
                    });
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error occurred',
                      html: `<p>${error.response.data.message}</p>`,
                    });
                  }
                }
              });
          } else if (result.isDenied) {
            formData.merge_type = 'nft';
            setIsLoading(true);
            MergeService.storeMergeRequest(formData)
              .then((res) => {
                setIsLoading(false);
                if (res.status) {
                  Swal.fire('success', res.message, 'success').then(() => {
                    return navigate('/dashboard');
                  });
                }
              })
              .catch((error) => {
                setIsLoading(false);
                if (error.response.data.errors) {
                  return Swal.fire({
                    icon: 'error',
                    title: 'Error occurred',
                    html: ErrorFormat.validationErrors(
                      error.response.data.errors
                    ),
                  });
                } else if (error.response.data.message) {
                  return Swal.fire({
                    icon: 'error',
                    title: 'Error occurred',
                    html: `<p>${error.response.data.message}</p>`,
                  });
                }
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          return window.location.reload();
        });
    }

    canvas.setHeight(defaultHeight);
    canvas.setWidth(defaultWidth);
    canvas.setZoom(1);
    canvas.renderAll();
  };

  useEffect(() => {
    if (isFormSubmit) {
      saveMergeRequest();
    }
  }, [isFormSubmit]);

  const nftAndPagenation = () => {
    const total = nfts?.length || 0;
    if (total !== 0) {
      setTotal(total);
      const no_of_pagenation = Math.ceil(total / limit);
      setPagenations(no_of_pagenation);
    }
  };

  useEffect(() => {
    nftAndPagenation();
  }, [nfts]);

  const hanldeMinAndMax = () => {
    if (currentPage == 1) {
      setMinNftIndex(0);
      setMaxNftIndex(limit);
    } else if (currentPage > 1) {
      setMinNftIndex(maxNftIndex);
      setMaxNftIndex(limit * currentPage);
    } else {
      setMinNftIndex(0);
      setMaxNftIndex(limit);
    }
  };

  useEffect(() => {
    hanldeMinAndMax();
  }, [currentPage]);

  const handleSelectedNft = async (nft_item) => {
    try {
      setIsLoading(true);
      let is_nft_already_exist = false;
      let nft_existed_index = null;

      for (let i = 0; i < selectedNfts.length; i++) {
        if (selectedNfts[i].id === nft_item.id) {
          is_nft_already_exist = true;
          nft_existed_index = i;
          break;
        }
      }

      if (is_nft_already_exist) {
        // nft already exist deselect it
        let oldSelectedNfts = selectedNfts;
        let newSelctedNfts = [];
        if (canvas !== null) {
          const activeObjects = canvas.getObjects();
          if (activeObjects.length > 0) {
            activeObjects.map((obj, index) => {
              if (obj.id == nft_item.id) {
                canvas.remove(obj);
                canvas.renderAll();
              }
            });
          }
        }
        // remove exist nft at index

        for (let i = 0; i < oldSelectedNfts.length; i++) {
          if (oldSelectedNfts[i].id !== nft_item.id) {
            newSelctedNfts.push(oldSelectedNfts[i]);
            //setIsLoading(false);
          }
        }

        setSelectedNfts(newSelctedNfts);
        //setIsLoading(false);
      } else {
        if (selectedNfts.length < 5) {
          //setIsLoading(true);
          setSelectedNfts((oldNft) => [
            ...oldNft,
            { id: nft_item.id, nft: nft_item.image_preview_url },
          ]);
          setTimeout(() => {
            hanldeNftPreview(nft_item);
          }, 250);
        } else {
          setIsLoading(false);
          Swal.fire(
            'Alert',
            'Oh no! you are trying to exceed your NFT limit',
            'info'
          );
        }
        //setIsLoading(true);
      }
      // await wait(2000);
      // setIsLoading(false);
    } catch (error) {
      //console.error(error);
      setIsLoading(false);
    }
  };

  const handleDeselectNft = async (nft_item) => {
    try {
      setIsLoading(true);
      let is_nft_already_exist = false;
      let nft_existed_index = null;

      for (let i = 0; i < selectedNfts.length; i++) {
        if (selectedNfts[i].id === nft_item.id) {
          is_nft_already_exist = true;
          nft_existed_index = i;
          break;
        }
      }

      if (is_nft_already_exist) {
        // nft already exist deselect it
        let oldSelectedNfts = selectedNfts;
        let newSelctedNfts = [];
        if (canvas !== null) {
          const activeObjects = canvas.getObjects();
          if (activeObjects.length > 0) {
            activeObjects.map((obj, index) => {
              if (obj.id == nft_item.id) {
                canvas.remove(obj);
                canvas.renderAll();
              }
            });
          }
        } else {
        }
        // remove exist nft at index

        for (let i = 0; i < oldSelectedNfts.length; i++) {
          if (oldSelectedNfts[i].id !== nft_item.id) {
            newSelctedNfts.push(oldSelectedNfts[i]);
            //setIsLoading(false);
          }
        }

        setSelectedNfts(newSelctedNfts);
        // setIsLoading(false);
      }
      await wait(2000);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const hanldeNftPreview = (nft_item) => {
    if (Object.keys(picsArtKeyInfo).length > 0) {
      increasePicsArtAPIHits();
      return ThirdPartyService.removeBg(
        nft_item.image_url,
        picsArtKeyInfo.api_key
      )
        .then((res) => {
          if (res.status === 'success') {
            new fabric.Image.fromURL(
              res.data.url,
              function (nft) {
                nft.set({
                  top: 0,
                  left: 0,
                  strokeWidth: 2,
                  borderColor: 'black',
                  cornerColor: 'black',
                  cornerStyle: 'circle',
                  cornerSize: 8,
                  transparentCorners: true,
                  selectable: true,
                  id: nft_item.id,
                  scaleX: canvas.width / nft.width,
                  scaleY: canvas.height / nft.height,
                });

                nft.on('selected', (e) => {
                  setCanvasActiveObj(nft);
                  setSelectedType('image');
                });

                nft.on('deselected', (e) => {
                  setCanvasActiveObj({});
                  setSelectedType('');
                });

                nft.on('modified', (e) => {
                  saveMergeView();
                });
                canvas.add(nft);

                canvas.renderAll();
                saveMergeView();
                setIsLoading(false);
              },
              {
                crossOrigin: 'annonymous',
              }
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      getPicsArtKey();
      hanldeNftPreview();
    }
  };

  const performActioninCanvas = (action_type) => {
    if (
      canvasActiveObj !== null &&
      canvasActiveObj !== {} &&
      Object.keys(canvasActiveObj).length !== 0
    ) {
      if (action_type === 'flipX') {
        canvasActiveObj.set({ flipX: !canvasActiveObj.flipX });
        canvas.renderAll();
      } else if (action_type === 'flipY') {
        canvasActiveObj.set({ flipY: !canvasActiveObj.flipY });
        canvas.renderAll();
      } else if (action_type === 'sendToBack') {
        // send object To Back
        canvas.sendToBack(canvasActiveObj);
        canvas.renderAll();
      } else if (action_type === 'sendBackwards') {
        // send object to Backwards
        canvas.sendBackwards(canvasActiveObj);
        canvas.renderAll();
      } else if (action_type === 'bringForwards') {
        // bring object to Forwards
        canvas.bringForward(canvasActiveObj);
        canvas.renderAll();
      } else if (action_type === 'bringToFront') {
        // bring object To Front
        canvas.bringToFront(canvasActiveObj);
        canvas.renderAll();
      } else if (action_type === 'delete') {
        /* Swal.fire({
          title: 'Do you want to delete?',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Delete',
          denyButtonText: `Cancel`,
        }).then((result) => {
          if (result.isConfirmed) { */
        const canvasActiveObjectId = canvasActiveObj.id;
        let oldSelectedNfts = selectedNfts;
        let newSelctedNfts = [];
        canvas.remove(canvasActiveObj);
        canvas.renderAll();

        for (let i = 0; i < oldSelectedNfts.length; i++) {
          if (oldSelectedNfts[i].id !== canvasActiveObj.id) {
            newSelctedNfts.push(oldSelectedNfts[i]);
          }
        }
        setSelectedNfts(newSelctedNfts);
        saveMergeView();
        //Swal.fire('Deleted!', '', 'success');
        /* } else if (result.isDenied) {
          }
        }); */
      } else if (action_type === 'deselect') {
        // deselect active object
        setCanvasActiveObj({});
        canvas.discardActiveObject();
        canvas.renderAll();
        //canvas.bringToFront(canvasActiveObj);
      }
      saveMergeView();
    }
  };

  const addText = (text = 'Enter text...') => {
    if (canvas !== '' && canvas !== null) {
      var text = new fabric.IText(text, {
        top: 0,
        left: 0,
        strokeWidth: 2,
        borderColor: 'black',
        cornerColor: 'black',
        cornerStyle: 'circle',
        cornerSize: 8,
        transparentCorners: true,
        selectable: true,
      });
      text.on('selected', (e) => {
        setCanvasActiveObj(text);
        setSelectedType('text');
      });
      text.on('deselected', (e) => {
        setCanvasActiveObj({});
        setSelectedType('image');
      });
      canvas.add(text);
      text.on('modified', (e) => {
        saveMergeView();
      });
      canvas.renderAll();
      saveMergeView();
    }
  };

  const activeClass = (nft_item) => {
    for (let i = 0; i < selectedNfts.length; i++) {
      if (selectedNfts[i].id === nft_item.id) {
        return 'active';
      }
    }
    return 'deactive';
  };

  const getAlias = (e) => {
    setAliasMessage('');

    // if (!e.target.value.trim()) {
    //   setToggleFrndTag(!toggleFrndTag);
    //   return setAlias([]);
    // }

    const data = {
      alias: e.target.value.trim(),
    };

    return UserService.getAlias(data)
      .then((res) => {
        if (res.status && res.data) {
          if (res.data.length === 0) {
            setAlias([]);
            return setAliasMessage('No alias found');
          } else {
            setAliasMessage('');
            return setAlias(res.data);
          }
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive Account',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        }
      });
  };

  function handleFriendTag(friend_tag) {
    if (friendTags.length < 6) {
      friendTags.indexOf(friend_tag._id) === -1 &&
        setFriendTags([...friendTags, friend_tag._id]);
      frndTagsPreview.indexOf(friend_tag.alias) === -1 &&
        setFrndTagsPreview([...frndTagsPreview, friend_tag.alias]);
      setAlias([]);
      setAliasVal('');
      //setToggleFrndTag(!toggleFrndTag);
    } else {
      setAlias([]);
    }
  }

  const handleCloseFriendTag = (tag) => {
    // setFriendTags(friendTags.filter((item) => {
    // }));

    let friendTagExistingIndex;

    frndTagsPreview.map((frndTag, index) => {
      if (frndTag === tag) {
        friendTagExistingIndex = index;
      }
    });

    // friendTags.splice(friendTagExistingIndex)
    // setFriendTags(friendTags.splice(friendTagExistingIndex))
    setFriendTags(
      friendTags.filter((item, index) => index !== friendTagExistingIndex)
    );

    setFrndTagsPreview(frndTagsPreview.filter((item) => tag !== item));
  };

  // useEffect(() => {
  //   getNfts();
  // }, [wallets]);

  const clrs = () => {
    let allColors = [];
    let eqOffset = 0;
    if (colors.length > 0) {
      eqOffset = 1 / colors.length;
      eqOffset = parseFloat(eqOffset).toFixed(2);
      eqOffset = parseFloat(eqOffset);
    }
    let actual_offset = 0;

    colors.map((colr, index) => {
      if (index === colors.length - 1) {
        actual_offset = Math.round(actual_offset);
      }
      allColors.push({ color: colr, offset: actual_offset });
      if (index !== colors.length - 1) {
        actual_offset += eqOffset;
      }
    });
    return allColors;
  };

  const handleBackgroundOfPreviewer = () => {
    let previwer = document.getElementsByClassName('upper-canvas');
    let callPreview = false;
    if (canvas !== null && canvas !== '') {
      if (bgType === 'color') {
        canvas.setBackgroundImage(null);
        canvas.renderAll();
        canvas.setBackgroundColor(color);
        saveMergeView();
      } else if (bgType === 'radiant') {
        canvas.setBackgroundImage(null);
        if (colors && colors.length > 1) {
          if (gradientType == 'linear') {
            var grad = new fabric.Gradient({
              type: 'linear',
              coords: {
                x1: 0,
                y1: 0,
                x2: canvas.width,
                y2: canvas.height,
              },
              colorStops: clrs(),
            });
            canvas.backgroundColor = grad.toLive(canvas.contextContainer);
            saveMergeView();
            canvas.renderAll();
            canvas.calcOffset();
          } else if (gradientType == 'radial') {
            var grad = new fabric.Gradient({
              type: 'radial',
              coords: {
                x1: canvas.width / 18,
                y1: canvas.height / 18,
                x2: canvas.width / 1.5,
                y2: canvas.height / 1.5,
                // for radial
                r1: canvas.width, // inner circle radius
                r2: canvas.width / 800,
                r3: canvas.width / 700,
                r4: canvas.width / 600,
                r5: canvas.width / 500,
                r6: canvas.width / 400,
                r7: canvas.width / 300,
                r8: canvas.width / 200,
                r9: canvas.width / 100,
                r10: -canvas.width / 1,
                r11: -canvas.width / 100,
                r12: -canvas.width / 200,
                r13: -canvas.width / 300,
                r14: -canvas.width / 400,
                r15: -canvas.width / 500,
                r16: -canvas.width / 600,
                r17: -canvas.width / 700,
                r17: -canvas.width / 800,
                r17: -canvas.width,
              },
              colorStops: clrs(),
            });
            canvas.backgroundColor = grad.toLive(canvas.contextContainer);
            saveMergeView();
            canvas.renderAll();
            canvas.calcOffset();
          } else if (gradientType == 'conic') {
            var grad = new fabric.Gradient({
              type: 'linear',
              coords: {
                x1: 0,
                y1: canvas.height / 2,
                x2: canvas.width,
                y2: canvas.height / 2,
              },
              colorStops: clrs(),
            });
            canvas.backgroundColor = grad.toLive(canvas.contextContainer);
            saveMergeView();
            canvas.renderAll();
            canvas.calcOffset();
          }
        }
      } else if (bgType === 'picture') {
        if (Object.keys(selectedArtWork).length > 0) {
          var image;
          var imgWidth, imgHeight;
          image = new Image();
          var newCanvas = '';
          image.onload = function (img) {
            imgWidth = this.width;
            imgHeight = this.height;
            if (imgWidth !== null && imgHeight !== null) {
              canvas.setBackgroundColor(null);
              canvas.renderAll();
              canvas.setBackgroundImage(
                selectedArtWork.artwork_uri,
                canvas.renderAll.bind(canvas),
                {
                  top: 0,
                  left: 0,
                  originX: 'left',
                  originY: 'top',
                  opacity: 0.9,
                  scaleX: canvas.width / imgWidth,
                  scaleY: canvas.height / imgHeight,
                  backgroundVpt: true,
                  crossOrigin: 'annonymous',
                }
              );
              canvas.renderAll();
              saveMergeView();
              renderCount = 1;
            }
          };
          image.src = selectedArtWork.artwork_uri;
        } else {
          const image = new fabric.Image(noImageSelected);
          canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas));
          canvas.setBackgroundColor('#ffff00');
          canvas.renderAll();
          saveMergeView(1);
        }
      }
    }
  };

  useLayoutEffect(() => {
    handleBackgroundOfPreviewer();
  }, [
    bgType,
    color,
    gradientType,
    colors,
    backgroundPreview,
    uploadedImgDimensions,
    selectedNfts,
    bringBackward,
    bringBack,
    bringFront,
    bringForward,
    selectedArtWork,
    canvas,
  ]);

  const removeBackgroundImage = () => {
    const image = new fabric.Image('');
    canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas));
    setBackgroundPreview(null);
  };

  const saveMergeView = (nan = 0) => {
    const defaultHeight = canvas.height;
    const defaultWidth = canvas.width;
    canvas.setHeight(2000);
    canvas.setWidth(2000);
    const zoomValue = 2000 / canvasWidth;
    canvas.setZoom(zoomValue);
    canvas.renderAll();
    setMergePreview(canvas.toDataURL('image/png'));
    canvas.setHeight(defaultHeight);
    canvas.setWidth(defaultWidth);
    canvas.setZoom(1);
    canvas.renderAll();
    if (bgType === 'picture') {
      if (renderCount === nan) {
        return handleBackgroundOfPreviewer();
      }
    }
  };

  const downloadMergePreview = () => {
    if (bgType === 'picture' && !selectedArtWork._id) {
      return Swal.fire(
        'Error',
        'Please select an artwork, or color, or radiant',
        'error'
      );
    }
    setIsLoading(true);
    saveMergeView();
    const defaultHeight = canvas.height;
    const defaultWidth = canvas.width;
    canvas.setHeight(2000);
    canvas.setWidth(2000);
    const zoomValue = 2000 / canvasWidth;
    canvas.setZoom(zoomValue);
    canvas.renderAll();
    var anchor = document.createElement('a');
    anchor.href = canvas.toDataURL('image/png');
    anchor.download = 'IMAGE.PNG';
    anchor.click();
    canvas.setHeight(defaultHeight);
    canvas.setWidth(defaultWidth);
    canvas.setZoom(1);
    canvas.renderAll();
    setIsLoading(false);
  };

  const resetNewWalletForm = () => {
    setPersonalSelectedWallet([]);
    setNewWalletErrors({});
    setNewWallet('');
    setWalletAlias('');
    setMultiValue([]);
    canvas.clear();
    setSelectedNfts([]);
    setNftsLoadingMessage(noNftsMessage);
    setNfts([]);
    setCurrentPage(1);
    setTotal(1);
    setPagenations(1);
  };

  const handleNewWalletSubmit = (e) => {
    e.preventDefault();
    FormValidator.handleOnSubmit('new_wallet_address', setNewWalletErrors);
    FormValidator.handleOnSubmit('wallet_alias', setNewWalletErrors);

    return setNewWalletSubmit(true);
  };

  const addNewWalletInAdressBook = (e) => {
    setNewWalletSubmit(false);

    let noOfErrors = 0;
    let firstKey;

    Object.values(newWalletErrors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(newWalletErrors).length - 1; i++) {
      if (
        Object.values(newWalletErrors)[i] !== '' &&
        Object.values(newWalletErrors)[i] !== null
      ) {
        firstKey = Object.keys(newWalletErrors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      let getFirstErrorElement = document.getElementById(firstKey);
      getFirstErrorElement.focus();
      return;
    } else {
      setIsLoading(true);
      setOpenForm(false);

      const data = {
        wallet_address: newWallet,
        alias: walletAlias.trim(),
      };

      ThirdPartyService.refreshNFT(newWallet)
        .then((res) => {
          WalletService.storeWalletsFromAdressBook(data)
            .then((res) => {
              setIsLoading(false);
              resetNewWalletForm();
              if (res.status) {
                setNewWalletErrors({});
                Swal.fire('success', res.message, 'success');
                getAllWallets();
              }
            })
            .catch((error) => {
              setIsLoading(false);
              resetNewWalletForm();
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                if (
                  error.response.data.message === 'User account deactivated'
                ) {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Inactive account!',
                    html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
                  }).then(() => {
                    StorageService.clearSession();
                    navigate('/connect-to-wallet');
                    return window.location.reload();
                  });
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error occurred',
                    html: `<p>${error.response.data.message}</p>`,
                  });
                }
              }
            });
        })
        .catch((error) => {
          setIsLoading(false);
          resetNewWalletForm();
          if (
            error.response &&
            error.response.data &&
            error.response.data.owner &&
            error.response.data.owner.length > 0
          ) {
            if (
              error.response.data.owner[0] ===
              `Address like string expected but received "${newWallet}"`
            ) {
              Swal.fire('Error', 'Invalid wallet address', 'error');
            }
          }
        });
    }
  };

  useEffect(() => {
    if (newWalletSubmit) {
      addNewWalletInAdressBook();
    }
  }, [newWalletSubmit]);

  const getAllNfts = () => {
    //debugger;

    setNfts([]);
    setNftPagenation({});
    setNftCurntPage(1);
    let allSelectedWallets = [];

    if (personalSelectedWallet.length > 0) {
      for (let personalWalletAdress of personalSelectedWallet) {
        getNfts('personal', personalWalletAdress);
        allSelectedWallets.push(personalWalletAdress);
      }
    } else {
      setNftCurntPage(1);
    }

    if (multiValue.length > 0) {
      for (let w of multiValue) {
        allSelectedWallets.push(w.wallet_address);
        getNfts('address_book', w.wallet_address);
      }
    }
    Array.isArray(nfts) &&
      nfts.length === 0 &&
      setNftsLoadingMessage(noNftsMessage);

    if (multiValue.length === 0 && personalSelectedWallet.length === 0) {
      setNftsLoadingMessage(noNftsMessage);
      setNfts([]);
    }

    // const data = {
    //   wallets: allSelectedWallets,
    // };

    setSelectedWallets(allSelectedWallets);

    // if (allSelectedWallets.length > 0 || selectedWallets.length>0) {
    //   setIsLoading(true);
    //   NftService.getAllNftsByWallets(nftCurntPage, nftsLimit, data)
    //     .then((res) => {
    //       setIsLoading(false);
    //       if (res.status && res.data) {
    //         setNfts(res.data.assets);
    //         setNftPagenation(res.pagenation);
    //       }
    //     })
    //     .catch((error) => {
    //       setIsLoading(false);
    //     });
    // }
  };

  useEffect(() => {
    getAllNfts();
  }, [multiValue, personalSelectedWallet]);

  /* useEffect(() => {
    getAllSelectedWalletNfts(nftCurntPage);
  }, [nftCurntPage]); */

  const getAllSelectedWalletNfts = (nftCurntPage = 1) => {
    if (selectedWallets.length > 0) {
      setNftsLoadingMessage(fetchNftsMessage);
      setIsLoading(true);
      const data = {
        wallets: selectedWallets,
      };

      setTimeout(() => {
        NftService.getAllNftsByWallets(nftCurntPage, nftsLimit, data)
          .then((res) => {
            setIsLoading(false);
            if (res.status && res.data) {
              setNfts(res?.data?.assets || []);
              setNftPagenation(res.pagenation);
            } else setNftsLoadingMessage(noNftsMessage);
          })
          .catch((error) => {
            setNftsLoadingMessage(noNftsMessage);
            setIsLoading(false);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              if (error.response.data.message === 'User account deactivated') {
                Swal.fire({
                  icon: 'warning',
                  title: 'Inactive account',
                  html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
                }).then(() => {
                  StorageService.clearSession();
                  navigate('/connect-to-wallet');
                  return window.location.reload();
                });
              }
            }
          });
      }, 1000);
    }
  };

  useEffect(() => {
    getAllSelectedWalletNfts(nftCurntPage);
  }, [selectedWallets, nftCurntPage]);
  useEffect(()=>{artworks && artworks.length>0 && swiperRef && slideTo(0)},[artworks])
  const slideTo = (index) => swiperRef.slideTo(index);
  const handleArtistPicture = () => {
    setIsLoading(true);
   
    ArtworkService.getArtWorks(artworkCurrentPage, artworksLimit)
      .then((res) => {
        setIsLoading(false);
        if (res.status && res.data) {
          if (res.data.length > 0) {
            setArtworks(res.data);
            //setSelectedArtwork(res.data[0]);
            // set pagenation info
            setArtworkFirstPage(res.pagenation.first_page);
            setArtworkLastPage(res.pagenation.last_page);
            setTotalArtworks(res.pagenation.total);
            setArtworksLimit(Number(res.pagenation.limit));
            renderCount = 0;
          }
          
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        }
      });
  };

  useEffect(() => {
    bgType === 'picture' && handleArtistPicture();
  }, [artworkCurrentPage]);

  const handleArtworkPagenation = (event, pagenate_type) => {
    event.preventDefault();
    if (pagenate_type === 'first') {
      setArtworkCurrentPage(artworkFirstPage);
    } else if (pagenate_type === 'last') {
      setArtworkCurrentPage(artworkLastPage);
    } else if (pagenate_type === 'next') {
      // handle next
      setArtworkCurrentPage(artworkCurrentPage + 1);
    } else if (pagenate_type === 'prev') {
      // handle previous
      setArtworkCurrentPage(artworkCurrentPage - 1);
    }
  };

  let checkTagExist = (newHashTagValue) => {
    for (let i = 0; i < hashTags.length; i++) {
      if (hashTags[i] == newHashTagValue) {
        return true;
      }
    }
    return false;
  };

  const handleTagWithComma = (e) => {
    setHashValueError('');
    if (hashTags.length < 5) {
      if (e.keyCode === 188) {
        if (hashvalue.length >= 3) {
          let newHashTagValue = hashvalue.trim();
          if (newHashTagValue[newHashTagValue.length - 1] === ',') {
            newHashTagValue = newHashTagValue.slice(0, hashvalue.length - 1);
          }

          if (hashTags.length === 0) {
            setHashTags((old) => [...old, newHashTagValue]);
            setHashValue('');
          } else {
            let tagExist = checkTagExist(newHashTagValue);
            if (tagExist) {
              setHashValue('');
            } else {
              setHashTags((old) => [...old, newHashTagValue]);
              setHashValue('');
            }
          }
        } else {
          setHashValueError('Please enter at least 3 characters with comma');
        }
      }
    } else {
      setHashValue('');
    }
  };

  useEffect(() => {
    if (canvas !== '') {
      canvas.clear();
      setCanvas(initCanvas());
    }
    setNfts([]);
    setSelectedNfts([]);
    getAllNfts();
  }, [personalSelectedWallet]);

  return (
    <>
      {isLoading && <PreLoaderComponent2 />}
      <section className='p-3'>
        {/* merger form */}
        <form
          encType='multipart/form-data'
          onSubmit={(e) => submitHandler(e)}
          autoComplete='off'
          noValidate
        >
          <div className='theme-text-color formating'>
            <h1 className='theme-text-color format-heading fw_bold text-level-5'>
              Choose format
            </h1>
            <div className='theme-text-color d-flex justify-content-start align-items-center flex-col-430px'>
              <div className='fs-t form-check mx-2'>
                <input
                  className='form-check-input '
                  type='radio'
                  name='format'
                  id='square'
                  defaultChecked={true}
                  value='square'
                  onChange={(e) => setFormat(e.target.value)}
                />
                <label className='form-check-label' htmlFor='square'>
                  Square
                </label>
              </div>
            </div>
          </div>
          <div className='theme-text-color formating pt-5 pb-2'>
            <div className='theme-text-color format-heading py-0 text-level-5 line-hight-12'>
              Account Wallet
            </div>
            <div className=' formating-content text-level-6 text-gray mb-23'>
              Click on wallet to select or deselect wallets
            </div>
            <NftSwiper
              selectedWallets={personalSelectedWallet}
              setSelectedWallets={setPersonalSelectedWallet}
              allSelectedWallets={selectedWallets}
            />
          </div>
          <div className='theme-text-color formating py-3'>
            {/* {selectedWallets.length < 5 ? ( */}
            <>
              <div className='theme-text-color format-heading py-0 text-level-5 line-hight-12'>
                Choose Friend's Wallets
              </div>
              <div className='formating-content text-level-6 text-gray mb-2'>
                Select up to 5 wallets
              </div>
            </>
            {/*  ) : (
              <>
                <h3 className='theme-text-color format-heading py-0'>
                  Maximum wallet selection limit reached
                </h3>
                <p className='formating-content'></p>
              </>
            )}  */}
            <div className='theme-text-color'>
              <Autocomplete
                multiple={true}
                value={multiValue}
                size='large'
                isRequired={false}
                className='w-100 dark-auto-complete'
                sx={{
                  color: 'var(--yellow)',
                  //padding: '8px 8px !important',
                  '.MuiIconButton-root .MuiSvgIcon-root': {
                    fill: 'yellow',
                  },

                  '& .MuiInputBase-input': {
                    '::placeholder': {
                      fontSize:
                        width > 500 ? '1rem !important' : '0.8rem !important',
                      color: 'yellow !important',
                    },
                  },
                  '& .MuiInputBase-root': {
                    width: '100% !important',
                    padding: '8px 8px !important',
                  },
                }}
                classes={{ paper: 'paperlist' }}
                onChange={(event, newValue) => {
                  setIsLoading(true);
                  setSelectedNfts([]);
                  setNftCurntPage(1);
                  canvas.clear();
                  newValue.length === 0 && setNftsLoadingMessage(noNftsMessage);
                  if (selectedWallets.length < 5) {
                    setMultiValue([
                      ...fixedOptions,
                      ...newValue.filter(
                        (option) => fixedOptions.indexOf(option) === -1
                      ),
                    ]);
                  } else if (newValue.length < 5) {
                    setMultiValue([
                      ...fixedOptions,
                      ...newValue.filter(
                        (option) => fixedOptions.indexOf(option) === -1
                      ),
                    ]);
                  } else if (
                    newValue.length > 5 ||
                    selectedWallets.length === 5
                  ) {
                    Swal.fire(
                      'Warning',
                      'Oh no! Selected wallets limit reached.',
                      'warning'
                    );
                  }
                  setIsLoading(false);
                }}
                options={wallets}
                getOptionLabel={(option) =>
                  option.alias + ', ' + option.wallet_address
                }
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      className='pl-0'
                      sx={{
                        fontSize: '1rem !important',
                        paddingRight: width < 500 ? '40px' : '0px',
                        marginLeft: '0px',
                      }}
                      label={option.alias + ', ' + option.wallet_address}
                      {...getTagProps({ index })}
                      //disabled={fixedOptions.indexOf(option) !== -1}
                    />
                  ))
                }
                style={{ width: 500 }}
                // size={width<=375 ? "small" : "medium"}
                renderInput={(params) => (
                  <AutocompleteField
                    {...params}
                    label={`Wallet Address`}
                    // label={`Wallets ${multiValue.length > 0 ? '*' : '*'}`}
                    placeholder={
                      selectedWallets.length < 5 ? 'Choose Wallet' : ''
                    }
                    className='autocomplete-select-field'
                  />
                )}
              />

              {selectedWallets.length === 5 && (
                <p className=' formating-content text-gray'>
                  Max wallet limit reached
                </p>
              )}
              <div className='d-flex py-3 align-items-center'>
                <div
                  className='d-flex cursor-pointer align-items-center'
                  onClick={(e) => {
                    setOpenForm(true);
                  }}
                >
                  <IconButton color='primary' className='pl-0'>
                    <Fab className='bg-yellow btn-fs fs-text-stroke-2px'>
                      <i className='fa-solid fa-plus m-auto'></i>
                    </Fab>
                  </IconButton>
                  <h1
                    className='theme-text-color format-heading  fw_bold text-center my-auto p-0 m-0 sm-fs_1rem'
                    style={{ fontSize: 'large', letterSpacing: '1px' }}
                  >
                    Add Wallet to Address Book
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {/* wallet nfts swiper */}
          <div className='py-3'>
            {selectedNfts?.length < 5 && (
              <div className='text-level-6 text-gray line-hight-1  text-center'>
                Select upto 5 NFTs for a square format
              </div>
            )}
            <div
              className='d-flex justify-content-center align-items-center pagenation '
              id='pagenation'
            >
              {
                // total pages is equal upto last page
                nftPagenation &&
                  Object.keys(nftPagenation).length > 0 &&
                  nftPagenation.last_page &&
                  nftPagenation.last_page > 0 &&
                  _.times(nftPagenation.last_page, (index) => (
                    <>
                      <div
                        className={`pagenation-item py-2 mb-1 mt-2 ${
                          nftCurntPage == index + 1 ? 'active' : ''
                        } mx-1`}
                        onClick={(e) => {
                          setNftCurntPage(index + 1);
                          handlePagenation(e);
                        }}
                        key={index}
                      ></div>
                    </>
                  ))
              }
            </div>
            {/* {selectedNfts.length === 5 && (
              <p className=" formating-content theme-text-color text-center">
                Maximum 5 NFTs are allowed to select
              </p>
            )} */}
            <div
              className='d-flex justify-content-center align-items-center nfts-image-cards flex-wrap '
              id='nfts-image-cards'
            >
              {nfts && nfts.length > 0 ? (
                nfts.map((nft_item, index) => (
                  <div
                    key={index}
                    className='smW d-flex justify-content-center align-items-center nfts-image-cards flex-wrap m-0'
                  >
                    {nft_item.image_preview_url !== null && (
                      <button
                        type='button'
                        disabled={isLoading === true}
                        className='m-0 p-1 mx-auto'
                        onClick={(e) => {
                          if (!e.target.disabled === true) {
                            e.target.disabled = true;
                            handleSelectedNft(nft_item);
                          } else if (!isLoading) {
                            e.target.disabled = false;
                            handleDeselectNft(nft_item);
                          }
                        }}
                        stye={{
                          padding: 0,
                          margin: 0,
                          maxWidth: '100%',
                          maxHeight: '100%',
                        }}
                      >
                        <div
                          type='button'
                          disabled={true}
                          sx={{
                            padding: 0,
                            margin: 0,
                            maxWidth: '100%',
                            maxHeight: '100%',
                          }}
                        >
                          <div
                            className={`p-2 nft-image-card mx-auto ${activeClass(
                              nft_item
                            )} `}
                          >
                            <img
                              src={nft_item.image_preview_url}
                              className='w-100 h-100'
                            />
                          </div>
                        </div>
                      </button>
                      // </Button>
                    )}
                  </div>
                ))
              ) : (
                <h1
                  className='theme-text-color format-heading  fw_bold text-center mx-auto pb-3 text-level-5'
                  style={{ fontSize: 'large', letterSpacing: '1px' }}
                >
                  {nftsLoadingMessage}
                </h1>
              )}
            </div>
          </div>

          <div className=' theme-text-color formating py-3'>
            <h3 className='theme-text-color format-heading py-0 text-level-5'>
              Choose Background
            </h3>
            <div>
              <div className='mx-auto'>
                <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    TabIndicatorProps={{ style: { background: '#FFFF00' } }}
                    inkBarStyle={{ background: '#FFFF00' }}
                    centered
                    aria-label='nfts-tab'
                    className='fs-1_25rem pb-3'
                  >
                    <Tab
                      onClick={() => {
                        setSelectedArtwork({});
                        setColors(['#faff00', '#a600ff']);
                        setBgType('color');
                      }}
                      label='Color'
                      {...a11yProps(0)}
                      className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                        value === 0 ? 'fw_bolder' : ''
                      }`}
                    />
                    <Tab
                      onClick={() => {
                        setSelectedArtwork({});
                        setColor('#ffff00');
                        setBgType('radiant');
                      }}
                      label='Radiant'
                      {...a11yProps(1)}
                      className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                        value === 1 ? 'fw_bolder' : ''
                      }`}
                    />
                    <Tab
                      onClick={() => {
                        setColors(['#faff00', '#a600ff']);
                        setColor('#ffff00');
                        setBgType('picture');
                        handleArtistPicture();
                      }}
                      label={
                        window.innerWidth > 430 ? 'Artist Picture' : 'Artwork'
                      }
                      {...a11yProps(2)}
                      className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                        value === 2 ? 'fw_bolder' : ''
                      }`}
                    />
                  </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                  <div className='d-flex gap-3 justify-content-between align-items-center py-3'>
                    <HuePicker
                      color={color}
                      onChange={(e) => setColor(e.hex)}
                      className='w-100 color-picker'
                    />
                    <div
                      className='selected-color'
                      style={{ backgroundColor: color }}
                    ></div>
                  </div>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <div className='py-3'>
                    <FormControl
                      fullWidth
                      className='wallet-select-field'
                      size={width <= 375 ? 'small' : 'medium'}
                    >
                      <InputLabel
                        id='demo-simple-select-label'
                        className='input-label'
                      >
                        Gradient Type
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={gradientType}
                        label='Gradient Type'
                        onChange={(e) => setGradientType(e.target.value)}
                        className='selected-text'
                      >
                        <MenuItem
                          value='linear'
                          className='theme-text-color bg-dark  border-bottom'
                        >
                          Linear
                        </MenuItem>
                        <MenuItem
                          value='radial'
                          className='theme-text-color bg-dark  border-bottom'
                        >
                          Radial
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <button
                      className='butn butn-primary my-3 w-100'
                      type='button'
                      disabled={colors.length == 6}
                      onClick={() =>
                        setColors((oldColors) => [...oldColors, '#faff00'])
                      }
                    >
                      Add Color
                    </button>
                    <div className=''>{colorPalletGenerator()}</div>
                  </div>
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <div className='py-3'>
                    <div>
                      <Swiper
                        onSwiper={setSwiperRef}
                        className='mySwiper swipe-w custom-rounded p-3 artwork-swipper'
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={true}
                        modules={[Navigation]}
                      >
                        {artworks &&
                          artworks.length > 0 &&
                          artworks.map((art, index) => (
                            <div key={index}>
                              <SwiperSlide
                                className={`artwork-image swiping-item cursor-pointer  ${
                                  selectedArtWork._id === art._id
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={() => {
                                  renderCount = 0;
                                  setSelectedArtwork(art);
                                }}
                              >
                                <img
                                  src={art.artwork_uri}
                                  alt={art.alias ? art.alias : 'artworks'}
                                  className='w-100 h-100'
                                />
                                <div className='position-absolute description '>
                                  <a
                                    className='title px-2 text-decoration-none'
                                    data-swiper-parallax='-300'
                                    href={art.website}
                                    target='_blank'
                                  >
                                    {art.alias}
                                  </a>
                                </div>
                              </SwiperSlide>
                            </div>
                          ))}
                      </Swiper>
                    </div>
                    {/* add pagenation here */}
                    <div>
                      {!isLoading && artworks.length > 0 && (
                        <div className='d-flex justify-content-around align-items-center py-3'>
                          <div className='d-flex justify-content-center align-items-center'>
                            <div>
                              <button
                                type='button'
                                className='mui-icon-btn'
                                variant='text'
                                onClick={(e) =>
                                  handleArtworkPagenation(e, 'first')
                                }
                                disabled={
                                  artworkCurrentPage == artworkFirstPage
                                }
                              >
                                <i className='fa-solid fa-angles-left mui-pagenate-icon'></i>
                              </button>
                              <button
                                type='button'
                                className='mui-icon-btn'
                                variant='text'
                                onClick={(e) =>
                                  handleArtworkPagenation(e, 'prev')
                                }
                                disabled={
                                  artworkCurrentPage == artworkFirstPage
                                }
                              >
                                <i className='fa-solid fa-chevron-left mui-pagenate-icon'></i>
                              </button>
                            </div>
                            <div>
                              <button
                                type='button'
                                className='mui-icon-btn'
                                variant='text'
                                onClick={(e) =>
                                  handleArtworkPagenation(e, 'next')
                                }
                                disabled={artworkCurrentPage == artworkLastPage}
                              >
                                <i className='fa-solid fa-chevron-right mui-pagenate-icon'></i>
                              </button>
                              <button
                                type='button'
                                className='mui-icon-btn'
                                variant='text'
                                onClick={(e) =>
                                  handleArtworkPagenation(e, 'last')
                                }
                                disabled={artworkCurrentPage == artworkLastPage}
                              >
                                <i className='fa-solid fa-angles-right mui-pagenate-icon'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {!isLoading && artworks.length > 0 ? (
                        <p className='theme-text-color mx-auto text-center'>
                          {artworkCurrentPage} of {artworkLastPage} Page
                        </p>
                      ) : (
                        <p className='theme-text-color mx-auto text-center'>
                          No artwork has been found!
                        </p>
                      )}
                    </div>
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>

          <div className=' theme-text-color formating py-3 '>
            <h3 className='theme-text-color format-heading py-0 pb-3 text-level-5'>
              Personalize it
            </h3>
            <div className='theme-text-color pb-3'>
              <TextFieldWrapper
                fullWidth
                //className='wallet-select-field selected-text description-text'
                onChange={(e) => {
                  setTitle(e.target.value);
                  FormValidator.handleOnBlur(e, setErrors);
                }}
                value={title}
                label='Title'
                id='title'
                //placeholder='NFT Title'
                required={true}
                inputProps={{ maxLength: 42 }}
                // inputProps={{ minLength: 5, maxLength: 42 }}
                onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                /* sx={{
                  '&.Mui-focused .MuiInputLabel-outlined': {
                    color: '#ffff00',
                    borderColor: '#ffff00',
                    outlineColor: '#ffff00',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#ffff00',
                    outlineColor: '#ffff00',
                  },
                  '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
                    borderColor: '#ffff00',
                    outlineColor: '#ffff00',
                  },
                  '&.Mui-focused .MuiInputLabel-outlined': {
                    borderColor: '#ffff00',
                    outlineColor: '#ffff00',
                  },
                }} */
                // size={width<=375 ? "small" : "medium"}
              />
              {errors && errors.title && (
                <div className='error ps-2'>
                  <span className='error-span pt-0 mt-0'>
                    <i className='fa-solid fa-circle-exclamation pe-1'></i>
                    {errors.title}
                  </span>
                </div>
              )}
            </div>

            <div className='theme-text-color'>
              <TextFieldWrapper
                fullWidth
                //className='wallet-select-field selected-text description-text descript-style'
                id='description'
                multiline
                //maxRows={7}
                minRows={4}
                onChange={(e) => {
                  setDescription(e.target.value);
                  FormValidator.handleOnBlur(e, setErrors);
                }}
                value={description}
                label='Description'
                //placeholder='Tell Your Story'
                // inputProps={{ minLength: 10, maxLength: 200 }}
                inputProps={{ maxLength: 200 }}
                onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
              />
              {errors && errors.description && (
                <div className='error ps-2'>
                  <span className='error-span pt-0 mt-0'>
                    <i className='fa-solid fa-circle-exclamation pe-1'></i>
                    {errors.description}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className=' theme-text-color formating pt-3'>
            <div className='theme-text-color format-heading py-0 text-level-5 line-hight-12'>
              Tag Friend
            </div>
            <div className='formating-content p-0 m-0 text-level-6 text-gray mb-2'>
              Tag up to 6 friends
            </div>
            {friendTags.length < 6 && (
              <IconButton
                color='primary'
                className='pl-0'
                onClick={() => {
                  setToggleFrndTag(!toggleFrndTag);
                  setAlias([]);
                  setAliasMessage('');
                }}
              >
                <Fab className='bg-yellow btn-fs fs-text-stroke-2px'>
                  {toggleFrndTag ? (
                    <i className='fa-solid fa-xmark '></i>
                  ) : (
                    <i className='fa-solid fa-plus'></i>
                  )}
                </Fab>
              </IconButton>
            )}

            {toggleFrndTag && (
              <div className='theme-text-color  w-100 mt-2'>
                <TextFieldWrapper
                  fullWidth
                  autoFocus
                  className='wallet-select-field selected-text description-text mt-2 tags-inputw-100'
                  label='# Alias'
                  //placeholder='Search by alias'
                  // onClick={(e) => getAlias(e.target.value.trim())}
                  onChange={(e) => {
                    e.target.value && getAlias(e);
                    !e.target.value && setAlias([]);
                    !e.target.value && setAliasMessage('');
                    setAliasVal(e.target.value.trim());
                  }}
                  value={aliasVal}
                />
                {alias &&
                  alias.length > 0 &&
                  alias.map(
                    (friend_alias, index) =>
                      friendTags.indexOf(friend_alias._id) === -1 && (
                        <MenuItem
                          value={friend_alias._id}
                          className='theme-text-color bg-dark  border-bottom'
                          key={index}
                          onClick={() => handleFriendTag(friend_alias)}
                        >
                          {friend_alias.alias}
                        </MenuItem>
                      )
                  )}
              </div>
            )}
            {aliasMessage !== '' && (
              <div className='theme-text-color  w-100'>
                {
                  <div className=' formating-content  text-gray text-level-6'>
                    {aliasMessage}
                  </div>
                }
              </div>
            )}
            <div className=' theme-text-color formating pt-3 d-flex flex-wrap gap-1'>
              {frndTagsPreview &&
                frndTagsPreview.map((tag, index) => (
                  <div className='tag-item' key={index}>
                    <span className='text'>{tag}</span>
                    <p
                      className='close'
                      onClick={() => handleCloseFriendTag(tag)}
                    >
                      &times;
                    </p>
                  </div>
                ))}
            </div>
          </div>

          <div className=' theme-text-color formating pb-3 pt-5 w-100'>
            <div className='theme-text-color format-heading py-0 text-level-5 line-hight-12'>
              #Tag
            </div>
            <div className=' formating-content p-0 m-0 text-level-6 text-gray mb-2'>
              Separate Tags By Comma (,)
            </div>
            <div className='theme-text-color w-100'>
              <div
                className={` ${
                  hashTags.length > 0 ? 'tags-input-container m-0' : ''
                }  w-100`}
              >
                {hashTags &&
                  hashTags.length > 0 &&
                  hashTags.map((tag, index) => (
                    <div className='tag-item' key={index}>
                      <span className='text'>{tag}</span>
                      <p className='close' onClick={() => handleCloseTag(tag)}>
                        &times;
                      </p>
                    </div>
                  ))}
                <div className='mt-2 w-100'>
                  <TextFieldWrapper
                    //className='wallet-select-field selected-text description-text tags-input mt-3 w-100'
                    className='mt-3'
                    label='HashTag'
                    fullWidth
                    //placeholder="What's your #?"
                    onKeyUp={(e) => handleTagWithComma(e)}
                    onChange={(e) => {
                      if (/^[a-zA-Z0-9]*$/g.test(e.target.value)) {
                        setHashValue(
                          e.target.value
                            .trim()
                            .replace(
                              /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/~`?]+/,
                              ''
                            )
                        );
                      }
                    }}
                    id='hashtags'
                    value={hashvalue}
                    inputProps={{ minLength: 3, maxLength: 16 }}
                  />
                </div>
                {hashTags.length === 5 && (
                  <p className='mb-3 formating-content p-0 m-0 text-level-6 text-gray'>
                    Maximum 5 tags are allowed
                  </p>
                )}

                {hashValueError !== '' && (
                  <p className='mb-3 formating-content p-0 m-0 text-xl'>
                    {hashValueError}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* <CanvasDraw /> */}
          <div className='w-100 h-100 pb-5'>
            <div className=' mb-5 w-100 h-100'>
              <div className='d-flex flex-wrap'>
                <div className='column-6'>
                  <div className='pb-4'>
                    <div className='btn-preview d-flex align-items-center justify-content-center flex-wrap'>
                      <TooltipWrapper title='Add Text'>
                        <IconButton
                          color='primary'
                          onClick={() => addText()}
                          className='tooltips p-1'
                        >
                          <Fab className='bg-yellow btn-fs fade show'>
                            <img
                              src={textIcon}
                              alt='add text'
                              className='w-50 h-50'
                            />
                          </Fab>
                          {/* <span className='tooltipstext'>Add Text</span> */}
                        </IconButton>
                      </TooltipWrapper>
                      {backgroundPreview !== null && (
                        <TooltipWrapper title='Remove Background'>
                          <IconButton
                            color='primary'
                            onClick={() => removeBackgroundImage()}
                            className='p-1'
                          >
                            <Fab className='bg-yellow btn-fs fade show '>
                              <img
                                src={backgroundIcon}
                                alt='add text'
                                className='w-50 h-50'
                              />
                            </Fab>
                            {/* <span className='tooltipstext'>
                              Remove Background
                            </span> */}
                          </IconButton>
                        </TooltipWrapper>
                      )}

                      {canvasActiveObj !== null &&
                        canvasActiveObj !== {} &&
                        Object.keys(canvasActiveObj).length !== 0 && (
                          <>
                            <TooltipWrapper title='Send to Back'>
                              <IconButton
                                color='primary'
                                onClick={() =>
                                  performActioninCanvas('sendToBack')
                                }
                                className='tooltips p-1'
                              >
                                <Fab className='bg-yellow btn-fs fade show '>
                                  <img
                                    src={sendBackIcon}
                                    alt='send back'
                                    className='w-50 h-50'
                                  />
                                </Fab>
                                {/* <span className='tooltipstext'>
                                  Send to Back
                                </span> */}
                              </IconButton>
                            </TooltipWrapper>
                            <TooltipWrapper title='Send Backward'>
                              <IconButton
                                color='primary'
                                onClick={() =>
                                  performActioninCanvas('sendBackwards')
                                }
                                className='tooltips p-1'
                              >
                                <Fab className='bg-yellow btn-fs fade show '>
                                  <img
                                    src={sendBackwardIcon}
                                    alt='send backward'
                                    className='w-50 h-50'
                                  />
                                </Fab>
                                {/* <span className='tooltipstext'>
                                  Send Backward
                                </span> */}
                              </IconButton>
                            </TooltipWrapper>
                            <TooltipWrapper title='Bring Forward'>
                              <IconButton
                                color='primary'
                                onClick={() =>
                                  performActioninCanvas('bringForwards')
                                }
                                className='tooltips p-1'
                              >
                                <Fab className='bg-yellow btn-fs fade show '>
                                  <img
                                    src={bringForwardIcon}
                                    alt='bring forward'
                                    className='w-50 h-50'
                                  />
                                </Fab>
                                {/* <span className='tooltipstext'>
                                  Bring Forward
                                </span> */}
                              </IconButton>
                            </TooltipWrapper>
                            <TooltipWrapper title='Bring to Front'>
                              <IconButton
                                color='primary'
                                onClick={() =>
                                  performActioninCanvas('bringToFront')
                                }
                                className='tooltips p-1'
                              >
                                <Fab className='bg-yellow btn-fs fade show '>
                                  <img
                                    src={bringFrontIcon}
                                    alt='bring to front'
                                    className='w-50 h-50'
                                  />
                                </Fab>
                                {/* <span className='tooltipstext'>
                                  Bring to Front
                                </span> */}
                              </IconButton>
                            </TooltipWrapper>
                            <TooltipWrapper title='Flip X'>
                              <IconButton
                                color='primary'
                                onClick={() => performActioninCanvas('flipX')}
                                className='tooltips p-1'
                              >
                                <Fab className='bg-yellow btn-fs fade show '>
                                  <img
                                    src={flipHorizontalIcon}
                                    alt='flip X'
                                    className='w-75 h-75'
                                  />
                                </Fab>
                                {/* <span className='tooltipstext'>Flip X</span> */}
                              </IconButton>
                            </TooltipWrapper>
                            <TooltipWrapper title='Flip Y'>
                              <IconButton
                                color='primary'
                                onClick={() => performActioninCanvas('flipY')}
                                className='tooltips p-1'
                              >
                                <Fab className='bg-yellow btn-fs fade show '>
                                  <img
                                    src={flipVerticalIcon}
                                    alt='flip Y'
                                    className='w-75 h-75'
                                  />
                                </Fab>
                                {/* <span className='tooltipstext'>Flip Y</span> */}
                              </IconButton>
                            </TooltipWrapper>
                            <TooltipWrapper title='Delete'>
                              <IconButton
                                color='primary'
                                onClick={() => performActioninCanvas('delete')}
                                className='tooltips p-1'
                              >
                                <Fab className='bg-yellow btn-fs fade show '>
                                  <img
                                    src={deleteIcon}
                                    alt='delete Object'
                                    className='w-50 h-50'
                                  />
                                </Fab>
                                {/* <span className='tooltipstext'>Delete</span> */}
                              </IconButton>
                            </TooltipWrapper>
                            <TooltipWrapper title='Deselect'>
                              <IconButton
                                color='primary'
                                onClick={() =>
                                  performActioninCanvas('deselect')
                                }
                                className='p-1'
                              >
                                <Fab className='bg-yellow btn-fs fade show '>
                                  <img
                                    src={deselectIcon}
                                    alt='delete Object'
                                    className='w-50 h-50'
                                  />
                                </Fab>
                                {/* <span className='tooltipstext'>Delete</span> */}
                              </IconButton>
                            </TooltipWrapper>
                          </>
                        )}
                    </div>
                  </div>
                  <div className='w-100 h-100 d-flex justify-content-center align-items-start'>
                    <div
                      id='cnav'
                      className=' d-flex justify-content-center align-items-center '
                    >
                      <canvas
                        id='merge-previwer'
                        style={{
                          borderRadius: '0.125rem',
                          boxShadow:
                            'rgba(255, 255, 0, 0.15) 0px 2px 1px, rgba(255, 255, 0, 0.15) -2px -2px 1px',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className=' theme-text-color formating py-3 column-6'>
                  <h4 className='theme-text-color format-heading py-0 text-center text-level-3 fw-5 pt-1'>
                    See Final Result
                  </h4>
                  <div className='py-4 cards-group row  g-4 '>
                    <div className='px-2 col d-flex justify-content-center'>
                      <div
                        style={{
                          /* height: canvasHeight, */ width: `${canvasWidth}px`,
                        }}
                        className='card p-0'
                      >
                        <img
                          height={canvasHeight}
                          width={canvasWidth}
                          //src={mergePreview ? mergePreview : nft}
                          src={mergePreview && mergePreview}
                          className='card-img-top'
                          alt='Preview not genrated yet.'
                        />
                        <button
                          type='button'
                          className='butn adjust-btn'
                          onClick={() => handleOpen()}
                          disabled={selectedNfts.length == 0}
                        >
                          See Final Result
                        </button>
                        <button
                          type='button'
                          className='butn download-butn'
                          onClick={() => {
                            downloadMergePreview();
                          }}
                          disabled={selectedNfts.length == 0}
                        >
                          <img src={downloadIcon} alt='merge preview' />
                        </button>
                        <div className='card-body'>
                          <h5 className='card-title text-level-3 one-line-text'>
                            {title !== '' ? title : 'Enter title'}
                          </h5>

                          <p className='card-text py-2 gap-2 d-flex justify-content-between align-items-center'>
                            {hashTags.length > 0 ? (
                              <span className=' hash-tag px-2 text-level-6'>
                                #{hashTags[0]}
                              </span>
                            ) : (
                              <span className=' hash-tag px-2 text-level-6'>
                                #HashTag
                              </span>
                            )}
                            {/* <span className=" hash-tag px-2">#HashTag</span> */}
                            <span className=' hash-tag px-2 text-level-6'>
                              {dateFormat(new Date(), 'dd/mm/yyyy')}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='fixed-bottom px-3 py-3 w-100 bg-dark border-top'>
            <button type='submit' className='butn butn-primary w-100'>
              Save Merger
            </button>
          </div>
        </form>

        {/* dialog for add new wallets */}
        <Modal
          open={openForm}
          onClose={(e) => {
            setOpenForm(false);
            resetNewWalletForm();
            setNewWalletErrors({});
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-auto m-auto'
        >
          <Box sx={style} className='' autoComplete='off'>
            <div className='d-flex justify-content-between modal-header px-1'>
              <h3 className='modal-title fw_bolder'>Add Wallet</h3>
              <button
                type='button'
                className='modal-butn fw_bolder'
                onClick={(e) => {
                  setOpenForm(false);
                  resetNewWalletForm();
                }}
              >
                x
              </button>
            </div>
            <div className='px-1 py-3 '>
              <p className='modal-p'>
                Add new wallet to adjust Merger your merger.
              </p>
            </div>
            <div className='px-1'>
              <form
                onSubmit={(e) => handleNewWalletSubmit(e)}
                encType='multipart/plain-text'
                noValidate
              >
                <div className='py-1'>
                  <TextFieldWrapperDark
                    id='new_wallet_address'
                    name='new_wallet_address'
                    label='Wallet Adress'
                    value={newWallet}
                    onChange={(e) => {
                      setNewWallet(e.target.value.trim());
                      FormValidator.handleOnBlur(e, setNewWalletErrors);
                    }}
                    onBlur={(e) =>
                      FormValidator.handleOnBlur(e, setNewWalletErrors)
                    }
                    className='p-0 m-0'
                    fullWidth
                    required
                    autoFocus={true}
                    inputProps={{ maxLength: 100 }}
                    size={width <= 375 ? 'small' : 'medium'}
                  />
                  {newWalletErrors && newWalletErrors.new_wallet_address && (
                    <div className='error ps-2 pt-2'>
                      <span className='error-span'>
                        <i className='fa-solid fa-circle-exclamation pe-1'></i>
                        {newWalletErrors.new_wallet_address}
                      </span>
                    </div>
                  )}
                </div>
                <div className='py-1'>
                  <TextFieldWrapperDark
                    id='wallet_alias'
                    name='wallet_alias'
                    label='Alias'
                    value={walletAlias}
                    onChange={(e) => {
                      setWalletAlias(e.target.value.replace(/^[0-9]/, ''));
                      FormValidator.handleOnBlur(e, setNewWalletErrors);
                    }}
                    fullWidth
                    className='px-0 mx-0'
                    required
                    inputProps={{ maxLength: 20 }}
                    onBlur={(e) =>
                      FormValidator.handleOnBlur(e, setNewWalletErrors)
                    }
                    size={width <= 375 ? 'small' : 'medium'}
                  />
                  {newWalletErrors && newWalletErrors.wallet_alias && (
                    <div className='error ps-2'>
                      <span className='error-span'>
                        <i className='fa-solid fa-circle-exclamation pe-1'></i>
                        {newWalletErrors.wallet_alias}
                      </span>
                    </div>
                  )}
                </div>

                <button type='submit' className='butn w-100 py-3'>
                  Add
                </button>
              </form>
            </div>
          </Box>
        </Modal>

        {/* modal for select artworks */}
        <Modal
          open={open}
          onClose={toggleModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-auto m-auto'
        >
          <Box sx={style} className='' autoComplete='off'>
            <div className='d-flex justify-content-between modal-header px-1'>
              <h3 className='modal-title fw_bolder'>Final Preview</h3>
              <button
                type='button'
                className='modal-butn fw_bolder'
                onClick={() => toggleModal()}
              >
                x
              </button>
            </div>

            <div className='px-1 d-flex justify-content-center align-items-center'>
              <img
                src={mergePreview}
                style={{
                  boxShadow:
                    '2px 2px 2px 2px rgba(255,255,0,.85), -2px -2px 2px 2px rgba(255,255,0,.85)',
                  borderRadius: '0.25rem',
                  border: '2px solid black',
                }}
                className='nft-prevew-dimensions mx-auto '
              />
            </div>
          </Box>
        </Modal>
      </section>
    </>
  );
};

export default CreateMerger;
