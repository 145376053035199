import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#36373f',
    color: 'yellow',
    fontSize: '0.8rem',
  },
 
}));

export default function TooltipWrapper(props) {
  return (
    <BootstrapTooltip
      title={props?.title || ''}
      arrow
      placement='top'
      followCursor
    >
      {props.children}
    </BootstrapTooltip>
  );
}
