import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { UserService } from '../../../services/admin';
import { StorageService } from '../../../services';
import ErrorFormat from '../../../utils/ErrorFormat;';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';
import { PreLoaderComponent, PreLoaderComponent2 } from '../../../components';
import FormValidator from '../../../utils/validation';
import useScreen from '../../../hooks/useScreen';
import TextFieldWrapper from '../../../components/TextFieldWrapper';

const Verification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useScreen();
  const [email, setEmail] = useState('');
  const [vcode, setVcode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const resendVerification = (e) => {
    e.stopPropagation(); // don't select this row after clicking
    if (email === '') {
      return Swal.fire({
        icon: 'error',
        title: 'Error occurred',
        html: `<p>Oh no, You can't do this mannually.</p>`,
      });
    }
    setIsLoading(true);
    UserService.resendVerification(email)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          Swal.fire('Success', res.message, 'info');
        }
      })
      .catch((error) => {
        setIsLoading(false);
        //handleClose();
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === 'User account deactivated'
        ) {
          Swal.fire({
            icon: 'warning',
            title: 'Inactive account!',
            html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
          }).then(() => {
            StorageService.clearSession();
            navigate('/admin/signin');
            return window.location.reload();
          });
        } else if (error.response.data.message) {
          Swal.fire({
            icon: 'error',
            title: 'Error occurred',
            html: `<p>${error.response.data.message}</p>`,
          });
        }
      });
  };

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  const setVerificationCode = (value) => {
    let new_value = '';
    let regExp = /[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    for (let i = 0; i < value.length; i++) {
      if (i > 5) {
        new_value += '';
      } else if (!regExp.test(value[i])) {
        new_value += value[i];
      } else {
        new_value += '';
      }
    }
    return setVcode(new_value);
  };

  const resetForm = () => {
    setVcode('');
    //setEmail('');
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    FormValidator.handleOnSubmit('admin_email2', setErrors);
    FormValidator.handleOnSubmit('verificationCode2', setErrors);

    let noOfErrors = 0;
    let firstKey;
    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      setIsLoading(true);

      const data = {
        email: email,
        verification_code: vcode,
      };

      UserService.verifyAccount(data)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            StorageService.setSession(res.token, res.data);
            resetForm();
            if (res.data.role === 'admin') {
              navigate('/admin/signin');
              return window.location.reload();
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (error.response.data.errors) {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: ErrorFormat.validationErrors(error.response.data.errors),
              });
            }if (error.response.data.message === 'Invalid verification code!') {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: `<p>${error.response.data.message}</p>`,
              });
            } else if (error.response.data.message) {
              if (error.response.data.message === 'Account is inactive') {
                resetForm();
              }
              Swal.fire({
                icon: 'waring',
                title: 'Inactive account!',
                html: `<p>${error.response.data.message}</p>`,
              });
            }
          }
        });
    }
  };

  return (
    <>
      <div className='px-4 py-4 d-flex justify-content-center align-items-center w-100 min-h-100vh'>
        {/* {isLoading ? (
          <PreLoaderComponent />
        ) : ( */}
          <form
            className='signin-form px-4 py-4 w-100 h-100'
            encType='multipart/form-data'
            onSubmit={(e) => submitHandler(e)}
            noValidate
          >
            <h1 className='text-center ad-dash-main-heading heading-level-2 pb-3 fx-sh'>
              Email Verification
            </h1>
            <div className=' theme-text-color formating'>
              <div className='theme-text-color'>
                <TextFieldWrapper
                  fullWidth
                  //className='wallet-select-field selected-text description-text'
                  hidden={true}
                  onChange={(e) => {
                    setEmail(e.target.value.trim());
                    FormValidator.handleOnBlur(e, setErrors);
                  }}
                  value={email.trim()}
                  label='Email'
                  type='email'
                  id='admin_email2'
                  //placeholder='Enter email...'
                  required
                  disabled={true}
                  onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  inputProps={{ maxLength: 128 }}
                  size={width <= 320 ? 'small' : 'medium'}
                />
                {errors && errors.admin_email2 && (
                  <div className='error ps-2'>
                    <span className='error-span mt-0 pb-0 '>
                      <i className='fa-solid fa-circle-exclamation pe-1'></i>
                      {errors.admin_email2}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex justify-content-center text-center align-items-center text-orange'>
              <text className='text-orange text-level-6'>
                {email === ''
                  ? 'Oh no, You can not do this manually.'
                  : `Please enter verification code sent at ${email}`}
              </text>
            </div>
            <div className=' theme-text-color formating py-3 '>
              <div className='theme-text-color'>
                <TextFieldWrapper
                  type='string'
                  autoFocus
                  fullWidth
                  className='wallet-select-field selected-text description-text'
                  onChange={(e) => {
                    setVerificationCode(e.target.value.trim());
                    FormValidator.handleOnBlur(e, setErrors);
                  }}
                  value={vcode.trim()}
                  label='Verification Code'
                  // placeholder='Verification Code'
                  required
                  // autoFocus={true}
                  id='verificationCode2'
                  inputProps={{ maxLength: 6 }}
                  onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  size={width <= 320 ? 'small' : 'medium'}
                />
                {errors && errors.verificationCode2 && (
                  <div className='error ps-2'>
                    <span className='error-span mt-0 pb-0'>
                      <i className='fa-solid fa-circle-exclamation pe-1'></i>
                      {errors.verificationCode2}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className=' w-100 py-3 d-flex flex-column align-items-center gap-2'>
              <u
                onClick={resendVerification}
                className='text-level-5 text-yellow cursor-pointer'
              >
                Resend Code
              </u>
              <button type='submit' className='butn butn-lg-primary w-100'>
                Verify Account
              </button>
            </div>
          </form>
        {/* )} */}
        {isLoading && <PreLoaderComponent2 />}
      </div>
    </>
  );
};

export default Verification;
