import React from "react";
import { CircularProgress, Box } from "@mui/material";

const PreLoaderComponent = (props) => {
  return (
    <>
      <Box className="preLoaderStyling">
        <CircularProgress className={`${props.color !== "dark"} ? "theme-text-color" : "text-dark"`} />
      </Box>
    </>
  );
};

export default PreLoaderComponent;
