import React from 'react';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const NofoundLayout = () => {
  const location = useLocation();

  useEffect(() => {
    Swal.close();
  }, [location]);
  return (
    <>
      <Outlet />
    </>
  );
};

export default NofoundLayout;
