import API from "../../config/api.config";

const getArts = (current_page = 1, limit = 10) => {
  return API.get(`/artwork/get-artworks/${current_page}/${limit}`).then(
    (res) => {
      return res.data;
    }
  );
};

const cahngeStatus = (artwork_id) => {
  return API.patch(`/artwork/change-status/${artwork_id}`).then((res) => {
    return res.data;
  });
};
const getArt = (artwork_id) => {
  return API.get(`/artwork/get-artwork/${artwork_id}`).then(
    (res) => {
      return res.data;
    }
  );
};

const ArtworkService = {
  getArts,
  cahngeStatus,
  getArt
};

export default ArtworkService;
