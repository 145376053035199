import React, { useEffect, useState } from 'react';
import {
  NftService,
  StorageService,
  ThirdPartyService,
} from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { PreLoaderComponent } from '../../../components';
import Swal from 'sweetalert2';

const Nfts = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const wallets = StorageService.getCurrentUser().wallets;
  const [currentPage, setCurrentPage] = useState(params.page ? params.page : 1);
  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  const storeNfts = (wallet_info, wallet_address) => {
    setIsLoading(true);

    ThirdPartyService.refreshNFT(wallet_address)
      .then((nfts) => {
        setIsLoading(false);
        if (nfts) {
          const data = {
            wallet_info: wallet_info,
            wallet_address: wallet_address,
            assets: nfts.assets,
          };

          if (nfts.assets.length > 0) {
            setIsLoading(true);
            NftService.storeNFTList(data)
              .then((res) => {
                setIsLoading(false);
                if (res.status) {
                }
              })
              .catch((error) => {
                setIsLoading(false);
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                ) {
                  if (
                    error.response.data.message === 'User account deactivated'
                  ) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Inactive account!',
                      html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
                    }).then(() => {
                      StorageService.clearSession();
                      navigate('/connect-to-wallet');
                      return window.location.reload();
                    });
                  }
                }
              });
          }
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        }
      });
  };

  const getAllNfts = () => {
    setIsLoading(true);
    let wallet_address = [];

    const wallet_is_array = Array.isArray(wallets);
    if (wallet_is_array) {
      for (let i = 0; i < wallets.length; i++) {
        const wallet = wallets[i];
        wallet_address.push(wallet.wallet_address);
        storeNfts('personal', wallet.wallet_address);
      }
    } else {
      wallet_address.push(wallets.wallet_address);
      storeNfts('personal', wallets.wallet_address);
    }

    const data = {
      wallets: wallet_address,
    };

    NftService.getAllPersonalNfts(data, currentPage)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          if (res.message !== 'No NFT found!') {
            setNfts(res.data.assets);
            setFirstPage(res.pagenation.first_page);
            setLastPage(res.pagenation.last_page);
            setTotalResults(res.pagenation.total);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        }
      });
  };

  useEffect(() => {
    getAllNfts();
  }, [currentPage]);

  const hanldePagenation = (pagenate_type) => {
    if (pagenate_type === 'first') {
      setCurrentPage(firstPage);
    } else if (pagenate_type === 'last') {
      setCurrentPage(lastPage);
    } else if (pagenate_type === 'next') {
      // handle next
      setCurrentPage(currentPage + 1);
    } else if (pagenate_type === 'prev') {
      // handle previous
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <section className='py-4 '>
      {/* nfts cards */}
      <div className='py-4 cards-group row g-4 h-100 justify-content-center mb-0'>
        {isLoading ? (
          <PreLoaderComponent />
        ) : nfts.length > 0 ? (
          nfts.map((item, index) => {
            return (
              item.image_preview_url !== null && (
                <div className='px-2 custom-column' key={index}>
                  <div className='card pb-0'>
                    <div className='img-container'>
                      <img
                        src={item.image_preview_url}
                        className='card-img-top '
                        alt='nfts card'
                      />
                    </div>
                    <div className='card-body'>
                      <h5 className='card-title one-line-text'>
                        {item.name !== '' && item.name !== null
                          ? item.name[0].toUpperCase() + item.name.slice(1)
                          : ''}
                      </h5>
                    </div>
                  </div>
                </div>
              )
            );
          })
        ) : (
          <h4 className='mx-auto theme-text-color text-center text-level-4'>
            No NFT Found!
          </h4>
        )}
      </div>
      {!isLoading && nfts.length > 0 && (
        <div className='d-flex justify-content-around align-items-center py-3 mb-3'>
          <div className='d-flex justify-content-center align-items-center'>
            <div>
              <button
                className='mui-icon-btn'
                variant='text'
                onClick={() => hanldePagenation('first')}
                disabled={currentPage == firstPage}
              >
                <i className='fa-solid fa-angles-left mui-pagenate-icon'></i>
              </button>
              <button
                className='mui-icon-btn'
                variant='text'
                onClick={() => hanldePagenation('prev')}
                disabled={currentPage == firstPage}
              >
                <i className='fa-solid fa-chevron-left mui-pagenate-icon'></i>
              </button>
            </div>
            <div>
              <button
                className='mui-icon-btn'
                variant='text'
                onClick={() => hanldePagenation('next')}
                disabled={currentPage == lastPage}
              >
                <i className='fa-solid fa-chevron-right mui-pagenate-icon'></i>
              </button>
              <button
                className='mui-icon-btn'
                variant='text'
                onClick={() => hanldePagenation('last')}
                disabled={currentPage == lastPage}
              >
                <i className='fa-solid fa-angles-right mui-pagenate-icon'></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Nfts;
