//AutocompleteField
import * as React from 'react';

import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import useScreen from '../hooks/useScreen';

const Input = styled(TextField)(({ theme }) => {
  const { width } = useScreen();
  return {
    '& label': {
      color: 'yellow !important',
      backgroundColor: '#1b1d21 !important',
      padding: '0px 8px !important',
      fontSize:
        width > 1600
          ? '2ren !important'
          : width < 1600 && width > 500
          ? '1.2rempx !important'
          : '1rem !important',
      top:
        width > 1600
          ? '2px !important'
          : width < 1600 && width > 500
          ? '1px !important'
          : '-1px !important',
    },
    '& .Mui-error fieldset': {
      borderColor: '#f50057 !important',
    },
    '& .MuiInputBase-root': {
      width: '100% !important',
      boxShadow: '0 0 0 0.1rem var(--yellow) !important',
      '& i': { color: 'yellow' },
      '&.MuiInputBase-root fieldset': {
        border: '1px solid var(--yellow) !important',
      },
      '&.Mui-focused fieldset': {
        border: '0.3px solid var(--yellow) !important',
        boxShadow: '0 0 0 0.2rem var(--yellow) !important',
      },
      '&:hover fieldset': {
        borderColor: 'red !imortant',
      },
    },
    '& .MuiInputBase-input': {
        width: '80% !important',
      '::placeholder': { color: 'yellow !important' },
      color: 'yellow !important',
      position: 'relative !important',
      backgroundColor: '#1b1d21 !important',
      border: 'none !important',
      paddingLeft: '4px !important',
      paddingRight:'30px !important',
      fontSize: '100% !important',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    '& textarea': {
      '::placeholder': { color: 'yellow !important' },
      '& #description': {
        color: 'yellow !important',
        position: 'relative !important',
        backgroundColor: '#1b1d21 !important',
      },
      width: '80% !important',
      color: 'yellow !important',
      position: 'relative !important',
      backgroundColor: '#1b1d21 !important',
      border: 'none !important',
      paddingLeft: '4px !important',
      paddingRight:'30px !important',
      fontSize: '100% !important',
      padding: '10px 0px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  };
});

const AutocompleteField = (props) => {
  const { width } = useScreen();
  return <Input {...props} sx={{}} className='TextFieldWrapper w-100' />;
};

export default AutocompleteField;
