import API from "../../config/api.config";

const getUsers = (current_page = 1, limit = 10) => {
  return API.get(`/admin/get-users/${current_page}/${limit}`).then((res) => {
    return res.data;
  });
};

const resendVerification = (user_email) => {
  return API.get(`/admin/resend-verification-code/${user_email}`).then(
    (res) => {
      return res.data;
    }
  );
};

const cahngeStatus = (user_id) => {
  return API.patch(`/admin/change-status/${user_id}`).then((res) => {
    return res.data;
  });
};

const verifyAccount = (data) => {
  return API.patch(`/admin/verify`, data).then((res) => {
    return res.data;
  });
};

const updateProfile = (user_id, data) => {
  return API.patch(`/admin/update-profile/${user_id}`, data).then((res) => {
    return res.data;
  });
};

const changePassword = (user_id, data) => {
  return API.patch(`/admin/change-password/${user_id}`, data).then((res) => {
    return res.data;
  });
};

const addUser = (data) => {
  return API.post(`/admin/add-account`, data).then((res) => {
    return res.data;
  });
};

const UserService = {
  getUsers,
  resendVerification,
  cahngeStatus,
  verifyAccount,
  updateProfile,
  changePassword,
  addUser,
};

export default UserService;
