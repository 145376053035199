import React, { useEffect } from 'react';
import { AdminNavComponent, AdminFooterComponent } from '../components';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthService, StorageService } from '../services';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const AdminLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Swal.close();
  }, [location]);

  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      navigate('/admin/signin');
    } else if (StorageService.getCurrentUser().role !== 'admin') {
      navigate('/dashboard');
    }
  }, []);

  return (
    <>
      <AdminNavComponent />
      <section className='admin-dash-container'>
        <Outlet />
      </section>
      <AdminFooterComponent />
    </>
  );
};

export default AdminLayout;
