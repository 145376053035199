import React, { useEffect } from 'react';
import { StickyHeaderComponent } from '../components';
import { Outlet } from 'react-router-dom';
import { StorageService, AuthService } from '../services';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Swal.close();
  }, [location]);

  useEffect(() => {
    if (
      AuthService.isLoggedIn() &&
      StorageService.getCurrentUser().role === 'admin'
    ) {
      navigate('/admin');
    }
  }, []);

  return (
    <>
      <div id='page'>
        <StickyHeaderComponent />
        <div className='page-title-clear'></div>
        <div
          id='menu-main'
          className='menu menu-box-left rounded-0'
          data-menu-width='280'
          data-menu-active='nav-welcome'
          data-menu-load='menu-main.html'
        ></div>
        <div
          id='menu-share'
          className='menu menu-box-bottom rounded-m'
          data-menu-load='menu-share.html'
          data-menu-height='370'
        ></div>
        <div
          id='menu-colors'
          className='menu menu-box-bottom rounded-m'
          data-menu-load='menu-colors.html'
          data-menu-height='480'
        ></div>
        <div className='page-content'>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default MainLayout;
