import API from "../config/api.config";

const mergedLikeAndDisklike = async (merge_id) => {
  return API.patch(`/merged/change-like-status/${merge_id}`).then((res) => {
    return res.data;
  });
};

const getPersonalMergedNfts = async (current_page = 1, limit = 10) => {
  return API.get(`/merged/personal-merged-nfts/${current_page}/${limit}`).then(
    (res) => {
      return res.data;
    }
  );
};

const MergedService = {
  getPersonalMergedNfts,
  mergedLikeAndDisklike,
};

export default MergedService;
