import React, { useRef, useState, useEffect } from 'react';
import { metamask, etheriumWallet, TrustWalletIcon } from '../../utils/images';
import { Box, Modal, TextField, FormControl } from '@mui/material';
import { ethers } from 'ethers';
import { StorageService, AuthService, UserService } from '../../services';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import FormValidator from '../../utils/validation';
import { PreLoaderComponent2 } from '../../components';
import useScreen from '../../hooks/useScreen';
import TextFieldWrapperDark from '../../components/TextFieldWrapperDark';

const ConnectToWallets = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useScreen();
  const formTypes = ['signup', 'verification', 'signin'];
  const [wallet, setWallet] = useState(null);
  const [walletAlias, setWalletAlias] = useState(null);
  const [email, setEmail] = useState(null);
  const [website, setWebsite] = useState(null);
  const [walletType, setWalletType] = useState(null);
  const [avatar, setAvatar] = useState({});
  const [profilePicPreview, setProfilePreview] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const fileInput = useRef(null);
  const toggleModal = () => {
    setOpen(!open);
    Swal.close();
  };
  const sign_in_types = ['signin', 'signup'];
  const [signInType, setSignInType] = useState(sign_in_types[0]);
  const [errors, setErrors] = useState({});
  const [uploadError, setUploadError] = useState('');
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (!open) {
      setWalletAlias('');
      setEmail('');
      setWebsite('');
      setAvatar({});
      setProfilePreview({});
      setUploadError('');
      setErrors({});
      setFileName('');
    }
  }, [open]);

  useEffect(() => {
    if (location.state && location.state.like_merge_id) {
      connectToMetaMask();
    }
  }, [location.state]);

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      navigate('/dashboard');
    }
  }, []);

  const resetForm = () => {
    setWalletAlias(null);
    setEmail(null);
    setWebsite(null);
    setWalletType(null);
    setAvatar(null);
    setProfilePreview(null);
  };

  // const connectToMetaMask = async () => {
  //   setIsLoading(true);
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   await provider
  //     .send("eth_requestAccounts", [])
  //     .then((res) => {
  //       setIsLoading(false);
  //       if (res[0]) {
  //         setWalletType("MetaMask");
  //         setWallet(res[0]);
  //         setOpen(true);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //     });
  // };

  const connectToMetaMask = async () => {
    setIsLoading(true);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider
      .send('eth_requestAccounts', [])
      .then((res) => {
        setIsLoading(false);
        if (res[0]) {
          setWalletType('MetaMask');
          setWallet(res[0]);
          setOpen(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const SignIn = async () => {
    setIsLoading(true);

    const formObj = {
      email: email,
      website: website,
      wallet_type: walletType,
      wallet_address: wallet,
      alias: walletAlias !== null ? walletAlias.trim() : walletAlias,
      avatar: avatar,
      signin_type: signInType,
    };

    AuthService.login(formObj)
      .then((res) => {
        setIsLoading(false);

        if (res.status) {
          StorageService.setSession(res.token, res.data);
          resetForm();
          if (
            location.state &&
            location.state.like_merge_id &&
            location.state.prev_location
          ) {
            navigate(`${location.state.prev_location}`, {
              state: { like_merge_id: location.state.like_merge_id },
            });
            return window.location.reload();
          } else if (res.data.role === 'user') {
            navigate('/dashboard');
            return window.location.reload();
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.data && error.response.data.errors) {
          Swal.fire('Error', 'Wallet not found!', 'error');
        } else if (
          error.response.data &&
          error.response.data.message === 'Account is inactive!'
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Account is inactivated!',
            html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
          }).then(() => setSignInType(sign_in_types[0]));
        } else if (
          error.response.data &&
          error.response.data.message ===
            "Couldn't find account. Need to Register?"
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Error occurred',
            html: `<p>${error.response.data.message}</p>`,
          }).then(() => setSignInType(sign_in_types[1]));
        } else if (
          error.response.data &&
          error.response.data.message === 'Account is inactive!'
        ) {
          Swal.fire({
            icon: 'warning',
            title: 'Error occurred',
            html: 'Account is already registerd' /* `<p>${error.response.data.message}</p>`, */,
          });
        } else if (error.response.data && error.response.data.message) {
          Swal.fire({
            icon: 'error',
            title: 'Error occurred',
            html: 'Account is already registerd' /* `<p>${error.response.data.message}</p>`, */,
          });
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (signInType == sign_in_types[1]) {
      if (walletAlias === null) {
        FormValidator.handleOnSubmit('name', setErrors);
      }

      if (website === null) {
        FormValidator.handleOnSubmit('email2', setErrors);
      }

      if (email === null) {
        FormValidator.handleOnSubmit('url', setErrors);
      }
    }

    let noOfErrors = 0;
    let firstKey;

    // for (let i = 0; i <= Object.entries(errors).length - 1; i++){
    //   const values =  Object.values(errors);
    //   setIsLoading(false);
    // }

    // Object.entries(errors).map((entry, index)=>{
    //   if(entry[1] !== "")
    //   {
    //     FormValidator.handleOnSubmit(entry[0], setErrors)
    //   }
    // })

    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      let getFirstErrorElement = document.getElementById(firstKey);
      getFirstErrorElement.focus();
      return;
    } else {
      return SignIn();
    }
  };

  const handleFileInput = (e) => {
    // get size in MBs
    const fileSize = e.target.files[0].size / (1024 * 1024);
    if (fileSize <= 8) {
      if (
        e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/jpg' ||
        e.target.files[0].type === 'image/jpeg'
      ) {
        var image = new Image();
        image.onload = function () {
          if (image.width < 1000 || image.height < 1000) {
            e.target.files = undefined;
            e.target.value = null;
            setUploadError('Picture must be greater than 1000 x 1000 px');
            setFileName('');
            setAvatar({});
            return setProfilePreview({});
          } else {
            setUploadError('');
            setAvatar(e.target.files[0]);
            setFileName(e.target.files[0].name);
            return setProfilePreview(URL.createObjectURL(e.target.files[0]));
          }
        };
        image.src = URL.createObjectURL(e.target.files[0]);
      } else {
        setFileName('');
        setAvatar({});
        setProfilePreview({});
        setUploadError('Only png, jpg, jpeg extensions are allowed');
        e.target.files = undefined;
        return (e.target.value = null);
      }
    } else if (
      e.target.files[0].type !== 'image/png' &&
      e.target.files[0].type !== 'image/jpg' &&
      e.target.files[0].type !== 'image/jpeg'
    ) {
      setAvatar({});
      setFileName('');
      setProfilePreview({});
      setUploadError('Only png, jpg, jpeg extensions are allowed');
      e.target.files = undefined;
      return (e.target.value = null);
    } else {
      setAvatar({});
      setFileName('');
      setProfilePreview({});
      setUploadError('Uploaded picture not be more than 8MB');
      e.target.files = undefined;
      return (e.target.value = null);
    }
  };

  const style = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '4rem',
    width: '90%',
    bgcolor: '#ffff00',
    boxShadow: 24,
    p: 3,
    borderRadius: '15px',
    '& .MuiTextField-root': { my: 1, width: '25ch' },
  };

  const removeProfilePic = async (e) => {
    Swal.fire({
      title: 'Do you want to remove?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Remove',
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        setFileName('');
        setProfilePreview({});
        setAvatar({});
      }
    });
  };

  return (
    <>
      {isLoading && <PreLoaderComponent2 />}
      <section className='p-4'>
        <div>
          <h1 className='theme-text-color main-heading heading-level-3'>
            Choose Wallet
          </h1>
          <h4 className='theme-text-color choose-wallet-h4 text-p text-level-5'>
            Connecting Wallet means that you agree to the{' '}
            <span style={{ fontWeight: '700' }} className='theme-text-color '>
              VN
            </span>{' '}
            <span className='text-purple' style={{ color: '#7b61ff' }}>
              Terms of Service
            </span>{' '}
            and <span className='text-purple'>Privacy Policy</span>
          </h4>
          <div className='py-4 d-flex flex-column justify-content-around align-items-center gap-4 '>
            <button
              className='border w-100 butn wallet-btn text-level-3'
              onClick={() => connectToMetaMask()}
              disabled={isLoading}
            >
              <div className='wallet-logo'>
                <img src={metamask} alt='meta mask' className='w-100 h-100' />
              </div>
              MetaMask
            </button>
            <button
              className='border w-100 butn wallet-btn text-level-3'
              onClick={() => toggleModal()}
              disabled
            >
              <div className='wallet-logo'>
                <img
                  src={TrustWalletIcon}
                  alt='meta mask'
                  className='w-100 h-100'
                />
              </div>
              Trust Wallet
            </button>
            <button
              className='border w-100 butn wallet-btn text-level-3'
              onClick={() => toggleModal()}
              disabled
            >
              <div className='wallet-logo'>
                <img
                  src={etheriumWallet}
                  alt='meta mask'
                  className='w-100 h-100'
                />
              </div>
              Type Ethereum Address
            </button>
          </div>
        </div>
        <div>
          <Modal
            open={open}
            onClose={toggleModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className='modal-background overflow-auto'
          >
            <Box sx={style} autoComplete='off'>
              <div className='d-flex justify-content-between modal-header px-1'>
                <h3 className='modal-title fw_bolder'>Add details</h3>
                <button
                  type='button'
                  className='modal-butn fw_bolder'
                  onClick={() => toggleModal()}
                >
                  x
                </button>
              </div>
              <div className=' py-3 '>
                {/* <p className="modal-p">
                  Lorem ipsum dolar sit amet, consectetuer adipiscing
                  elit.Aenean commodo ligulia eget dolor.
                </p> */}
              </div>
              <div className=''>
                {signInType == sign_in_types[0] ? (
                  <form
                    onSubmit={(e) => handleSubmit(e)}
                    encType='multipart/form-data'
                    noValidate
                  >
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <TextFieldWrapperDark
                        className='w-100'
                        //required
                        id='walletAddress'
                        name='walletAddress'
                        label='Wallet Address *'
                        value={wallet}
                        disabled={true}
                        //className='text-field clr-black'
                      />
                      <div className='py-3 w-100'>
                        <button
                          type='submit'
                          className='butn w-100 text-level-3'
                          disabled={isLoading}
                        >
                          Sign In
                        </button>
                        <div className='d-flex justify-content-center align-items-center pt-2'>
                          <button
                            type='button'
                            className='upload-profile-btn resize-font-sm text-level-3'
                            onClick={() => {
                              setSignInType(sign_in_types[1]);
                              setErrors({});
                            }}
                          >
                            Create Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <form
                    onSubmit={(e) => handleSubmit(e)}
                    encType='multipart/form-data'
                  >
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <FormControl style={{ width: '100%' }}>
                        <TextFieldWrapperDark
                          //required={true}
                          className='w-100'
                          id='walletAddress'
                          label='Wallet Address *'
                          value={wallet}
                          disabled={true}
                          //className='text-field clr-black'
                        />
                        <TextFieldWrapperDark
                          //required={true}
                          className='w-100'
                          id='name'
                          name='name'
                          value={walletAlias}
                          onChange={(e) => {
                            setWalletAlias(
                              e.target.value.replace(/^[0-9]/, '')
                            );
                            FormValidator.handleOnBlur(e, setErrors);
                          }}
                          label='Alias *'
                          autoFocus={true}
                          //className='text-field clr-black'
                          inputProps={{ minLength: 2, maxLength: 20 }}
                          onBlur={(e) =>
                            FormValidator.handleOnBlur(e, setErrors)
                          }
                        />
                        {errors && errors.name && (
                          <div className='error ps-2'>
                            <span className='error-span'>
                              <i className='fa-solid fa-circle-exclamation pe-1'></i>
                              {errors.name}
                            </span>
                          </div>
                        )}
                        <TextFieldWrapperDark
                          className='w-100'
                          id='email2'
                          name='email2'
                          label='E-mail'
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value.trim());
                            FormValidator.handleOnBlur(e, setErrors);
                          }}
                          //className='text-field clr-black'
                          onBlur={(e) =>
                            FormValidator.handleOnBlur(e, setErrors)
                          }
                          inputProps={{ maxLength: 128 }}
                        />
                        {errors && errors.email2 && (
                          <div className='error ps-2'>
                            <span className='error-span'>
                              <i className='fa-solid fa-circle-exclamation pe-1'></i>
                              {errors.email2}
                            </span>
                          </div>
                        )}
                        <TextFieldWrapperDark
                          id='url'
                          className='w-100'
                          name='url'
                          label='Website'
                          value={website}
                          onChange={(e) => {
                            setWebsite(e.target.value.trim());
                            FormValidator.handleOnBlur(e, setErrors);
                          }}
                          //className='text-field clr-black'
                          onBlur={(e) =>
                            FormValidator.handleOnBlur(e, setErrors)
                          }
                          inputProps={{ maxLength: 500 }}
                        />
                        {errors && errors.url && (
                          <div className='error ps-2'>
                            <span className='error-span'>
                              <i className='fa-solid fa-circle-exclamation pe-1'></i>
                              {errors.url}
                            </span>
                          </div>
                        )}
                        <div className='up-bt-layout'>
                          <input
                            type='file'
                            ref={fileInput}
                            onChange={(e) => handleFileInput(e)}
                            hidden
                            name='profilePic'
                            id='profilePic'
                            accept='image/png, image/jpg, image/jpeg'
                          />

                          {/*  <div className='d-flex flex-column justify-content-center align-items-start '>
                            <button
                              type='button'
                              className='upload-profile-btn w-100 text-level-4'
                              onClick={() => fileInput.current.click()}
                              disabled={isLoading}
                            >
                              <i className='fa-solid fa-circle-plus pe-2'></i>
                              Upload Profile Picture
                            </button>

                            <div style={{ alignSelf: 'start' }}>
                              {uploadError !== '' ? (
                                <div className='error resposError'>
                                  <span className='error-span'>
                                    <i className='fa-solid fa-circle-exclamation pe-1'></i>
                                    {uploadError}
                                  </span>
                                </div>
                              ) : (
                                <div className='displayInstructions '>
                                  {' '}
                                  <p className=' text-dark'>
                                    Picture type: JPG, JPEG, PNG. Size more than
                                    1000x1000 px
                                  </p>
                                </div>
                              )}
                            </div>
                          </div> */}

                          {/*  */}
                          <div className='d-flex align-items-center'>
                            <button
                              type='button'
                              className='upload-profile-btn '
                              onClick={() => fileInput.current.click()}
                              disabled={isLoading}
                            >
                              <i className='fa-solid fa-circle-plus pe-2'></i>
                            </button>

                            <div className='d-flex flex-column justify-content-center align-items-start'>
                              <div className='text-level-4'>
                                Upload Profile Picture
                              </div>
                              <div>
                                {uploadError !== '' ? (
                                  <div className='error'>
                                    <span className='error-span line-hight-12'>
                                      <i className='fa-solid fa-circle-exclamation  line-hight-12'></i>
                                      {uploadError}
                                    </span>
                                  </div>
                                ) : (
                                  <div className='text-gray text-level-6 word-break-all line-height-14'>
                                    {fileName !== ''
                                      ? fileName
                                      : 'Picture type: JPG, JPEG, PNG. Size more than 1000 x 1000 px'}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {/*  */}

                          {Object.keys(profilePicPreview).length > 0 && (
                            <div className=' position-relative showRemoveIconOnhover'>
                              <img
                                src={profilePicPreview}
                                className='mx-auto profile-img-preview profile-dark-border'
                                alt='profile-pic'
                              />
                              <div
                                className='position-absolute top-50 start-50 translate-middle show-onhover'
                                style={{
                                  width: '100px',
                                  height: '100px',
                                  backgroundColor: 'rgba(0,0,0,0.75)',
                                  borderRadius: '100%',
                                }}
                              >
                                <button
                                  type='button'
                                  className='delete-butn w-100 theme-text-color'
                                  onClick={() => removeProfilePic()}
                                >
                                  <i className='fa-regular fa-trash-can '></i>
                                </button>
                              </div>
                            </div>
                          )}

                          {/* {Object.keys(profilePicPreview).length > 0 && (
                            <img
                              src={profilePicPreview}
                              alt='profile preview'
                              className='profile-img-preview avaShadHov'
                            />
                          )} */}
                          {/* {uploadError!== "" && (
                        <div className="error ps-2">
                          <span className="error-span">
                            <i className="fa-solid fa-circle-exclamation pe-1"></i>
                            {uploadError}
                          </span>
                        </div>
                      )} */}
                        </div>
                      </FormControl>
                      <div className='py-2 w-100'>
                        <button
                          type='submit'
                          className='butn w-100 text-level-3'
                          disabled={isLoading}
                        >
                          Create Account
                        </button>
                        <div className='d-flex justify-content-center align-items-center pt-2'>
                          <button
                            type='button'
                            className='upload-profile-btn resize-font-sm text-level-4'
                            onClick={() => {
                              setSignInType(sign_in_types[0]);
                              setErrors({});
                            }}
                          >
                            Sign In
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </Box>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default ConnectToWallets;
