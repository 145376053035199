import React, { useRef, useEffect, useState } from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
} from '@mui/material';
import { StorageService, AuthService, UserService } from '../../../services';
import ErrorFormat from '../../../utils/ErrorFormat;';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import FormValidator from '../../../utils/validation';
import { PreLoaderComponent } from '../../../components';
import useScreen from '../../../hooks/useScreen';
import TextFieldWrapper from '../../../components/TextFieldWrapper';

const PersonalDetailsComponents = () => {
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const { width } = useScreen();
  const [emailNotification, setEmailNotification] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [profilePreview, setProfilePreview] = useState(
    StorageService.getCurrentUser()?.imgUrl != ''
      ? `${process.env.REACT_APP_ASSET_URL}/${
          StorageService.getCurrentUser()?.imgUrl
        }`
      : null
  );
  const [url, setUrl] = useState('');
  const [alias, setAlias] = useState('');
  const [email, setEmail] = useState('');
  const user = useState(StorageService.getCurrentUser());
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [imgError, setImgError] = useState('');
  const [fileName, setFileName] = useState('');

  const getUser = () => {
    setIsLoading(true);
    setAlias(user[0]?.alias);
    setUrl(user[0]?.website !== ' ' ? user[0]?.website : '');
    setEmailNotification(
      user[0]?.is_email_notification_enabled
        ? user[0]?.is_email_notification_enabled
        : false
    );
    setEmail(user[0]?.email !== ' ' ? user[0]?.email : '');
    setIsLoading(false);
  };

  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      navigate('/', { replace: true });
    }
    getUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    FormValidator.handleOnSubmit('name', setErrors);
    FormValidator.handleOnSubmit('email2', setErrors);
    FormValidator.handleOnSubmit('url', setErrors);

    let noOfErrors = 0;
    let firstKey;

    if (imgError !== '') {
      let btnElement = document.getElementById('upload-profile-btn');
      return btnElement.click();
    }

    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      setIsLoading(true);
      let formData = new FormData();
      formData.append('alias', alias.trim());
      formData.append('avatar', profileImage);
      formData.append('website', url !== '' ? url : ' ');
      formData.append('email', email !== '' ? email : ' ');
      formData.append('is_email_notification_enabled', emailNotification);

      UserService.updateProfile(formData)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            Swal.fire({
              icon: 'success',
              title: res.message,
            });
            UserService.getProfile()
              .then((res) => {
                if (res.status) {
                  StorageService.updateUser(res.data);
                }
              })
              .catch((error) => {
                setIsLoading(false);
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.message === 'User account deactivated') {
              Swal.fire({
                icon: 'warning',
                title: 'Inactive account!',
                html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
              }).then(() => {
                StorageService.clearSession();
                navigate('/connect-to-wallet');
                return window.location.reload();
              });
            }
          } else if (error.response) {
            if (error.response.data && error.response.data.errors) {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',

                html: ErrorFormat.validationErrors(error.response.data.errors),
              });
            } else if (error.response.data.message) {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: `<p>${error.response.data.message}</p>`,
              });
            }
          }
        });
    }
  };

  const handleFileInput = (e) => {
    if (e.target.files[0]) {
      // get file size in MBs
      const fileSize = e.target.files[0].size / (1024 * 1024);
      if (fileSize <= 8) {
        if (
          e.target.files[0].type === 'image/png' ||
          e.target.files[0].type === 'image/jpg' ||
          e.target.files[0].type === 'image/jpeg'
        ) {
          var image = new Image();
          image.onload = function () {
            if (image.width < 1000 || image.height < 1000) {
              e.target.files = undefined;
              e.target.value = null;
              setFileName('');
              setImgError('Picture must be greater than 1000 x 1000 px');
              setProfileImage(null);
              return setProfilePreview(
                StorageService.getCurrentUser().imgUrl
                  ? `${process.env.REACT_APP_ASSET_URL}/${
                      StorageService.getCurrentUser().imgUrl
                    }`
                  : null
              );
            } else {
              setImgError('');
              setFileName(e.target.files[0].name);
              setProfileImage(e.target.files[0]);
              return setProfilePreview(URL.createObjectURL(e.target.files[0]));
            }
          };
          image.src = URL.createObjectURL(e.target.files[0]);
          //   }
          //   else {
          // setProfileImage(null);
          // setProfilePreview(StorageService.getCurrentUser().imgUrl
          //   ? `${process.env.REACT_APP_ASSET_URL}/${StorageService.getCurrentUser().imgUrl
          //   }`
          //   : null);
          // return setImgError("Uploaded image must be 1000x1000 pixel")

          //   }
          // };
          // image.src = URL.createObjectURL(e.target.files[0]);
        } else {
          setProfileImage(null);
          setProfilePreview(
            StorageService.getCurrentUser().imgUrl
              ? `${process.env.REACT_APP_ASSET_URL}/${
                  StorageService.getCurrentUser().imgUrl
                }`
              : null
          );
          setFileName('');
          setImgError('Only png, jpg, jpeg extensions are allowed!');
          e.target.files = undefined;
          return (e.target.value = null);
        }
      } else if (
        e.target.files[0].type !== 'image/png' &&
        e.target.files[0].type !== 'image/jpg' &&
        e.target.files[0].type !== 'image/jpeg'
      ) {
        setProfileImage(null);
        setProfilePreview(
          StorageService.getCurrentUser().imgUrl
            ? `${process.env.REACT_APP_ASSET_URL}/${
                StorageService.getCurrentUser().imgUrl
              }`
            : null
        );
        setFileName('');
        setImgError('Only png, jpg, jpeg extensions are allowed!');
        e.target.files = undefined;
        return (e.target.value = null);
      } else {
        setFileName('');
        setProfileImage(null);
        setProfilePreview(
          StorageService.getCurrentUser().imgUrl
            ? `${process.env.REACT_APP_ASSET_URL}/${
                StorageService.getCurrentUser().imgUrl
              }`
            : null
        );
        setImgError('Uploaded image not be greater than 8MB');
        e.target.files = undefined;
        return (e.target.value = null);
      }
    }
  };

  const removeProfilePic = async (e) => {
    setIsLoading(true);
    setFileName('');
    UserService.removeProfileImage()
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          Swal.fire({
            icon: 'success',
            title: res.message,
          });
          setProfilePreview(null);
          // get profile info on removing profile image and update it on local storage
          UserService.getProfile()
            .then((res) => {
              if (res.status) {
                StorageService.updateUser(res.data);
              }
            })
            .catch((error) => {
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Error occurred',
            html: `<p>${error.response.data.message}</p>`,
          });
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <PreLoaderComponent />
      ) : (
        <div>
          <form
            onSubmit={(e) => handleSubmit(e)}
            encType='multipart/form-data'
            noValidate
            className='d-flex flex-column gap-3'
          >
            {/* <div className="theme-text-color py-2">
              <FormControl fullWidth className="wallet-select-field">
                <InputLabel
                  id="demo-simple-select-label"
                  className="input-label"
                >
                  Email Notification
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={emailNotification}
                  label="Email Notification"
                  onChange={(e) => setEmailNotification(e.target.value)}
                  className="selected-text"
                >
                  <MenuItem
                    value={true}
                    className="theme-text-color bg-dark  border-bottom"
                  >
                    Enable
                  </MenuItem>
                  <MenuItem
                    value={false}
                    className="theme-text-color bg-dark border-bottom"
                  >
                    Disable
                  </MenuItem>
                </Select>
              </FormControl>
            </div> */}
            <div className='theme-text-color py-1'>
              <TextFieldWrapper
                fullWidth
                //className='wallet-select-field selected-text description-text'
                onChange={(e) => {
                  setAlias(e.target.value.replace(/^[0-9]/, ''));
                  FormValidator.handleOnBlur(e, setErrors);
                }}
                value={alias}
                id='name'
                name='name'
                label='Alias'
                //placeholder='Type your name'
                required
                inputProps={{ minLength: 2, maxLength: 20 }}
                onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
              />
              {errors && errors.name && (
                <div className='error ps-2'>
                  <span className='error-span mt-0 pt-1'>
                    <i className='fa-solid fa-circle-exclamation pe-1'></i>
                    {errors.name}
                  </span>
                </div>
              )}
            </div>
            <div className='theme-text-color py-1'>
              <TextFieldWrapper
                fullWidth
                //className='wallet-select-field selected-text description-text'
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                  FormValidator.handleOnBlur(e, setErrors);
                }}
                value={email}
                id='email2'
                name='email2'
                label='Email'
                //placeholder='Email'
                onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                inputProps={{
                  maxLength: 128,
                }}
              />
              {errors && errors.email2 && (
                <div className='error ps-2'>
                  <span className='error-span mt-0 pt-1'>
                    <i className='fa-solid fa-circle-exclamation pe-1'></i>
                    {errors.email2}
                  </span>
                </div>
              )}
            </div>
            <div className='theme-text-color py-1'>
              <TextFieldWrapper
                fullWidth
                //className='wallet-select-field selected-text description-text'
                onChange={(e) => {
                  setUrl(e.target.value.trim());
                  FormValidator.handleOnBlur(e, setErrors);
                }}
                value={url}
                label='URL'
                id='url'
                name='url'
                onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                inputProps={{
                  maxLength: 500,
                }}
              />
              {errors && errors.url && (
                <div className='error ps-2'>
                  <span className='error-span mt-0 pt-1'>
                    <i className='fa-solid fa-circle-exclamation pe-1'></i>
                    {errors.url}
                  </span>
                </div>
              )}
            </div>

            <div className='d-flex flex-column gap-2'>
              <input
                type='file'
                ref={fileInput}
                onChange={(e) => handleFileInput(e)}
                hidden
                name='profilePic'
                id='profilePic'
                accept='image/png, image/jpg, image/jpeg'
                // maxSize="52"
              />
              {/* <div className='theme-text-color py-2 eer-flex'> */}
              {/* <div className='d-flex flex-column justify-content-center align-items-start '>
              <button
                type='button'
                onClick={() => fileInput.current.click()}
                className='upload-profile-btn w-100 theme-text-color'
                id='upload-profile-btn'
              >
                <i className='fa-solid fa-circle-plus pe-2'></i>
                Upload Profile Picture
              </button>
              <div style={{ alignSelf: 'start' }}>
                {imgError !== '' ? (
                  <div className='error resposError'>
                    <span className='error-span'>
                      <i className='fa-solid fa-circle-exclamation pe-1'></i>
                      {imgError}
                    </span>
                  </div>
                ) : (
                  <div className='displayInstructions '>
                    {' '}
                    <p className=' theme-text-color'>
                      Image format: JPG, JPEG, PNG. Size greater than 1000x1000
                      px
                    </p>
                  </div>
                )}
              </div>
            </div> */}
              <div className='d-flex align-items-center'>
                <button
                  type='button'
                  className='upload-profile-btn theme-text-color'
                  onClick={() => fileInput.current.click()}
                  disabled={isLoading}
                >
                  <i className='fa-solid fa-circle-plus pe-2'></i>
                </button>

                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className='text-level-4 text-yellow'>
                    Upload Profile Picture
                  </div>
                  <div>
                    {imgError !== '' ? (
                      <div className='error'>
                        <span className='error-span'>
                          <i className='fa-solid fa-circle-exclamation'></i>
                          {imgError}
                        </span>
                      </div>
                    ) : (
                      <div className='text-gray text-level-6 word-break-all line-height-14'>
                        {fileName
                          ? fileName
                          : 'Picture type: JPG, JPEG, PNG. Size more than 1000 x 1000 px'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* {imgError !== '' && <p className='error m-auto'>{imgError}</p>} */}
              {/* </div> */}

              {profilePreview !== null && (
                <div className='w-100 position-relative showRemoveIconOnhover'>
                  <img
                    src={profilePreview}
                    className='mx-auto profile-img-preview profile-dark-border'
                    alt='profile-pic'
                  />
                  <div
                    className='position-absolute top-50 start-50 translate-middle show-onhover'
                    style={{
                      width: '100px',
                      height: '100px',
                      backgroundColor: 'rgba(0,0,0,0.75)',
                      borderRadius: '100%',
                    }}
                  >
                    <button
                      type='button'
                      className='delete-butn w-100 theme-text-color'
                      onClick={() => removeProfilePic()}
                    >
                      <i className='fa-regular fa-trash-can '></i>
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className='theme-text-color py-1'>
              <button type='submit' className='butn w-100' disabled={isLoading}>
                Update Profile
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default PersonalDetailsComponents;
