import 'react-image-crop/dist/ReactCrop.css';
import React, { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';

function ImageCropper(props) {
  const { imageToCrop, onImageCropped } = props;

  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
      unit: '%',
      width: 100,
      height: 100,
      // x: 0.25,
      // y:0.25,
      aspect: 4 / 4,
    }
  );

  const imageRef = useRef(null);

  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        document.getElementById('image-cropper'),
        crop,
        'croppedImage.jpeg' // destination filename
      );
      // calling the props function to expose
      // croppedImage to the parent component
      onImageCropped(croppedImage);
    }
  }

  async function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image

    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    // canvas.width = cropConfig.width;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    const newImg = ctx.getImageData(0, 0, cropConfig.width, cropConfig.height);

    // ctx.putImageData(newImg, 2, 2);
    ctx.putImageData(newImg, 1, 1);

    const url = await getCroppedImageUrl(canvas, fileName);

    return { url: url, data: canvas.toDataURL('', 1) };
  }

  async function getCroppedImageUrl(canvas, fileName) {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        blob.name = fileName;
        // creating a Object URL representing the Blob object given
        const croppedImageUrl = window.URL.createObjectURL(blob);
        resolve(croppedImageUrl);
      }, 'image/jpeg');
    });
  }

  const handleCropConfig = (cConfig) => {
    if (cConfig.width > 500 && cConfig.height > 500) {
      cConfig.height = cConfig.width;
      return cropImage(cConfig);
    }
    // if (cConfig.width > 500 ) {
    //   cConfig.height = cropConfig.height;
    //    cropImage(cConfig);
    // }

    // if (cConfig.height > 500 ) {
    //   cConfig.width = cropConfig.width;
    //    cropImage(cConfig);
    // }
  };

  ImageCropper.defaultProps = {
    onImageCropped: () => {
      cropImage();
    },
  };

  return (
    <>
      <ReactCrop
        src={imageToCrop}
        crop={cropConfig}
        ruleOfThirds
        onComplete={(cropConfig) => {
          handleCropConfig(cropConfig);
        }}
        onChange={(cropConfig) => {
          handleCropConfig(cropConfig);
          // cropConfig.height = cropConfig.width;
          // setCropConfig(cropConfig);
        }}
        crossorigin='anonymous' // to avoid CORS-related problems
      >
        <img id='image-cropper' ref={imageRef} src={imageToCrop} />
      </ReactCrop>
    </>
  );
}

export default ImageCropper;
