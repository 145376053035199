import API from "../config/api.config";
import axios from "axios";
import StorageService from "./storage.service";
const token = StorageService.getToken() ? StorageService.getToken() : "";

// const updateProfile
const updateProfile = (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios
    .patch(`${process.env.REACT_APP_API_URL}user/update-profile`, data, config)
    .then((res) => {
      return res.data;
    });
};

const getUsers = (current_page = 1, limit = 10) => {
  return API.get(`/user/get-users/${current_page}/${limit}`).then((res) => {
    return res.data;
  });
};

const getProfile = () => {
  return API.get("/user/get-profile-info").then((res) => {
    return res.data;
  });
};

const changeRole = (user_id, data) => {
  return API.patch(`/user/change-role/${user_id}`, data).then((res) => {
    return res.data;
  });
};

const changeStatus = (user_id) => {
  return API.patch(`/user/change-status/${user_id}`).then((res) => {
    return res.data;
  });
};

const getAlias = (data) => {
  return API.post(`/user/get-alias/`, data).then((res) => {
    return res.data;
  });
};

const postTweet = (data) => {
  return API.post(`/user/post-a-tweet/`, data).then((res) => {
    return res.data;
  });
};

const checkUserActiveStatus = () => {
  return API.get(`/user/check-status`).then((res) => {
    return res.data;
  });
};

const removeProfileImage = () => {
  return API.patch(`/user/remove-profile-pic`).then((res) => {
    return res.data;
  });
};



const UserService = {
  removeProfileImage,
  checkUserActiveStatus,
  postTweet,
  updateProfile,
  getUsers,
  changeRole,
  changeStatus,
  getProfile,
  getAlias,
};

export default UserService;
