import React, { useEffect, useState } from 'react';
import {
  WalletService,
  StorageService,
  AuthService,
  UserService,
  ThirdPartyService,
} from '../../services';
import Swal from 'sweetalert2';
import { Box, TextField, Fab, IconButton, Modal } from '@mui/material';
import FormValidator from '../../utils/validation';
import { PreLoaderComponent, PreLoaderComponent2 } from '../../components';
import { useNavigate } from 'react-router-dom';
import useScreen from '../../hooks/useScreen';
import TextFieldWrapperDark from '../../components/TextFieldWrapperDark';

const style = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: '4rem',
  width: '90%',
  bgcolor: '#ffff00',
  boxShadow: 24,
  p: 3,
  borderRadius: '15px',
  '& .MuiTextField-root': { my: 1, width: '25ch' },
};

const WalletsDashboard = () => {
  const navigate = useNavigate();
  const [wallets, setWallets] = useState([]);
  const { width } = useScreen();
  const [isLoading, setIsLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [newWallet, setNewWallet] = useState('');
  const [walletAlias, setWalletAlias] = useState('');
  const [newWalletErrors, setNewWalletErrors] = useState({});
  const [openSubmit, setOpenSubmit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [limit, setLimit] = useState(
    width >= 1600 ? 20 : width < 1600 && width > 1000 ? 10 : width <= 1000 && 5
  );

  useEffect(() => {
    width >= 1600
      ? setLimit(20)
      : width < 1600 && width > 1000
      ? setLimit(10)
      : width <= 1000 && setLimit(5);
  }, [width]);
  const getAdressBookWallets = () => {
    setIsLoading(true);
    WalletService.getWalletsFromAdressBook(currentPage, limit)
      .then((res) => {
        setIsLoading(false);
        /* if (res.status) { */
        setWallets(res.data);
        setFirstPage(res.pagenation.first_page);
        setLastPage(res.pagenation.last_page);
        /* } */
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        }
      });
  };

  useEffect(() => {
    getAdressBookWallets();
  }, []);

  const handleRemoveWallet = (wallet_address) => {
    Swal.fire({
      title: 'Do you want to delete?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `Not Delete`,
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setIsLoading(true);
          WalletService.removeWalletFromAddressBook(wallet_address)
            .then((res) => {
              setIsLoading(false);
              if (res.status) {
                Swal.fire('Success', res.message, 'success');
                return getAdressBookWallets();
              }
            })
            .catch((error) => {
              setIsLoading(false);
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                if (
                  error.response.data.message === 'User account deactivated'
                ) {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Inactive account!',
                    html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
                  }).then(() => {
                    StorageService.clearSession();
                    navigate('/connect-to-wallet');
                    return window.location.reload();
                  });
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error occurred',
                    html: `<p>${error.response.data.message}</p>`,
                  });
                }
              }
            });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const resetNewWalletForm = () => {
    setNewWalletErrors({});
    setNewWallet('');
    setWalletAlias('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    FormValidator.handleOnSubmit('new_wallet_address', setNewWalletErrors);
    FormValidator.handleOnSubmit('wallet_alias', setNewWalletErrors);
    return setOpenSubmit(true);
  };

  const addNewWalletInAdressBook = (e) => {
    // e.preventDefault();
    setOpenSubmit(false);

    let noOfErrors = 0;
    let firstKey;

    Object.values(newWalletErrors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(newWalletErrors).length - 1; i++) {
      if (
        Object.values(newWalletErrors)[i] !== '' &&
        Object.values(newWalletErrors)[i] !== null
      ) {
        firstKey = Object.keys(newWalletErrors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      setIsLoading(false);
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      setOpenForm(false);
      setIsLoading(true);

      const data = {
        wallet_address: newWallet,
        alias: walletAlias.trim(),
      };
      try {
        var result = ThirdPartyService.refreshNFT(newWallet)
          .then((res) => {
            WalletService.storeWalletsFromAdressBook(data)
              .then((res) => {
                setIsLoading(false);
                resetNewWalletForm();
                if (res.status) {
                  Swal.fire('success', res.message, 'success');
                  return getAdressBookWallets();
                }
              })
              .catch((error) => {
                setIsLoading(false);
                resetNewWalletForm();
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                ) {
                  if (
                    error.response.data.message === 'User account deactivated'
                  ) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Inactive account!',
                      html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
                    }).then(() => {
                      StorageService.clearSession();
                      navigate('/connect-to-wallet');
                      return window.location.reload();
                    });
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error occurred',
                      html: `<p>${error.response.data.message}</p>`,
                    });
                  }
                }
              });
          })
          .catch((error) => {
            setIsLoading(false);
            resetNewWalletForm();
            if (
              error.response &&
              error.response.data &&
              error.response.data.owner &&
              error.response.data.owner.length > 0
            ) {
              if (
                error.response.data.owner[0] ===
                `Address like string expected but received "${newWallet}"`
              ) {
                Swal.fire('Error', 'Invalid wallet address', 'error');
              }
            }
          });
      } catch (err) {}
    }
  };

  useEffect(() => {
    if (openSubmit) {
      addNewWalletInAdressBook();
    }
  }, [openSubmit]);

  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      navigate('/connect-to-wallet');
    }
  }, []);

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      setIsLoading(true);
      UserService.checkUserActiveStatus()
        .then((res) => {
          setIsLoading(false);
          if (res.status === false) {
            StorageService.clearSession();
            navigate('/connect-to-wallet', { reload: true });
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: `<p>Oh no, account deactivated. Please contact to admin at info@nftneighbors.com </p>`,
            })
              .then(() => {
                localStorage.clear();
                window.location.reload(true);
              })
              .catch(() => {
                localStorage.clear();
                window.location.reload(true);
              });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === 'User account deactivated'
          ) {
            setIsLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        });
    }
  }, []);

  const hanldePagenation = (pagenate_type) => {
    if (pagenate_type === 'first') {
      setCurrentPage(firstPage);
    } else if (pagenate_type === 'last') {
      setCurrentPage(lastPage);
    } else if (pagenate_type === 'next') {
      // handle next
      setCurrentPage(currentPage + 1);
    } else if (pagenate_type === 'prev') {
      // handle previous
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    getAdressBookWallets();
  }, [currentPage, limit]);

  return (
    <>
      {/* {isLoading && <PreLoaderComponent2 />} */}
      <section className='p-4'>
        <div className='d-flex align-items-center justify-content-sm-center flex-md-column justify-content-between flex-wrap pb-4 pt-1 '>
          <div className='theme-text-color main-heading fw_500 text-center heading-level-3'>
            Wallet Address Book
          </div>
          <div>
            <Fab
              className='bg-yellow btn-fs fs-text-stroke-2px '
              onClick={(e) => setOpenForm(true)}
            >
              <i className='fa-solid fa-plus m-auto'></i>
            </Fab>
          </div>
        </div>
        <div className='row theme-text-color addresses'>
          {isLoading ? (
            <PreLoaderComponent />
          ) : (
            Array.isArray(wallets) &&
            wallets.map((wallet, index) => (
              <div
                className='col-12 col-lg-6 col-xl-6 
                     address_item'
                key={index}
              >
                <div className='address-cards'>
                  <h3 className=' format-heading text-capitalize py-0 text-level-3'>
                    {wallet?.alias}
                  </h3>
                  <p
                    className=' py-0 text-level-6 text-gray'
                    style={{ textOverflow: 'ellipsis' }}
                  >
                    {wallet?.wallet_address}
                  </p>
                  <div className='address-btn'>
                    <IconButton
                      className='icon-mui-btn'
                      disabled={!wallet?.wallet_address}
                      onClick={(e) =>
                        handleRemoveWallet(wallet?.wallet_address)
                      }
                    >
                      <Fab className='bg-light-dark btn-fs fs-text-stroke-2px'>
                        <i className='fa-solid fa-minus m-auto theme-text-color'></i>
                      </Fab>
                    </IconButton>
                  </div>
                </div>
              </div>
            ))
          )}
          {!isLoading && wallets && wallets.length > 0 && (
            <div className='d-flex justify-content-around align-items-center py-3'>
              <div className='d-flex justify-content-center align-items-center'>
                <div>
                  <button
                    className='mui-icon-btn'
                    variant='text'
                    onClick={() => hanldePagenation('first')}
                    disabled={currentPage == firstPage}
                  >
                    <i className='fa-solid fa-angles-left mui-pagenate-icon'></i>
                  </button>
                  <button
                    className='mui-icon-btn'
                    variant='text'
                    onClick={() => hanldePagenation('prev')}
                    disabled={currentPage == firstPage}
                  >
                    <i className='fa-solid fa-chevron-left mui-pagenate-icon'></i>
                  </button>
                </div>
                <div>
                  <button
                    className='mui-icon-btn'
                    variant='text'
                    onClick={() => hanldePagenation('next')}
                    disabled={currentPage == lastPage}
                  >
                    <i className='fa-solid fa-chevron-right mui-pagenate-icon'></i>
                  </button>
                  <button
                    className='mui-icon-btn'
                    variant='text'
                    onClick={() => hanldePagenation('last')}
                    disabled={currentPage == lastPage}
                  >
                    <i className='fa-solid fa-angles-right mui-pagenate-icon'></i>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal
          open={openForm}
          onClose={(e) => {
            setOpenForm(false);
            resetNewWalletForm();
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-auto m-auto'
        >
          <Box sx={style} className='' autoComplete='off'>
            <div className='d-flex justify-content-between modal-header px-1'>
              <h3 className='modal-title fw_bolder'>Add Friend's Wallet</h3>
              <button
                type='button'
                className='modal-butn fw_bolder'
                onClick={(e) => {
                  setOpenForm(false);
                  resetNewWalletForm();
                }}
              >
                x
              </button>
            </div>
            <div className='px-1 py-3 '>
              <p className='modal-p'>
                Add new friend's wallet to create merger.
              </p>
            </div>
            <div className='px-1'>
              <form
                onSubmit={(e) => handleSubmit(e)}
                encType='multipart/plain-text'
                noValidate
              >
                <div className='py-1'>
                  <TextFieldWrapperDark
                    id='new_wallet_address'
                    name='new_wallet_address'
                    label='Wallet Address'
                    value={newWallet}
                    onChange={(e) => {
                      setNewWallet(e.target.value.trim());
                      FormValidator.handleOnBlur(e, setNewWalletErrors);
                    }}
                    onBlur={(e) =>
                      FormValidator.handleOnBlur(e, setNewWalletErrors)
                    }
                    className='text-field clr-black p-0 m-0'
                    required
                    sx={{
                      '&.Mui-focused .MuiInputLabel-outlined': {
                        color: '#ffff00',
                        borderColor: '#ffff00',
                        outlineColor: '#ffff00',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ffff00',
                        outlineColor: '#ffff00',
                      },
                      '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
                        borderColor: '#ffff00',
                        outlineColor: '#ffff00',
                      },
                      '&.Mui-focused .MuiInputLabel-outlined': {
                        borderColor: '#ffff00',
                        outlineColor: '#ffff00',
                      },
                    }}
                    inputProps={{ maxLength: 100 }}
                    // autoFocus={true}
                    size={width <= 375 ? 'small' : 'medium'}
                  />
                  {newWalletErrors && newWalletErrors.new_wallet_address && (
                    <div className='error ps-2 pt-2'>
                      <span className='error-span'>
                        <i className='fa-solid fa-circle-exclamation pe-1 '></i>
                        {newWalletErrors.new_wallet_address}
                      </span>
                    </div>
                  )}
                </div>
                <div className='py-1'>
                  <TextFieldWrapperDark
                    id='wallet_alias'
                    name='wallet_alias'
                    label='Alias'
                    value={walletAlias}
                    onChange={(e) => {
                      setWalletAlias(e.target.value.replace(/^[0-9]/, ''));
                      FormValidator.handleOnBlur(e, setNewWalletErrors);
                    }}
                    className='text-field clr-black px-0 mx-0 '
                    required
                    inputProps={{ maxLength: 20 }}
                    onBlur={(e) =>
                      FormValidator.handleOnBlur(e, setNewWalletErrors)
                    }
                    size={width <= 375 ? 'small' : 'medium'}
                  />
                  {newWalletErrors && newWalletErrors.wallet_alias && (
                    <div className='error ps-2'>
                      <span className='error-span'>
                        <i className='fa-solid fa-circle-exclamation pe-1'></i>
                        {newWalletErrors.wallet_alias}
                      </span>
                    </div>
                  )}
                </div>

                <button type='submit' className='butn w-100 py-3 mb-2'>
                  Add Wallet
                </button>
              </form>
            </div>
          </Box>
        </Modal>
      </section>
    </>
  );
};

export default WalletsDashboard;
