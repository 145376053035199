import React, { useEffect, useState } from 'react';
import { downloadIcon } from '../../../utils/images';
import { MergedService } from '../../../services';
import { saveAs } from 'file-saver';
import ErrorFormat from '../../../utils/ErrorFormat;';
import Swal from 'sweetalert2';
import dateFormat from 'dateformat';
import { Button, Avatar, AvatarGroup } from '@mui/material';
import { PreLoaderComponent } from '../../../components';
import { MergeService, StorageService } from '../../../services';
import { useNavigate } from 'react-router-dom';
import TooltipWrapper from '../../../components/TooltipWrapper';

const PersonalMerges = () => {
  const navigate = useNavigate();
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [limit, setLimit] = useState(8);

  const getAllMergedNfts = () => {
    setIsLoading(true);
    MergedService.getPersonalMergedNfts(currentPage, limit)
      .then((res) => {
        setIsLoading(false);
        if (res.message !== 'No NFT found!') {
          if (res.status && res.data.length > 0) {
            setFirstPage(res.pagenation.first_page);
            setLastPage(res.pagenation.last_page);
            setNfts(res.data);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: `<p>${error.response.data.message}</p>`,
            });
          }
        }
      });
  };

  const handleDownload = async (e, merged_id, title) => {
    setIsLoading(true);

    MergeService.getMergePreview(merged_id)
      .then((res) => {
        if (res.status) {
          saveAs(res.data.merge_preview, title + '.png');
        } else {
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        }
      });
  };

  useEffect(() => {
    getAllMergedNfts();
  }, [currentPage]);

  const likeAndDislike = (merge_id) => {
    setIsLoading(true);
    MergedService.mergedLikeAndDisklike(merge_id)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          getAllMergedNfts();
          // Swal.fire("success", res.message, "success");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.data.errors) {
          Swal.fire({
            icon: 'error',
            title: 'Error occurred',
            html: ErrorFormat.validationErrors(error.response.data.errors),
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: `<p>${error.response.data.message}</p>`,
            });
          }
        }
      });
  };

  const hanldePagenation = (pagenate_type) => {
    if (pagenate_type === 'first') {
      setCurrentPage(firstPage);
    } else if (pagenate_type === 'last') {
      setCurrentPage(lastPage);
    } else if (pagenate_type === 'next') {
      // handle next
      setCurrentPage(currentPage + 1);
    } else if (pagenate_type === 'prev') {
      // handle previous
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <section className='py-4 '>
      {/* nfts cards */}
      <div className='py-4 cards-group row g-4 h-100 justify-content-center'>
        {isLoading ? (
          <PreLoaderComponent />
        ) : nfts.length > 0 ? (
          nfts.map((item, index) => (
            <div className='px-2 custom-column ' key={index}>
              <div className='card  pb-0'>
                <div className='img-container'>
                  <img
                    src={
                      item.merge_type === 'nft'
                        ? `${process.env.REACT_APP_ASSET_URL}/${item.merged_image}`
                        : item.merge_thumnail_url
                    }
                    className='card-img-top'
                    alt='nfts card'
                  />
                </div>
                <button
                  type='button'
                  className={`butn love-butn ${item.is_liked && 'liked'}`}
                  onClick={() => likeAndDislike(item._id)}
                  disabled={isLoading}
                >
                  <i className='fa-solid fa-heart '></i>
                  <span>{item.likes}</span>
                </button>
                <button
                  type='button'
                  className='butn download-butn'
                  onClick={(e) => handleDownload(e, item._id, item.title)}
                >
                  <img src={downloadIcon} alt='download-nfts' />
                </button>
                <div className='card-body'>
                  <h5 className='card-title one-line-text'>
                    {item.title[0].toUpperCase() + item.title.slice(1)}
                  </h5>
                  <div className='d-flex gap-1 justify-content-start align-items-center pe-2'>
                    <AvatarGroup max={6}>
                      {item.friend_tags.map((frnd_info, index) => {
                        return (
                          index < 6 && (
                            <TooltipWrapper title={frnd_info?.alias}>
                              {frnd_info.imgUrl !== '' ? (
                                <Avatar
                                  key={frnd_info._id}
                                  className='border-yellow text-yellow bg-dark'
                                  alt={frnd_info.alias}
                                  src={`${process.env.REACT_APP_ASSET_URL}/${frnd_info.imgUrl}`}
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    position: 'static',
                                  }}
                                />
                              ) : (
                                <Avatar
                                  key={frnd_info._id}
                                  className='avatar-text border-yellow text-yellow bg-dark'
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    position: 'static',
                                  }}
                                >
                                  {frnd_info.alias[0] + frnd_info.alias[1]}
                                </Avatar>
                              )}
                            </TooltipWrapper>
                          )
                        );
                      })}
                    </AvatarGroup>
                  </div>
                  <p className='card-text py-2 gap-2 d-flex justify-content-between align-items-center'>
                    <span className=' hash-tag tag-shag px-2'>
                      {item.tags.length > 0 ? '#' + item.tags[0] : ' '}
                    </span>
                    <span className=' hash-tag px-2 hash-date'>
                      {dateFormat(item.updated_at, 'dd') +
                        '/' +
                        dateFormat(item.updated_at, 'mm') +
                        '/' +
                        dateFormat(nfts[0].updated_at, 'yy')}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h4 className='mx-auto theme-text-color text-center text-level-5'>
            No Merged NFT Found!
          </h4>
        )}
        {!isLoading && nfts.length > 0 && (
          <div className='d-flex justify-content-around align-items-center py-3'>
            <div className='d-flex justify-content-center align-items-center'>
              <div>
                <button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('first')}
                  disabled={currentPage == firstPage}
                >
                  <i className='fa-solid fa-angles-left mui-pagenate-icon'></i>
                </button>
                <button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('prev')}
                  disabled={currentPage == firstPage}
                >
                  <i className='fa-solid fa-chevron-left mui-pagenate-icon'></i>
                </button>
              </div>
              <div>
                <button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('next')}
                  disabled={currentPage == lastPage}
                >
                  <i className='fa-solid fa-chevron-right mui-pagenate-icon'></i>
                </button>
                <button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('last')}
                  disabled={currentPage == lastPage}
                >
                  <i className='fa-solid fa-angles-right mui-pagenate-icon'></i>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default PersonalMerges;
