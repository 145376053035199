import API from "../config/api.config";

const storeNFTList = (data) => {
  return API.post("/nfts/store-nfts", data).then((res) => {
    return res.data;
  });
};

const likedMergedNfts = (data, current_page = 1, limit = 10) => {
  return API.post(`/merge/liked-merged-nfts/${current_page}/${limit}`, data).then(
    (res) => {
      return res.data;
    }
  );
};

const mergedNfts = (data, current_page = 1, limit = 10) => {
  return API.post(`/merge/merged-nfts/${current_page}/${limit}`, data).then((res) => {
    return res.data;
  });
};

const allMergedNfts = ( current_page = 1, limit = 10) => {
  return API.post(`/merge/merged-nfts/${current_page}/${limit}`).then((res) => {
    return res.data;
  });
};

const allLikedMergedNfts = ( current_page = 1, limit = 10) => {
  return API.post(`/merge/liked-merged-nfts/${current_page}/${limit}`).then(
    (res) => {
      return res.data;
    }
  );
};

const pendingMerges = (current_page = 1, limit = 10) => {
  return API.get(`/merge/get-pending-requests/${current_page}/${limit}`).then(
    (res) => {
      return res.data;
    }
  );
};

const searchNfts = async (data, current_page = 1, limit = 10) => {
  return API.post(`/merge/search-nfts/${current_page}/${limit}`, data).then(
    (res) => {
      return res.data;
    }
  );
};

const sortNfts = async (data, current_page = 1, limit = 10) => {
  return API.post(
    `/merge/sorted-merged-nfts/${current_page}/${limit}`,
    data
  ).then((res) => {
    return res.data;
  });
};

const filterNfts = async (data, current_page = 1, limit = 10) => {
  return API.post(
    `/merge/filter-merged-nfts/${current_page}/${limit}`,
    data
  ).then((res) => {
    return res.data;
  });
};

const getAllNfts = async (current_page = 1, limit = 10) => {
  return API.get(`/nfts/get-all-nfts/${current_page}/${limit}`).then((res) => {
    return res.data;
  });
};

const getAllPersonalNfts = async (data, current_page = 1, limit = 10) => {
  return API.post(`/nfts/get-all-nfts/${current_page}/${limit}`, data).then(
    (res) => {
      return res.data;
    }
  );
};

const getAllNftsByWallets = async (current_page = 1, limit = 10, data) => {
  return API.post(`/nfts/get-all-nfts/${current_page}/${limit}`, data).then(
    (res) => {
      return res.data;
    }
  );
};

const NftService = {
  allLikedMergedNfts,
  allMergedNfts,
  getAllPersonalNfts,
  getAllNftsByWallets,
  storeNFTList,
  likedMergedNfts,
  mergedNfts,
  searchNfts,
  sortNfts,
  filterNfts,
  getAllNfts,
  pendingMerges,
};

export default NftService;
