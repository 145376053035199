import * as React from 'react';

import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import useScreen from '../hooks/useScreen';

const Input = styled(TextField)(({ theme }) => {
  const { width } = useScreen();
  return {
    '& label': {
      color: 'var(--second) !important',
      backgroundColor: 'var(--yellow) !important',
      padding: '0px 8px !important',
      fontSize: '1rem !important',
      top:
        width > 1600
          ? '2px !important'
          : width < 1600 && width > 500
          ? '1px !important'
          : '-1px !important',
    },
    '& .Mui-error fieldset': {
      borderColor: '#f50057 !important',
    },
    '& .MuiInputBase-root': {
      width: '100% !important',
      padding: '2px 8px !important',
      boxShadow: '0 0 0 0.1rem var(--second) !important',
      '&.MuiInputBase-root fieldset': {
        border: '1px solid var(--second) !important',
      },
      '&.Mui-focused fieldset': {
        border: '0.3px solid var(--second) !important',
        boxShadow: '0 0 0 0.2rem var(--second) !important',
      },
      '&:hover fieldset': {
        borderColor: 'red !imortant',
      },
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: '#5b5f69 !important',
      color: '#5b5f69 !important',
    },
    '& .MuiInputBase-input': {
      '::placeholder':{color:'var(--second) !important'},
      color: 'var(--second) !important',
      position: 'relative !important',
      backgroundColor: 'var(--yellow)',
      border: 'none !important',
      paddingLeft: '4px !important',
      fontSize: '100% !important',
      //fontZize: '1rem',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    '& textarea': {
      '::placeholder':{color:'var(--second) !important'},
      color: 'var(--second) !important',
      position: 'relative !important',
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2b2b2b',
      border: 'none !important',
      paddingLeft: '4px !important',
      fontSize: '100% !important',
      padding: '10px 0px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  };
});

const TextFieldWrapperDark = (props) => {
  const { width } = useScreen();
  return (
    <Input
      {...props}
      className='TextFieldWrapperDark w-100'
      size={
        width > 1600
          ? 'large'
          : width < 1600 && width > 500
          ? 'medium'
          : 'small'
      }
    />
  );
};

export default TextFieldWrapperDark;
