import API from '../config/api.config';
import StorageService from './storage.service';
import axios from 'axios';

const token = StorageService.getToken() ? StorageService.getToken() : '';

const login = async (data) => {
  if (data.avatar) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };
    return axios
      .post(`${process.env.REACT_APP_API_URL}user/signin`, data, config)
      .then((res) => {
        return res.data;
      });
  } else {
    return await API.post('/user/signin', data).then((res) => {
      return res.data;
    });
  }
};

const signup = async (data) => {
  return await API.post('/user/signup', data);
};

const verifyEmail = async (data) => {
  return await API.patch('user/verify-email', data);
};

const signin = async (data) => {
  return await API.post('user/signin', data);
};

const isLoggedIn = () => {
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  if (user && token) {
    return true;
  } else {
    return false;
  }
};

const AuthService = {
  login,
  isLoggedIn,
  signup,
  verifyEmail,
  signin,
};

export default AuthService;
