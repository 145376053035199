import { AppRoutes } from './routes/app.route';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import './style/index.css';
import Swal from 'sweetalert2';

function App() {
  /*  const location = useLocation();
  useEffect(()=>{
  },[location]) */

  document.addEventListener('keydown', (evt) => {
    if (evt.key === 'Escape') {
      alert('Esc press');
    }
  });

  const appRoute = AppRoutes.map(({ Component, children }, key) => (
    <Route key={key} exact element={<Component />}>
      {children.map(({ path, Component }, key) => (
        <Route key={key} exact path={path} element={<Component />} />
      ))}
    </Route>
  ));
  return (
    <BrowserRouter>
      <Routes>{appRoute}</Routes>
    </BrowserRouter>
  );
}

export default App;
