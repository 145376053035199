import React, { useEffect, useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Drawer,
  Box,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import SegmentRoundedIcon from '@mui/icons-material/SegmentRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import WalletRoundedIcon from '@mui/icons-material/WalletRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { StorageService } from '../services';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import logo from '../logo.svg';
import { DashboardService } from '../services';
import LogoutIcon from '@mui/icons-material/Logout';

const AdminNavComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [profileMenu, setProfileMenu] = useState(null);
  const [notifyMenu, setNotifyMenu] = useState(null);
  const [state, setState] = useState({
    right: false,
  });
  const [pendingMerges, setPendingMerges] = useState(0);

  const profileOpen = (event) => {
    setProfileMenu(event.currentTarget);
  };
  const profileClose = () => {
    setProfileMenu(null);
  };
  const notifyOpen = (event) => {
    setNotifyMenu(event.currentTarget);
  };
  const notifyClose = () => {
    setNotifyMenu(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <NavLink
          to={'/'}
          state={{ tabs: 0 }}
          onClick={() => toggleDrawer(anchor, false)}
          className={location.pathname === '/admin' ? 'active' : ''}
        >
          <ListItem
            disablePadding
            // onClick={() => {
            //   navigate('/admin');
            //   toggleDrawer(anchor, false);
            // }}
          >
            <ListItemButton>
              <ListItemIcon>
                <img
                  src={logo}
                  className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root mui-border'
                />
              </ListItemIcon>
              <ListItemText
                primary='  Vintage Neighbors'
                className='mui-side-text'
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        {/* <NavLink
          to={'/'}
          state={{ tabs: 0 }}
          onClick={() => toggleDrawer(anchor, false)}
        >
        <ListItem
          disablePadding
          // onClick={() => {
          //   navigate('/admin');
          //   toggleDrawer(anchor, false);
          // }}
        >
          <ListItemButton>
            <ListItemIcon>
              <GridViewRoundedIcon />
            </ListItemIcon>
            <ListItemText primary='Dashboard' className='mui-side-text' />
          </ListItemButton>
        </ListItem>
        </NavLink> */}
        <Divider />
        <NavLink
          to={'/admin/merge-requests'}
          state={{ tabs: 0 }}
          onClick={() => toggleDrawer(anchor, false)}
        >
          <ListItem
            disablePadding
            // onClick={(e) => {
            //   navigate('/admin/merge-requests');
            //   toggleDrawer(anchor, false);
            // }}
          >
            <ListItemButton>
              <ListItemIcon>
                <WalletRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Merge Request' className='mui-side-text' />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink
          to={'/admin/artworks'}
          state={{ tabs: 0 }}
          onClick={() => toggleDrawer(anchor, false)}
        >
          <ListItem
            disablePadding
            onClick={(e) => {
              navigate('/admin/artworks');
              toggleDrawer(anchor, false);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <BubbleChartIcon />
              </ListItemIcon>
              <ListItemText primary='Artwork' className='mui-side-text' />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink
          to={'/admin/users'}
          state={{ tabs: 0 }}
          onClick={() => toggleDrawer(anchor, false)}
        >
          <ListItem
            disablePadding
            onClick={(e) => {
              navigate('/admin/users');
              toggleDrawer(anchor, false);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <PeopleRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Users' className='mui-side-text' />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink
          to={'/admin/admin-users'}
          state={{ tabs: 0 }}
          onClick={() => toggleDrawer(anchor, false)}
        >
          <ListItem
            disablePadding
            // onClick={(e) => {
            //   navigate('/admin/admin-users');
            //   toggleDrawer(anchor, false);
            // }}
          >
            <ListItemButton>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Admin' className='mui-side-text' />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        <ListItem
          disablePadding
          onClick={(e) => {
            logout();
            toggleDrawer(anchor, false);
            window.location.reload();
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Logout' className='mui-side-text' />
          </ListItemButton>
        </ListItem>
        <Divider />
      </List>
    </Box>
  );

  const logout = () => {
    StorageService.clearSession();
    navigate('/admin/signin');
  };

  const getDashboardData = async () => {
    DashboardService.getDashboardInfo()
      .then((res) => {
        if (res.status) {
          if (res.data) {
            setPendingMerges(res?.data?.pending_merges);
          }
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === 'User account deactivated'
        ) {
          StorageService.clearSession();
          navigate('/admin/signin');
          return window.location.reload();
        }
      });
  };

  useEffect(() => {
    setInterval(() => {
      getDashboardData();
    }, [3000]);
  }, []);

  document.addEventListener('click', (e) => {
    if (e.target.id == 'mui-notify-info') {
      return 'you clicked on target point';
    }
  });

  return (
    <nav className='admin-nav'>
      <div>
        <Button
          id='mui-profile-info'
          aria-controls={profileMenu ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={profileMenu ? 'true' : undefined}
          onClick={profileOpen}
          className='mui-icon-btn'
          variant='text'
        >
          <AccountCircleRoundedIcon className='mui-nav-icon ' />
        </Button>
        <Menu
          id='mui-profile-menu'
          anchorEl={profileMenu}
          open={profileMenu}
          onClose={profileClose}
          MenuListProps={{
            'aria-labelledby': 'mui-profile-info',
          }}
          className='a-mui-drop-down'
        >
          <MenuItem
            onClick={() => {
              navigate('/admin/change-password');
              profileClose();
            }}
            className='text-yellow'
          >
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/admin/update-profile');
              profileClose();
            }}
            className='text-yellow'
          >
            Update Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              logout();
              profileClose();
            }}
            className='text-yellow'
          >
            Logout
          </MenuItem>
        </Menu>
        <Button
          id='mui-notify-info'
          aria-controls={notifyMenu ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={notifyMenu ? 'true' : undefined}
          onClick={notifyOpen}
          className='mui-icon-btn'
          variant='text'
        >
          <NotificationsRoundedIcon className='mui-nav-icon' />
        </Button>

        <Menu
          key={pendingMerges + 10}
          id='mui-notify-menu'
          anchorEl={notifyMenu}
          open={notifyMenu}
          onClose={notifyClose}
          MenuListProps={{
            'aria-labelledby': 'mui-notify-info',
          }}
          className='a-mui-drop-down'
        >
          <MenuItem
            key={pendingMerges}
            className='text-yellow'
            onClick={() => {
              navigate('/admin/merge-requests', {
                state: { data: 'pendingMergeRequest' },
              });
              notifyClose();
            }}
          >
            {pendingMerges && pendingMerges > 0
              ? pendingMerges + ' New Merge Requests'
              : 'No New Merge Request'}{' '}
          </MenuItem>
        </Menu>
      </div>
      <div>
        <Button onClick={toggleDrawer('right', true)}>
          <SegmentRoundedIcon className='mui-nav-icon' />
        </Button>
        <Drawer
          anchor={'right'}
          open={state['right']}
          onClose={toggleDrawer('right', false)}
        >
          {list('right')}
        </Drawer>
      </div>
    </nav>
  );
};

export default AdminNavComponent;
