import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { Modal, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArtworkService } from '../../../services/admin';
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { PreLoaderComponent, PreLoaderComponent2 } from '../../../components';
import { StorageService } from '../../../services';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ArtWorks = () => {
  const navigate = useNavigate();
  const [artWorks, setArtWorks] = useState([]);
  const [rows, setRows] = useState([]);
  const [artwork, setArtwork] = useState({});
  const [limit, setLimit] = useState(5);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [openArtwork, setOpenArtwork] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [artowrkDetails, setArtworkDetails] = useState({});
  const [viewArtInfo, toggleArtInfo] = useState(false);
  const [loadArt, setLoadArt] = useState(false);

  const columns = [
    { field: 'id', headerName: 'id', minWidth: 70, flex: 0.5 },
    { field: 'sr_no', headerName: 'Sr #', minWidth: 70, flex: 0.2 },
    {
      field: 'details',
      headerName: 'Details',
      minWidth: 120,
      flex: 0.3,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleViewAllDetails = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setArtworkDetails(params.value);
          setArtwork({});
          toggleArtInfo(true);
          setLoadArt(true);
          ArtworkService.getArt(params.id)
            .then((res) => {
              setLoadArt(false);
              if (res.status) {
                // setArtworkDetails(res.data);
                setArtwork(res.data.artwork_uri);
              } else {
                setArtwork({});
              }
            })
            .catch((error) => {
              setLoadArt(false);
              setArtwork({});
            });
        };

        return (
          <Button
            variant='text'
            className='mui-grid-btn mx-auto text-yellow'
            onClick={(e) => handleViewAllDetails(e)}
          >
            <FullscreenIcon className='text-yellow' />
          </Button>
        );
      },
    },
    { field: 'alias', headerName: 'Alias', minWidth: 150, flex: 0.7 },
    {
      field: 'website',
      headerName: 'Website',
      minWidth: 350,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.value.trim() !== '' && (
              <a
                href={params.value}
                target='_blank'
                style={{ color: '#000000DE' }}
              >
                {params.value}
              </a>
            )}
          </>
        );
      },
    },
    { field: 'email', headerName: 'Email', minWidth: 250, flex: 0.8 },
    {
      field: 'artwork',
      headerName: 'Preview',
      disableColumnMenu: true,
      sortable: false,
      minWidth: 100,
      flex: 0.3,
      headerAlign: 'center',
      renderCell: (params) => {
        const viewArtWork = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setOpenArtwork(true);
          setArtwork({});
          // setArtwork(params.value);
          setLoadArt(true);
          ArtworkService.getArt(params.id)
            .then((res) => {
              setLoadArt(false);
              if (res.status) {
                setArtwork(res.data.artwork_uri);
              } else {
                setArtwork({});
              }
            })
            .catch((error) => {
              setLoadArt(false);
              setArtwork({});
            });
        };

        return (
          <Button
            variant='text'
            className='mui-grid-btn mx-auto text-yellow'
            onClick={(e) => viewArtWork(e)}
          >
            <CollectionsRoundedIcon className='text-yellow' />
          </Button>
        );
      },
    },
    {
      field: 'approved',
      headerName: 'Approved',
      minWidth: 160,
      flex: 0.3,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleChangeStatus = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setIsLoading(true);
          ArtworkService.cahngeStatus(params.id)
            .then((res) => {
              setIsLoading(false);
              if (res.status) {
                Swal.fire('success', res.message, 'success');
                getArtWorks();
              }
            })
            .catch((error) => {
              setIsLoading(false);
              if (
                error.response &&
                error.response.data &&
                error.response.data.message === 'User account deactivated'
              ) {
                StorageService.clearSession();
                navigate('/admin/signin');
                return window.location.reload();
              } else if (error.response.data.message) {
                Swal.fire({
                  icon: 'error',
                  title: 'Error occurred',
                  html: `<p>${error.response.data.message}</p>`,
                });
              }
            });
        };

        return (
          <>
            {params.value ? (
              <Button
                variant='text'
                className='mui-grid-btn mx-auto text-capitalize w-75px text-yellow'
                onClick={(e) => handleChangeStatus(e)}
              >
                Approved
              </Button>
            ) : (
              <Button
                variant='text'
                className='mui-grid-btn-primary mx-auto text-capitalize w-75px'
                onClick={(e) => handleChangeStatus(e)}
              >
                Approve
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const style = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '4rem',
    width: '90%',
    bgcolor: '#ffff00',
    boxShadow: 24,
    p: 3,
    borderRadius: '15px',
    '& .MuiTextField-root': { my: 1, width: '25ch' },
  };

  const resetArtData = () => {
    setArtWorks([]);
    setFirstPage(1);
    setLastPage(1);
    setTotalPages(null);
    setTotalCount(0);
    // setCurrentPage(1);
  };

  const getArtWorks = () => {
    resetArtData();
    setIsLoading(true);
    ArtworkService.getArts(currentPage, limit)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          if (res.message !== 'No artwork found!') {
            setArtWorks(res.data);
            setFirstPage(res.pagenation.first_page);
            setLastPage(res.pagenation.last_page);
            setLimit(res.pagenation.limit);
            setTotalPages(
              Math.ceil(res.pagenation.total / res.pagenation.limit)
            );
            setTotalCount(res.pagenation.total);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getArtWorks();
  }, []);

  const getArtworkRows = () => {
    if (artWorks && artWorks.length > 0) {
      setIsLoading(true);
      const allRows = artWorks.map((art, index) => {
        return {
          id: art._id,
          sr_no: ++index + (currentPage - 1) * limit,
          user: art.user,
          alias: art.alias,
          website: art.website,
          email: art.email,
          approved: art.approved,
          artwork: art.artwork_uri,
          details: art,
        };
      });
      setRows(allRows);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getArtworkRows();
  }, [artWorks]);

  const hanldePagenation = (pagenate_type) => {
    if (pagenate_type === 'first') {
      setCurrentPage(firstPage);
    } else if (pagenate_type === 'last') {
      setCurrentPage(lastPage);
    } else if (pagenate_type === 'next') {
      // handle next
      setCurrentPage(currentPage + 1);
    } else if (pagenate_type === 'prev') {
      // handle previous
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    getArtWorks();
  }, [currentPage]);

  return (
    <>
      {/* {isLoading && <PreLoaderComponent2 />} */}
      <div>
        <h1 className='text-center ad-dash-main-heading heading-level-2'>Artwork</h1>
      </div>

      <div>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          className='data-grid-border'
          sx={{
            borderColor: '#ffff00',
            backgroundColor: '#ffff00',
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          autoHeight={true}
          loading={isLoading}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
        <div className='d-flex justify-content-around align-items-center py-3'>
          {artWorks.length > 0 ? (
            <div className='d-flex justify-content-center align-items-center'>
              <div>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('first')}
                  disabled={currentPage == firstPage}
                >
                  <i className='fa-solid fa-angles-left mui-pagenate-icon'></i>
                </Button>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('prev')}
                  disabled={currentPage == firstPage}
                >
                  <i className='fa-solid fa-chevron-left mui-pagenate-icon'></i>
                </Button>
              </div>
              <div>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('next')}
                  disabled={currentPage == lastPage}
                >
                  <i className='fa-solid fa-chevron-right mui-pagenate-icon'></i>
                </Button>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('last')}
                  disabled={currentPage == lastPage}
                >
                  <i className='fa-solid fa-angles-right mui-pagenate-icon'></i>
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className='p-0 m-0'>
          {currentPage && lastPage ? (
            <p className='theme-text-color text-center'>
              {currentPage} of {lastPage} Page
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* view an artwork */}
      <div className=''>
        <Modal
          open={openArtwork}
          onClose={() => setOpenArtwork(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-y-auto '
        >
          <Box sx={style} className=''>
            <div className='d-flex justify-content-between modal-header py-0 my-0'>
              <h3 className='modal-title'>Artwork</h3>
              <button
                type='button'
                className='modal-butn'
                onClick={() => setOpenArtwork(false)}
              >
                x
              </button>
            </div>
            <div className=' d-flex flex-column justify-content-center align-items-center py-3 '>
              <div className='w-100 pb-3 pt-5'>
                {loadArt ? (
                  <PreLoaderComponent color='dark' />
                ) : (
                  <img
                    src={artwork}
                    alt='artwork'
                    className='nft-prevew-dimensions mx-auto '
                    style={{
                      boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                      border: '1px solid rgba(0,0,0,0.35)',
                      borderRadius: '0.2rem',
                    }}
                  />
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      {/*  view all the details of merge requests */}
      <div className=''>
        <Modal
          open={viewArtInfo}
          onClose={(e) => toggleArtInfo(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-y-auto '
        >
          <Box sx={style} className=''>
            <div className='d-flex justify-content-between modal-header py-0 my-0'>
              <h3 className='modal-title'>Artwork Details</h3>
              <button
                type='button'
                className='modal-butn'
                onClick={(e) => toggleArtInfo(false)}
              >
                x
              </button>
            </div>

            {Object.keys(artowrkDetails).length > 0 && (
              <>
                <div className=' d-flex flex-column justify-content-center align-items-start pb-3 pt-5 fs-1_25rem'>
                  <h2 className=' text-dark text-level-3'>
                    Alias:{' '}
                    <span className='fw_500 text-capitalize'>
                      {artowrkDetails.alias}
                    </span>
                  </h2>
                  <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                    Website:{' '}
                    <a
                      href={artowrkDetails.website}
                      target='_blank'
                      className='fw_500  word-break-all text-blue'
                      style={{ color: 'var(--second)' }}
                    >
                      <i><u>Link</u></i>
                    </a>
                  </p>
                  <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                    Email:{' '}
                    <span className='fw_500'>
                      {artowrkDetails.email && artowrkDetails.email !== ''
                        ? artowrkDetails.email
                        : 'No email'}
                    </span>
                  </p>
                  <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                    Approved:{' '}
                    <span className='fw_500'>
                      {artowrkDetails.approved ? 'Approved' : 'Not Approved'}
                    </span>
                  </p>
                </div>
                <div className=' d-flex flex-column justify-content-center align-items-center pb-3  fs-1_25rem pt-3'>
                  <h3 className='bg-heading text-capitalize text-level-2'>
                    Artwork Preview
                  </h3>
                  <div className='w-100 py-2'>
                    {loadArt ? (
                      <PreLoaderComponent color='dark' />
                    ) : (
                      <img
                        src={artwork}
                        alt='background'
                        className='nft-prevew-dimensions mx-auto '
                        style={{
                          boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                          border: '1px solid rgba(0,0,0,0.35)',
                          borderRadius: '0.2rem',
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ArtWorks;
