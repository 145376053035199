import API from '../config/api.config';
import axios from 'axios';

/* const searchNFTs = (wallet_address) => {
  if (wallet_address !== ' ' && wallet_address !== null) {
    // get friend nfts from opensea
    return axios
      .get(`https://api.opensea.io/api/v1/assets?owner=${wallet_address}`)
      .then((res) => {
        return res.data;
      });
  }
};

const refreshNFT = (wallet_address) => {
  return axios
    .get(`https://api.opensea.io/api/v1/assets?owner=${wallet_address}`)
    .then((res) => {
      if (res.data.assets) {
        let assetArr = [];
        res.data.assets.map((nft) => {
          let assetObj = {
            id: nft.id,
            image_url: nft.image_url,
            image_preview_url: nft.image_preview_url,
            image_thumbnail_url: nft.image_thumbnail_url,
            image_original_url: nft.image_original_url,
            animation_url: nft.animation_url,
            animation_original_url: nft.animation_original_url,
            name: nft.name,
            description: nft.description,
            owner: {
              username: nft.owner ? nft.owner.username : '',
              profile_img_url: nft.owner ? nft.owner.profile_img_url : '',
              address: nft.owner ? nft.owner.address : '',
            },
            creator: {
              username: nft.creator.username,
              profile_img_url: nft.creator.profile_img_url,
              address: nft.creator.address,
            },
            traits: nft.traits,
            token_id: nft.token_id,
          };
          assetArr.push(assetObj);
        });

        return {
          assets: assetArr,
        };
      }
    })
    .catch((err) => {
      return err.response;
    });
}; */

const config = {
  headers: {
    'x-api-key': process.env.REACT_APP_OPEN_SEA_API_KEY,
  },
};

const searchNFTs = (wallet_address) => {
  if (wallet_address !== ' ' && wallet_address !== null) {
    // get friend nfts from opensea
    return axios
      .get(
        `https://api.opensea.io/api/v1/assets?owner=${wallet_address}`,
        config
      )
      .then((res) => {
        return res.data;
      });
  }
};

const refreshNFT = (wallet_address) => {
  return axios
    .get(`https://api.opensea.io/api/v1/assets?owner=${wallet_address}`, config)
    .then((res) => {
      if (res.data.assets) {
        let assetArr = [];
        res.data.assets.map((nft) => {
          let assetObj = {
            id: nft.id,
            image_url: nft.image_url,
            image_preview_url: nft.image_preview_url,
            image_thumbnail_url: nft.image_thumbnail_url,
            image_original_url: nft.image_original_url,
            animation_url: nft.animation_url,
            animation_original_url: nft.animation_original_url,
            name: nft.name,
            description: nft.description,
            owner: {
              username: nft.owner ? nft.owner.username : '',
              profile_img_url: nft.owner ? nft.owner.profile_img_url : '',
              address: nft.owner ? nft.owner.address : '',
            },
            creator: {
              username: nft.creator.username,
              profile_img_url: nft.creator.profile_img_url,
              address: nft.creator.address,
            },
            traits: nft.traits,
            token_id: nft.token_id,
          };
          assetObj.image_preview_url !== null &&
            assetObj.image_preview_url !== undefined &&
            assetObj.image_preview_url !== '' &&
            assetArr.push(assetObj);
        });

        return {
          assets: assetArr,
        };
      }
    });
};

const removeBg = (nft_url, api_key) => {
  var data = new FormData();
  data.append('output_type', 'cutout');
  data.append('image_url', nft_url);

  var config = {
    method: 'post',
    url: 'https://api.picsart.io/tools/1.0/removebg',
    headers: {
      // "x-picsart-api-key": process.env.REACT_APP_PICS_ART_kEY,
      'x-picsart-api-key': api_key,
    },
    data: data,
  };

  return axios(config).then(function (response) {
    return response.data;
  });
};

const getPicsArtApiKey = () => {
  return API.get(`/removebg/unexpired-api-key`).then((res) => {
    return res.data;
  });
};

const increaseHitsPicsArtApi = (_artkey_id) => {
  return API.patch(`/removebg/increase-hits/${_artkey_id}`).then((res) => {
    return res.data;
  });
};

const ThirdPartyService = {
  increaseHitsPicsArtApi,
  getPicsArtApiKey,
  searchNFTs,
  refreshNFT,
  removeBg,
};

export default ThirdPartyService;
