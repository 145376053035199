import React, { useEffect, useState } from 'react';
import { TextField, Box } from '@mui/material';
import { AuthService } from '../../../services/admin';
import ErrorFormat from '../../../utils/ErrorFormat;';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';
import FormValidator from '../../../utils/validation';
import { PreLoaderComponent, PreLoaderComponent2 } from '../../../components';
import { Alert } from '@mui/material';
import useScreen from '../../../hooks/useScreen';
import TextFieldWrapper from '../../../components/TextFieldWrapper';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useScreen();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [vcode, setVcode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formType, setFormType] = useState('forgot');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
      document.getElementById('submit-btn').focus();
    }
  }, [location.state]);

  const resetForm = () => {
    setPassword('');
    setEmail('');
    setVcode('');
  };

  const setVerificationCode = (value) => {
    let new_value = '';
    let regExp = /[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    for (let i = 0; i < value.length; i++) {
      if (i > 5) {
        new_value += '';
      } else if (!regExp.test(value[i])) {
        new_value += value[i];
      } else {
        new_value += '';
      }
    }
    return setVcode(new_value);
  };

  const forgotPassword = (e) => {
    let noOfErrors = 0;
    let firstKey;

    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      setIsLoading(true);

      AuthService.forgotPassword(email)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            setFormType('reset');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (error.response.data.errors) {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: ErrorFormat.validationErrors(error.response.data.errors),
              });
            } else if (error.response.data.message) {
              if (error.response.data.message === 'Account not verified') {
                navigate('/admin/verify-account', { state: { email: email } });
              } else if (
                error.response.data.message === 'Account is inactive'
              ) {
                resetForm();
              }
              Swal.fire({
                icon: 'waring',
                title: 'Inactive account!',
                html: `<p>${error.response.data.message}</p>`,
              });
            }
          }
        });
    }
  };

  const resetPassword = (e) => {
    let noOfErrors = 0;
    let firstKey;

    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      setIsLoading(true);

      const data = {
        email: email,
        new_password: password,
        verification_code: vcode,
      };

      AuthService.resetPassword(data)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            Swal.fire('success', res.message, 'success');
            resetForm();
            navigate('/admin/signin');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (error.response.data.errors) {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: ErrorFormat.validationErrors(error.response.data.errors),
              });
            } else if (error.response.data.message) {
              if (error.response.data.message === 'Account not verified') {
                navigate('/admin/verify-account', { state: { email: email } });
              } else if (
                error.response.data.message === 'Account is inactive'
              ) {
                resetForm();
              } else if (
                error.response.data.message ===
                'Invalid email or verification code'
              ) {
                return Swal.fire({
                  icon: 'error',
                  title: 'Error occurred',
                  html: `<p>${error.response.data.message}</p>`,
                });
              }else if (
                error.response.data.message ===
                'Please try another password!'
              ) {
                return Swal.fire({
                  icon: 'error',
                  title: 'Error occurred',
                  html: `<p>Current password can not be use as new passowrd</p>`,
                });
              } else
                Swal.fire({
                  icon: 'waring',
                  title: 'Inactive account!',
                  html: `<p>${error.response.data.message}</p>`,
                });
            }
          }
        });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    var em = true;
    var vc = true;
    var ps = true;

    if (formType === 'forgot') {
      em = FormValidator.handleOnSubmit('admin_email2', setErrors);
      if (email === '') {
        let emailElement = document.getElementById('admin_email2');
        return emailElement.focus();
      }
    } else {
      vc = FormValidator.handleOnSubmit('verificationCode2', setErrors);
      ps = FormValidator.handleOnSubmit('password', setErrors);
    }
    !vc
      ? document.getElementById('verificationCode2').focus()
      : !ps && document.getElementById('password').focus();
    {
      em &&
        vc &&
        ps &&
        setTimeout(() => {
          formType === 'forgot' ? forgotPassword(e) : resetPassword(e);
        }, 200);
    }
  };

  return (
    <>
      <div className='px-4 py-4 d-flex justify-content-center align-items-center w-100 min-h-100vh'>
        {/*  {isLoading ? (
          <PreLoaderComponent />
        ) : ( */}
        <Box sx={{ width: '100%' }} className='' autoComplete='off'>
          <form
            className='signin-form px-4 py-4 w-100 h-100'
            encType='multipart/form-data'
            onSubmit={(e) => submitHandler(e)}
            autoComplete='off'
            noValidate
          >
            <h1 className='text-center ad-dash-main-heading pb-3 fx-sh heading-level-2'>
              {formType === 'forgot' ? 'Forgot Password' : 'Reset Password'}
            </h1>
            {formType !== 'forgot' && (
              <Alert
                fontSize='inherit'
                size='small'
                severity='success'
                sx={{
                  backgroundColor: 'rgb(237, 247, 237)',
                  // color: "var(--second)",
                  color: 'var(--success)',
                  marginBottom: '1rem',
                  // textAlign: "center",
                  // display:"flex",
                  // justifyCenter: "center"
                }}
                autoComplete='off'
              >
                We've sent an email to {email}
              </Alert>
            )}
            <div className=' theme-text-color formating  '>
              <div className='theme-text-color pb-1'>
                <TextFieldWrapper
                  fullWidth
                  //className="wallet-select-field selected-text description-text"
                  onChange={(e) => {
                    setEmail(e.target.value.trim());
                    FormValidator.handleOnBlur(e, setErrors);
                  }}
                  value={email.trim()}
                  label='Email'
                  type='email'
                  name='admin_email2'
                  id='admin_email2'
                  required
                  disabled={formType === 'reset'}
                  onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  inputProps={{
                    autoComplete: 'new-email',
                    form: {
                      autoComplete: 'off',
                    },
                    maxLength: 128,
                  }}
                  size={width <= 320 ? 'small' : 'medium'}
                  hidden={formType === 'reset' && true}
                />
                {errors && errors.admin_email2 && (
                  <div className='error ps-2'>
                    <span className='error-span mt-0 pb-0 '>
                      <i className='fa-solid fa-circle-exclamation pe-1'></i>
                      {errors.admin_email2}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {formType === 'reset' && (
              <>
                <div className=' theme-text-color formating py-3 '>
                  <div className='theme-text-color'>
                    <TextFieldWrapper
                      fullWidth
                      //className='wallet-select-field selected-text description-text'
                      onChange={(e) => {
                        setVerificationCode(e.target.value.trim());
                        FormValidator.handleOnBlur(e, setErrors);
                      }}
                      value={vcode.trim()}
                      label='Verification Code'
                      type='string'
                      required
                      id='verificationCode2'
                      name='verificationCode2'
                      onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                      autoFocus={true}
                      inputProps={{ maxLength: 6 }}
                      size={width <= 320 ? 'small' : 'medium'}
                    />
                    {errors && errors.verificationCode2 && (
                      <div className='error ps-2'>
                        <span className='error-span mt-0 pb-0 '>
                          <i className='fa-solid fa-circle-exclamation pe-1'></i>
                          {errors.verificationCode2}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className=' theme-text-color formating py-3 '>
                  <div className='theme-text-color'>
                    <TextFieldWrapper
                      fullWidth
                      //className='wallet-select-field selected-text description-text'
                      onChange={(e) => {
                        setPassword(e.target.value.trim());
                        FormValidator.handleOnBlur(e, setErrors);
                      }}
                      value={password.trim()}
                      label='New Password'
                      type='password'
                      id='password'
                      name='password'
                      required
                      onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                      inputProps={{ maxLength: 100 }}
                      size={width <= 320 ? 'small' : 'medium'}
                    />

                    {errors && errors.password && (
                      <div className='error ps-2'>
                        <span className='error-span mt-0 pb-0'>
                          <i className='fa-solid fa-circle-exclamation pe-1'></i>
                          {errors.password}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            <div className=' w-100 py-3'>
              <button
                type='submit'
                className='butn butn-lg-primary w-100'
                id='submit-btn'
              >
                {formType === 'forgot' ? 'Forgot Password' : 'Reset Password'}
              </button>
              <div className='theme-text-color d-flex sm-flex-d justify-content-center align-items-center pt-2'>
                <div
                  onClick={() =>
                    navigate('/admin/signin', {
                      state: { email },
                    })
                  }
                  className='text-center cursor-pointer theme-text-color pt-2 fs-lg fw_500 fx-sh'
                >
                  Sign In
                </div>
              </div>
            </div>
          </form>
        </Box>
        {/*  )} */}
        {isLoading && <PreLoaderComponent2 />}
      </div>
    </>
  );
};

export default ForgotPassword;
