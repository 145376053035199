import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { UserService } from '../../../services/admin';
import { StorageService } from '../../../services';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import FormValidator from '../../../utils/validation';
import { PreLoaderComponent } from '../../../components';
import useScreen from '../../../hooks/useScreen';
import TextFieldWrapper from '../../../components/TextFieldWrapper';

const UpdateProfile = () => {
  const navigate = useNavigate();
  const { width } = useScreen();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const user = StorageService.getCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const resetForm = () => {
    setPassword('');
    setEmail('');
    setName('');
  };

  const getProfileInfo = () => {
    setIsLoading(true);
    setName(user?.name);
    setEmail(user?.email);
    setIsLoading(false);
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    FormValidator.handleOnSubmit('name', setErrors);
    FormValidator.handleOnSubmit('password2', setErrors);
    // updateProfile(e)
    setIsFormSubmit(true);
  };

  const updateProfile = async (e) => {
    // e.preventDefault();
    setIsFormSubmit(false);
    setIsLoading(true);
    let noOfErrors = 0;
    let firstKey;

    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      setIsLoading(false);
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      const data = {
        name: name.trim(),
        email: email.trim(),
        old_password: password.trim(),
      };

      UserService.updateProfile(user.user_id, data)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            Swal.fire('success', res.message, 'success');
            resetForm();
            StorageService.clearSession();
            return navigate('/admin/signin');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setPassword('');
          if (error.response) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message === 'User account deactivated'
            ) {
              StorageService.clearSession();
              Swal.fire({
                icon: 'warning',
                title: 'Inactive account!',
                html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
              });
              return navigate('/admin/signin');
            } else if (error.response.data.message) {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: `<p>${error.response.data.message}</p>`,
              });
            }
          }
        });
    }
  };

  useEffect(() => {
    if (isFormSubmit) {
      updateProfile();
    }
  }, [isFormSubmit]);

  return (
    <>
      <div className=' py-2 d-flex justify-content-center align-items-center w-100 min-h-100vh'>
        {isLoading ? (
          <PreLoaderComponent />
        ) : (
          <form
            className='px-4 w-100 h-100'
            // encType="multipart/form-data"
            onSubmit={(e) => submitHandler(e)}
            autoComplete='off'
            noValidate
          >
            <h1 className='text-center text-yellow heading-level-2 pb-3 fx-sh'>
              Update Profile
            </h1>
            <div className=' theme-text-color formating py-3 '>
              <div className='theme-text-color'>
                <TextFieldWrapper
                  fullWidth
                  className='wallet-select-field selected-text description-text'
                  onChange={(e) => {
                    setName(e.target.value.replace(/^[0-9]/, ''));
                    FormValidator.handleOnBlur(e, setErrors);
                  }}
                  value={name}
                  label='Name'
                  type='text'
                  id='name'
                  name='name'
                  // placeholder="Alias Name"
                  required
                  onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  // inputProps={{ minLength: 2, maxLength: 100 }}
                  inputProps={{ maxLength: 20 }}
                  size={width <= 320 ? 'small' : 'medium'}
                />
                {errors && errors.name && (
                  <div className='error ps-2'>
                    <span className='error-span mt-0 pb-0'>
                      <i className='fa-solid fa-circle-exclamation pe-1'></i>
                      {errors.name}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className=' theme-text-color formating py-3 '>
              <div className='theme-text-color'>
                <TextFieldWrapper
                  fullWidth
                  className='wallet-select-field selected-text description-text'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  label='Email'
                  type='email'
                  // placeholder="Email"
                  required
                  disabled
                  size={width <= 320 ? 'small' : 'medium'}
                />
              </div>
            </div>
            <div className=' theme-text-color formating py-3 '>
              <div className='theme-text-color'>
                <TextFieldWrapper
                  fullWidth
                  className='wallet-select-field selected-text description-text'
                  onChange={(e) => {
                    setPassword(e.target.value);
                    FormValidator.handleOnBlur(e, setErrors);
                  }}
                  value={password}
                  label='Password'
                  type='password'
                  id='password2'
                  name='password2'
                  // placeholder="Password"
                  required
                  onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  // inputProps={{minLength:8, maxLength: 100}}
                  inputProps={{ maxLength: 100 }}
                  size={width <= 320 ? 'small' : 'medium'}
                />
                {errors && errors.password2 && (
                  <div className='error ps-2'>
                    <span className='error-span mt-0 pb-0'>
                      <i className='fa-solid fa-circle-exclamation pe-1'></i>
                      {errors.password2}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className=' w-100 py-3'>
              <button
                type='submit'
                className='butn butn-lg-primary w-100'
                disabled={isLoading}
                onClick={(e) => submitHandler(e)}
              >
                Update Profile
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default UpdateProfile;
