import React, { useState, useEffect, useRef } from 'react';
import 'swiper/css';
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box, Modal, TextField } from '@mui/material';
import {
  LatestComponents,
  PreLoaderComponent,
  TrendingComponents,
} from './components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import {
  ArtworkService,
  StorageService,
  AuthService,
  UserService,
} from '../../services';
import Swal from 'sweetalert2';
import ErrorFormat from '../../utils/ErrorFormat;';
import FormValidator from '../../utils/validation';
import { ImageCropper, PreLoaderComponent2 } from '../../components';
import { useNavigate } from 'react-router-dom';
import useScreen from '../../hooks/useScreen';
import TextFieldWrapperDark from '../../components/TextFieldWrapperDark';
import { Upload, Add } from '@mui/icons-material';
import { plusCircleIcon } from '../../utils/images';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ArtworkPage = () => {
  const fileInput = useRef(null);
  const navigate = useNavigate();
  const { width } = useScreen();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };
  const [alias, setAlias] = useState('');
  const [url, setUrl] = useState('');
  const [email, setEmail] = useState('');
  const [artwork, setArtwork] = useState({});
  const [artworkPreview, setArtworkPreview] = useState(null);
  const [artworkFile, setArtworkFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState('');
  const [artworks, setArtWorks] = useState([]);
  const [cropedArt, setCropedArt] = useState('');
  const [artworkWidth, setArtworkWidth] = useState(0);
  const [artworkHeight, setArtworkHeight] = useState(0);
  const [isUploadedArtSquare, setIsUploadedArtSquare] = useState(false);
  const [isArworkCroped, setIsArtworkCroped] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const style = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '4rem',
    width: '90%',
    bgcolor: '#ffff00',
    boxShadow: 24,
    p: 3,
    borderRadius: '15px',
    '& .MuiTextField-root': { my: 1, width: '25ch' },
  };

  const resetArtsData = () => {
    setArtworkPreview(null);
    setArtworkFile(null);
    setArtwork({});
    setCropedArt('');
    setIsUploadedArtSquare(false);
    setArtworkHeight(0);
    setArtworkWidth(0);
    setIsArtworkCroped(false);
    setFileName('');
    setErrors((old) => {
      return {
        ...old,
        artwork: '',
      };
    });
  };

  const resetForm = () => {
    setAlias('');
    setUrl('');
    setEmail('');
    resetArtsData();
    setErrors({});
  };

  const handleFileInput = async (e) => {
    resetArtsData();
    if (e.target.files.length === 0) {
      setErrors((old) => {
        return {
          ...old,
          artwork: 'Artwork is required',
        };
      });
      setFileName('');
      return;
    }
    const fileSize = e.target.files[0].size / (1024 * 1024);
    if (fileSize <= 8) {
      if (
        e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/jpg' ||
        e.target.files[0].type === 'image/jpeg'
      ) {
        var image = new Image();
        image.onload = function () {
          if (image.width < 1000 || image.height < 1000) {
            e.target.files = undefined;
            e.target.value = null;
            setFileName('');
            return setErrors((old) => {
              return {
                ...old,
                artwork: 'Artwork must be greater than 1000 x 1000 px',
              };
            });
          }
          /* if (image.width > 2000 || image.height > 2000) {
            e.target.files = undefined;
            e.target.value = null;
            setFileName('');
            return setErrors((old) => {
              return {
                ...old,
                artwork: 'Artwork must be greater than 1000 x 1000 px',
              };
            });
          } */
          setArtworkWidth(image.width);
          setArtworkHeight(image.height);
          if (image.width === image.height) {
            // set image is square
            setIsUploadedArtSquare(true);
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, image.width, image.width);
            const imgUrl = canvas.toDataURL('image/png');
            setArtworkPreview(imgUrl);
            setArtworkFile(e.target.files[0]);
            setArtwork(e.target.files[0]);
            setFileName(e.target.files[0].name);
          } else {
            setArtwork({});
            setFileName('');
            setArtworkPreview(null);
            setArtworkFile(null);
            e.target.files = undefined;
            e.target.value = null;
            setErrors((old) => {
              return {
                ...old,
                artwork: 'Please upload square artwork.',
              };
            });
          }
        };
        image.src = URL.createObjectURL(e.target.files[0]);
      } else {
        e.target.files = undefined;
        e.target.value = null;
        setFileName('');
        setErrors((old) => {
          return {
            ...old,
            artwork: 'Only png, jpg, jpeg Artwork are allowed.',
          };
        });
        e.target.files = undefined;
        return (e.target.value = null);
      }
    } else if (
      e.target.files[0].type !== 'image/png' &&
      e.target.files[0].type !== 'image/jpg' &&
      e.target.files[0].type !== 'image/jpeg'
    ) {
      e.target.files = undefined;
      e.target.value = null;
      setFileName('');
      setErrors((old) => {
        return {
          ...old,
          artwork: 'Only png, jpg, jpeg Artwork are allowed.',
        };
      });
      e.target.files = undefined;
      return (e.target.value = null);
    } else {
      e.target.files = undefined;
      e.target.value = null;
      setFileName('');
      setErrors((old) => {
        return {
          ...old,
          artwork: 'Artwork size is more than 8mb',
        };
      });
      e.target.files = undefined;
      return (e.target.value = null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const _name = FormValidator.handleOnSubmit('name', setErrors);
    const _url2 = FormValidator.handleOnSubmit('url2', setErrors);
    const _email2 = FormValidator.handleOnSubmit('email2', setErrors);
    var _artwork = true;
    if (artworkPreview === null) {
      _artwork = setErrors((old) => {
        return {
          ...old,
          artwork: 'Artwork is required',
        };
      });
      let uploadBtn = document.getElementById('upload-image-btn');
      return uploadBtn.click();
    } else if (!_name || !_url2 || !_email2 || !_artwork) {
      return;
    } else if (
      Object.keys(errors).length > 0 &&
      errors.artwork &&
      errors.artwork !== ''
    ) {
      let uploadBtn = document.getElementById('upload-image-btn');
      return uploadBtn.click();
    }

    let noOfErrors = 0;
    let firstKey;

    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      setIsLoading(true);

      /* const data = {
        alias: alias.trim(),
        website: url,
        email: email,
        artwork: artworkPreview,
      }; */
      var formData = new FormData();
      formData.append('alias', alias.trim());
      formData.append('website', url);
      formData.append('email', email);
      formData.append('artwork', artworkFile);

      const data = {
        alias: alias.trim(),
        website: url,
        email: email,
        artwork: artworkPreview,
      };

      ArtworkService.storeArtWork(formData)
        .then((res) => {
          setIsLoading(false);
          handleClose();
          if (res.status) {
            Swal.fire('success', res.message, 'success');
            resetForm();
            getArtworks();
          }
        })
        .catch((error) => {
          console.error('error= >', error);
          setIsLoading(false);
          if (error?.response?.data?.errors) {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: ErrorFormat.validationErrors(error.response.data.errors),
            });
          } else if (
            error?.response &&
            error?.response?.data &&
            error?.response?.data?.message
          ) {
            if (error?.response?.data?.message === 'User account deactivated') {
              Swal.fire({
                icon: 'warning',
                title: 'Inactive account!',
                html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
              }).then(() => {
                StorageService.clearSession();
                navigate('/connect-to-wallet');
                return window.location.reload();
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: `<p>${error.response.data.message}</p>`,
              });
            }
          }
        });
    }
  };

  const getArtworks = () => {
    setIsLoading(true);
    ArtworkService.getArtWorks(1, 6)
      .then((res) => {
        setIsLoading(false);
        if (res.status && res.data) {
          setArtWorks(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: `<p>${error.response.data.message}</p>`,
            });
          }
        }
      });
  };

  useEffect(() => {
    getArtworks();
  }, []);

  const disableButn = () => {
    if (isLoading) {
      //debugger;
      return true;
    } else if (errors.artwork && errors.artwork != '') {
      //debugger;
      return true;
    } else if (!isUploadedArtSquare && !isArworkCroped) {
      //debugger;
      return true;
    } else {
      //debugger;
      return false;
    }
  };

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      setIsLoading(true);
      UserService.checkUserActiveStatus()
        .then((res) => {
          setIsLoading(false);
          if (res.status === false) {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === 'User account deactivated'
          ) {
            setIsLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        });
    }
  }, []);
  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      navigate('/connect-to-wallet');
    }
  }, []);

  return (
    <>
      {isLoading && <PreLoaderComponent2 />}
      <div className='text-center'>
        <h1 className='theme-text-color main-title text-capitalize heading-level-2 letter-space-2 line-hight-12'>
          Hello {StorageService.getCurrentUser()?.alias.split(' ')[0]}, Upload
          your Artwork
        </h1>
      </div>
      {/* swiper for upload art work */}
      <div>
        <Swiper
          className='square-swiper' // custom-rounded p-3 artwork-swipper
          spaceBetween={50}
          slidesPerView={1}
          navigation={true}
          modules={[Navigation]}
        >
          {isLoading ? (
            <SwiperSlide className='artwork-image swiping-item w-100'>
              {' '}
              <PreLoaderComponent />
            </SwiperSlide>
          ) : artworks.length > 0 ? (
            artworks.map((art, index) => (
              <div key={index}>
                <SwiperSlide
                  className='square-swiper' /* className='artwork-image swiping-item w-100' */
                >
                  {/* <div> */}
                  <img
                    src={art.artwork_uri}
                    //src={`${process.env.REACT_APP_API_URL}/${art.artwork_uri}`}
                    alt={art.alias ? art.alias : 'artworks'}
                    className='square-swiper' //className='w-100 h-100'
                  />
                  <div className='position-absolute description '>
                    <a
                      href={art.website}
                      className='title px-2'
                      data-swiper-parallax='-300'
                    >
                      {art.alias}
                    </a>
                    {/* <div className="subtitle px-2" data-swiper-parallax="-200">
                      {art.website ? art.website : ""}
                    </div> */}
                  </div>

                  <div className='position-absolute'>
                    <div
                      className=' d-flex flex-column justify-content-center align-items-center'
                      onClick={() => handleOpen()}
                    >
                      <div class='input-div'>
                        {/* <Add className='icon' /> */}
                        <text className='icon heading-level-1'>+</text>
                      </div>
                      <text className='icon text-level-2 mt-2'>
                        Upload Artwork
                      </text>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            ))
          ) : (
            <>
              <SwiperSlide className='square-swiper bg-yellow '>
                <div className=' '>
                  <div className='d-flex flex-column bg-transparent justify-content-center'>
                    <div className=''>
                      <button className='butn' onClick={() => handleOpen()}>
                        Upload Artwork
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide className='swipper-item swiping-item'>
                <div className='h-100 d-flex flex-column bg-transparent justify-content-center my-2'>
                  <div className='d-flex flex-column justify-content-end h-100'>
                    <h3 className='swipper-title pb-3 text-level-2'>
                      Upload Artwork
                    </h3>
                  </div>
                  <div className='d-flex flex-column justify-content-center h-100'>
                    <button className='butn' onClick={() => handleOpen()}>
                      Upload Artwork
                    </button>
                  </div>
                </div>
              </SwiperSlide> */}
            </>
          )}
        </Swiper>
      </div>

      <div className='mx-auto'>
        <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { background: '#FFFF00' } }}
            inkbarstyle={{ background: '#FFFF00' }}
            centered
            aria-label='nfts-tab'
            className='fs-1_5rem'
          >
            <Tab
              label='Trending'
              {...a11yProps(0)}
              className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                value === 0 ? 'fw_bolder' : ''
              }`}
            />
            <Tab
              label='Latest'
              {...a11yProps(1)}
              className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                value === 1 ? 'fw_bolder' : ''
              }`}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <TrendingComponents />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LatestComponents />
        </TabPanel>
      </div>

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-y-auto'
        >
          <Box
            sx={style}
            className=''
            autoComplete='off'
            component='form'
            onSubmit={(e) => handleSubmit(e)}
            encType='multipart/form-data'
            noValidate
          >
            <div className='d-flex justify-content-between align-items-center  modal-header py-0 my-0'>
              <div className='modal-title fw_bolder'>Add Artwork</div>
              <button
                type='button'
                className='modal-butn fw_bolder'
                onClick={() => handleClose()}
              >
                x
              </button>
            </div>
            <div className='px-1 py-4'></div>
            <input
              type='file'
              ref={fileInput}
              onChange={(e) => handleFileInput(e)}
              hidden
              name='artwork'
              id='artwork'
              accept='image/png, image/jpg, image/jpeg'
            />
            <div className='d-flex flex-column justify-content-center align-items-start '>
              <div className='d-flex align-items-center'>
                <button
                  type='button'
                  className='upload-profile-btn'
                  onClick={() => fileInput.current.click()}
                  disabled={isLoading}
                >
                  <i className='fa-solid fa-circle-plus pe-2'></i>
                </button>

                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <div className='text-level-4'>Upload Artwork</div>
                  <div>
                    {errors.artwork && errors.artwork != '' ? (
                      <div className='error'>
                        <span className='error-span '>
                          <i className='fa-solid fa-circle-exclamation'></i>
                          {errors.artwork}
                        </span>
                      </div>
                    ) : (
                      <div className='text-gray text-level-6 word-break-all line-height-14'>
                        {fileName
                          ? fileName
                          : 'Artwork type: JPG, JPEG, PNG. Size more than 1000 x 1000 px'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <button
                type='button'
                className='upload-profile-btn w-100 '
                onClick={() => fileInput.current.click()}
                id='upload-image-btn'
              >
                <i className='fa-solid fa-circle-plus pe-2'></i>
                Upload Artwork
              </button>

              <div style={{ alignSelf: 'start' }}>
                {errors.artwork && errors.artwork != '' ? (
                  <div className='error resposError'>
                    <span className='error-span'>
                      <i className='fa-solid fa-circle-exclamation pe-1'></i>
                      {errors.artwork}
                    </span>
                  </div>
                ) : (
                  <div className='displayInstructions'>
                    {' '}
                    <p className=' text-dark'>
                      Image format: JPG, JPEG, PNG. Size greater than 1000x1000
                      px
                    </p>
                  </div>
                )}
              </div> */}
              <TextFieldWrapperDark
                id='name'
                name='name'
                label='Alias'
                className='w-100'
                //placeholder='Tell us Artist Name'
                //className='text-field mui-modal-input '
                value={alias}
                onChange={(e) => {
                  setAlias(e.target.value.replace(/^[0-9]/, ''));
                  FormValidator.handleOnBlur(e, setErrors);
                }}
                required
                onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                inputProps={{ maxLength: 20 }}
                size={width <= 375 ? 'small' : 'medium'}
              />
              {errors && errors.name && (
                <div className='error ps-2'>
                  <span className='error-span'>
                    <i className='fa-solid fa-circle-exclamation pe-1'></i>
                    {errors.name}
                  </span>
                </div>
              )}
              <TextFieldWrapperDark
                id='url2'
                name='url2'
                label='URL'
                className='w-100'
                //className='text-field mui-modal-input'
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value.trim());
                  FormValidator.handleOnBlur(e, setErrors);
                }}
                //placeholder='Artist Website'
                required
                onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                inputProps={{
                  maxLength: 500,
                }}
                size={width <= 375 ? 'small' : 'medium'}
              />
              {errors && errors.url2 && (
                <div className='error ps-2'>
                  <span className='error-span'>
                    <i className='fa-solid fa-circle-exclamation pe-1'></i>
                    {errors.url2}
                  </span>
                </div>
              )}
              <TextFieldWrapperDark
                id='email2'
                name='email2'
                label='E-Mail (optional)'
                defaultValue='E-Mail'
                //className='text-field mui-modal-input'
                className='w-100'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                  FormValidator.handleOnBlur(e, setErrors);
                }}
                //placeholder='E-Mail'
                onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                inputProps={{
                  maxLength: 128,
                }}
                size={width <= 375 ? 'small' : 'medium'}
              />
              {errors && errors.email2 && (
                <div className='error ps-2'>
                  <span className='error-span'>
                    <i className='fa-solid fa-circle-exclamation pe-1'></i>
                    {errors.email2}
                  </span>
                </div>
              )}
              <div style={{ zIndex: '999999', width: '100%', height: '100%' }}>
                {artworkPreview &&
                artworkPreview !== '' &&
                artworkPreview !== null ? (
                  !isUploadedArtSquare ? (
                    <>
                      <div className='d-flex justify-content-center w-100 align-items-center py-3'>
                        <div>
                          <ImageCropper
                            imageToCrop={artworkPreview}
                            onImageCropped={({ url, data }) => {
                              setArtworkPreview(data);
                            }}
                            setIsArtworkCroped={setIsArtworkCroped}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <div className='py-3'>
                          <img
                            src={artworkPreview}
                            alt='artwork preview'
                            className='artwork-preview mx-auto'
                            id='artwork-preview'
                            style={{
                              boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                              border: '1px solid rgba(0,0,0,0.35)',
                              borderRadius: '0.2rem',
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}
              </div>

              <div className='py-3 w-100'>
                <button
                  type='submit'
                  className='butn btn-f-small w-100 text-capitalize'
                  disabled={
                    isLoading
                      ? true
                      : errors.artwork && errors.artwork != ''
                      ? true
                      : false
                  }
                  id='submit'
                >
                  Save Artwork
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ArtworkPage;
