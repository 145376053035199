import React, { useState, useEffect } from 'react';
import { UserService } from '../../../services';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { Modal, Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import WalletIcon from '@mui/icons-material/Wallet';
import { Navigation } from 'swiper';
import { Images } from '../../../constants';
import { trustwallet } from '../../../utils/images';
import { Swiper, SwiperSlide } from 'swiper/react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { StorageService } from '../../../services';
import useScreen from '../../../hooks/useScreen';
import TooltipWrapper from '../../../components/TooltipWrapper';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const User = () => {
  const navigate = useNavigate();
  const { width } = useScreen();
  const [users, setUsers] = useState([]);
  const [rows, setRows] = useState([]);
  const [user, setUser] = useState({});
  const [role, setRole] = useState('');
  const [limit, setLimit] = useState(5);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [wallets, setWallets] = useState([]);
  const [openWallet, setOpenWallet] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [openUserInfo, toggleUserInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const displayWalletAdress = (wallet_address) => {
    let wallet_part_a = '',
      wallet_part_b = '';
    let wallet = '';
    for (let i = 0; i < wallet_address.length; i++) {
      if (i <= 6) {
        wallet_part_a += wallet_address[i];
      } else if (i >= wallet_address.length - 7) {
        wallet_part_b += wallet_address[i];
      }
    }
    wallet = wallet_part_a + '...' + wallet_part_b;
    return wallet;
  };

  const getAllUsers = () => {
    resetData();
    setIsLoading(true);
    UserService.getUsers(currentPage, limit)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          if (res.data) {
            setUsers(res.data);
            setFirstPage(res.pagenation.first_page);
            setLastPage(res.pagenation.last_page);
            setLimit(res.pagenation.limit);
            setTotalPages(
              Math.ceil(res.pagenation.total / res.pagenation.limit)
            );
            setTotalCount(res.pagenation.total);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === 'User account deactivated'
        ) {
          StorageService.clearSession();
          navigate('/admin/signin');
          return window.location.reload();
        }
      });
  };

  const columns = [
    { field: 'id', headerName: 'id', minWidth: 70 },
    { field: 'sr_no', headerName: 'Sr #', minWidth: 70, flex: 0.2 },
    {
      field: 'details',
      headerName: 'Details',
      minWidth: 120,
      flex: 0.4,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleViewAllDetails = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setUserDetails(params.value);
          toggleUserInfo(true);
        };

        return (
          <Button
            variant='text'
            className='mui-grid-btn mx-auto text-yellow'
            onClick={(e) => handleViewAllDetails(e)}
          >
            <FullscreenIcon className='text-yellow' />
          </Button>
        );
      },
    },
    {
      field: 'img',
      headerName: 'Profile',
      disableColumnMenu: true,
      sortable: false,
      minWidth: 100,
      flex: 0.4,
      renderCell: (params) => {
        return (
          <>
            {params.value && params.value !== '' ? (
              <Avatar
                alt={params.row.alias}
                src={`${process.env.REACT_APP_ASSET_URL}/${params.value}`}
                className='avaShadHov'
              />
            ) : (
              <Avatar className='avatar-text avaShadHov'>
                {params.row.alias[0] + params.row.alias[1]}
              </Avatar>
            )}
          </>
        );
      },
    },
    { field: 'alias', headerName: 'Alias', minWidth: 200, flex: 1 },
    { field: 'merge_requests', headerName: 'Merges', minWidth: 100, flex: 0.4 },
    { field: 'is_active', headerName: 'Active', minWidth: 100, flex: 0.4 },
    {
      field: 'wallets',
      headerName: 'Wallets',
      minWidth: 100,
      flex: 0.4,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const viewWallets = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setOpenWallet(true);
          setWallets(params.value);
        };

        return (
          <Button
            variant='text'
            className='mui-grid-btn mx-auto text-yellow'
            onClick={(e) => viewWallets(e)}
          >
            <WalletIcon className='text-yellow' />
          </Button>
        );
      },
    },
    {
      field: 'stauts',
      headerName: 'Change status',
      minWidth: 200,
      flex: 0.8,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const hanldeChangeStatus = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setIsLoading(true);
          UserService.changeStatus(params.id)
            .then((res) => {
              setIsLoading(false);
              if (res.status) {
                Swal.fire('success', res.message, 'success');
                resetForm();
                getAllUsers();
              }
            })
            .catch((error) => {
              setIsLoading(false);
              if (
                error.response &&
                error.response.data &&
                error.response.data.message === 'User account deactivated'
              ) {
                StorageService.clearSession();
                navigate('/admin/signin');
                return window.location.reload();
              } else if (error.response.data.message) {
                Swal.fire({
                  icon: 'error',
                  title: 'Error occurred!',
                  html: `<p>${error.response.data.message}</p>`,
                });
              }
            });
        };

        return (
          <>
            {params.value ? (
              <Button
                variant='text'
                className='mui-grid-btn mx-auto text-capitalize w-75px text-yellow'
                onClick={(e) => hanldeChangeStatus(e)}
              >
                Active
              </Button>
            ) : (
              <Button
                variant='text'
                className='mui-grid-btn-primary mx-auto text-capitalize w-75px'
                onClick={(e) => hanldeChangeStatus(e)}
              >
                Inactive
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const style = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '4rem',
    width: '90%',
    bgcolor: '#ffff00',
    boxShadow: 24,
    p: width > 500 ? 3 : 1.2,
    borderRadius: '15px',
    '& .MuiTextField-root': { my: 1, width: '25ch' },
  };

  const resetData = () => {
    setUsers([]);
    setFirstPage(1);
    // setCurrentPage(1);
    setLastPage(1);
  };

  const getAllUserRows = () => {
    if (users && users.length > 0) {
      setIsLoading(true);
      const allRows = users.map((user, index) => {
        return {
          id: user._id,
          sr_no: ++index + (currentPage - 1) * limit,
          img: user.imgUrl,
          alias: user.alias,
          is_active: user.is_active,
          merge_requests: user.no_of_merge_request,
          wallets: user.wallets,
          stauts: user.is_active,
          details: user,
        };
      });
      setRows(allRows);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUserRows();
  }, [users]);

  const resetForm = () => {
    setRole('');
    setUser({});
  };

  const hanldePagenation = (pagenate_type) => {
    if (pagenate_type === 'first') {
      setCurrentPage(firstPage);
    } else if (pagenate_type === 'last') {
      setCurrentPage(lastPage);
    } else if (pagenate_type === 'next') {
      // handle next
      setCurrentPage(currentPage + 1);
    } else if (pagenate_type === 'prev') {
      // handle previous
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [currentPage]);

  return (
    <>
      <div>
        <h1 className='text-center ad-dash-main-heading heading-level-2'>
          Users
        </h1>
      </div>

      <div>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableRowSelectionOnClick
          className='data-grid-border'
          sx={{
            borderColor: '#ffff00',
            backgroundColor: '#ffff00',
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          autoHeight={true}
          loading={isLoading}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
        <div className='d-flex justify-content-around align-items-center py-3'>
          {users.length > 0 ? (
            <div className='d-flex justify-content-center align-items-center'>
              <div>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('first')}
                  disabled={currentPage == firstPage}
                >
                  <i className='fa-solid fa-angles-left mui-pagenate-icon'></i>
                </Button>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('prev')}
                  disabled={currentPage == firstPage}
                >
                  <i className='fa-solid fa-chevron-left mui-pagenate-icon'></i>
                </Button>
              </div>
              <div>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('next')}
                  disabled={currentPage == lastPage}
                >
                  <i className='fa-solid fa-chevron-right mui-pagenate-icon'></i>
                </Button>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('last')}
                  disabled={currentPage == lastPage}
                >
                  <i className='fa-solid fa-angles-right mui-pagenate-icon'></i>
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className='p-0 m-0'>
          {currentPage && lastPage ? (
            <p className='theme-text-color text-center'>
              {currentPage} of {lastPage} Page
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* view wallets */}
      <div className=''>
        <Modal
          open={openWallet}
          onClose={() => {
            setOpenWallet(false);
            setWallets([]);
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-y-auto '
        >
          <Box sx={style} className=''>
            <div className='d-flex justify-content-between modal-header py-0 my-0'>
              <h3 className='modal-title'>View Wallets</h3>
              <button
                type='button'
                className='modal-butn'
                onClick={() => {
                  setOpenWallet(false);
                  setWallets([]);
                }}
              >
                x
              </button>
            </div>
            <div className='px-3 pb-3 pt-4'>
              <Swiper
                className='mySwiper custom-rounded'
                spaceBetween={50}
                slidesPerView={1}
                modules={[Navigation]}
                navigation={window.innerWidth > 375 ? true : false}
              >
                {wallets &&
                  wallets.length > 0 &&
                  wallets.map((wallet, index) => (
                    <SwiperSlide
                      className='nft-swipper d-flex flex-column justify-content-start  align-items-start modal-swiper'
                      key={index}
                      style={{
                        boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                        border: '1px solid rgba(0,0,0,0.35)',
                      }}
                    >
                      <img
                        src={
                          wallet.wallet_type === 'MetaMask'
                            ? Images.MetaMask
                            : wallet.wallet_type === 'Trust'
                            ? trustwallet
                            : Images.UnkownWallet
                        }
                        alt={wallet.wallet_type}
                        className='wallet-logo mx-auto w-50'
                      />
                      <div className='modal-swiper-text w-100'>
                        <h4 className='theme-text-clr userSwipHeading'>
                          {wallet.wallet_type}
                        </h4>
                        <span className='theme-text-clr userSwipWallet'>
                          {wallet.wallet_address}
                        </span>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </Box>
        </Modal>
      </div>

      {/*  view all the details of merge requests */}
      <div className=''>
        <Modal
          open={openUserInfo}
          onClose={(e) => toggleUserInfo(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-y-auto '
        >
          <Box sx={style} className=''>
            <div className='d-flex justify-content-between modal-header py-0 my-0'>
              <h3 className='modal-title'>User Details</h3>
              <button
                type='button'
                className='modal-butn'
                onClick={(e) => toggleUserInfo(false)}
              >
                x
              </button>
            </div>

            {Object.keys(userDetails).length > 0 && (
              <>
                <div className=' d-flex flex-column justify-content-center align-items-start pb-0 pt-3 fs-1_25rem word-break-all'>
                  <div className='p-0 m-0  w-100'>
                    {userDetails.imgUrl && userDetails.imgUrl !== '' ? (
                      <div className='w-100 py-2 '>
                        <Avatar
                          alt={userDetails.alias}
                          src={
                            process.env.REACT_APP_ASSET_URL + userDetails.imgUrl
                          }
                          style={{ width: '100px', height: '100px' }}
                          className='avaShadHov'
                        />
                      </div>
                    ) : (
                      <div className=' py-3'>
                        <Avatar
                          className='avatar-text avaShadHov'
                          style={{ width: '100px', height: '100px' }}
                        >
                          {userDetails.alias[0] + userDetails.alias[1]}
                        </Avatar>
                      </div>
                    )}
                    <h2 className=' text-dark p-0 m-0 text-level-3'>
                      Alias:{' '}
                      <span className='fw_500 text-capitalize text-level-4'>
                        {userDetails.alias}
                      </span>
                    </h2>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Email:{' '}
                      <span className='fw_500 text-break text-level-4'>
                        {userDetails.email && userDetails.email !== ''
                          ? userDetails.email
                          : 'No email'}
                      </span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Role:{' '}
                      <span className='fw_500 text-capitalize text-level-4'>
                        {userDetails.role}
                      </span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Website:{' '}
                      {userDetails.website.trim() &&
                      userDetails.website.trim() !== '' ? (
                        <span className='fw_500 text-level-4'>
                          {' '}
                          <a
                            className='text-blue text-level-4'
                            href={userDetails.website}
                            target='_blank'
                            style={{ color: 'var(--second)' }}
                          >
                            <i>
                              <u> {'Link'} </u>
                            </i>
                          </a>
                        </span>
                      ) : (
                        <span className='fw_500 text-level-4'>No Url</span>
                      )}
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Email Notification:{' '}
                      <span className='fw_500 text-level-4'>
                        {userDetails.is_email_notification_enabled
                          ? 'Enable'
                          : 'Disable'}
                      </span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Total Merge Requests:{' '}
                      <span className='fw_500 text-level-4'>
                        {userDetails.no_of_merge_request}
                      </span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Account Status:{' '}
                      <span className='fw_500 text-level-4'>
                        {userDetails.is_active ? 'Active' : 'Inactive'}
                      </span>
                    </p>
                    <h3 className='text-dark p-0 m-0 text-level-3'>
                      Personal Wallets
                    </h3>
                    <ul className='text-level-3 text-level-4 w-100'>
                      {userDetails.wallets.length > 0 ? (
                        userDetails.wallets.map((personalWallet, index) => (
                          <li key={index} style={{ overflowWrap: 'anywhere' }}>
                            {/* <li
                              key={index}
                              style={{ overflowWrap: 'anywhere' }}
                            >
                              {personalWallet.wallet_type +
                                ', ' +
                                personalWallet.wallet_address}
                            </li> */}
                            <text className='sm-d-none text-level-4'>
                              {personalWallet.wallet_type +
                                ', ' +
                                personalWallet.wallet_address}
                            </text>
                            <TooltipWrapper
                              title={
                                personalWallet.wallet_type +
                                ', ' +
                                personalWallet.wallet_address
                              }
                            >
                              <text className={`d-none sm-d-block text-level-4`}>
                                {displayWalletAdress(
                                  personalWallet.wallet_type +
                                    ', ' +
                                    personalWallet.wallet_address
                                )}
                              </text>
                            </TooltipWrapper>
                          </li>
                        ))
                      ) : (
                        <>
                          <li style={{ overflowWrap: 'anywhere text-level-4' }}>
                            No wallet selected from the address book
                          </li>
                        </>
                      )}
                    </ul>
                    <h3 className='text-dark p-0 m-0 text-level-3'>
                      Friend's Wallets From Address Book
                    </h3>
                    <div className='d-flex flex-cloumn frame w-100 mt-2'>
                      <ul className='text-level-3 frame text-level-4 w-100'>
                        {userDetails.address_book.length > 0 ? (
                          userDetails.address_book.map(
                            (addressBookWallet, index) => (
                              <li
                                key={index}
                                style={{ overflowWrap: 'anywhere' }}
                              >
                                {/* <li
                              key={index}
                              style={{ overflowWrap: 'anywhere' }}
                              className='text-capitalize'
                            >
                              {addressBookWallet.alias +
                                ', ' +
                                addressBookWallet.wallet_address}
                            </li> */}
                                <text className='sm-d-none text-level-4 w-100'>
                                  {addressBookWallet.alias +
                                    ', ' +
                                    addressBookWallet.wallet_address}
                                </text>
                                <TooltipWrapper
                                  title={
                                    addressBookWallet.alias +
                                    ', ' +
                                    addressBookWallet.wallet_address
                                  }
                                >
                                  <text className={`d-none sm-d-block text-level-4`}>
                                    {displayWalletAdress(
                                      addressBookWallet.alias +
                                        ', ' +
                                        addressBookWallet.wallet_address
                                    )}
                                  </text>
                                </TooltipWrapper>
                              </li>
                            )
                          )
                        ) : (
                          <div
                            style={{ overflowWrap: 'anywhere' }}
                            className='text-capitalize text-gray text-level-6'
                          >
                            No Wallets save in Address Book
                          </div>
                        )}
                      </ul>
                    </div>
                    {/* <h3 className='text-dark p-0 m-0'>Profile Image</h3>
                    {userDetails.imgUrl && userDetails.imgUrl !== '' ? (
                      <div className='w-100 py-2'>
                        <img
                          src={
                            process.env.REACT_APP_ASSET_URL + userDetails.imgUrl
                          }
                          alt='profile image'
                          className='w-25'
                        />
                      </div>
                    ) : (
                      <div>No profile Image</div>
                    )} */}
                  </div>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default User;
