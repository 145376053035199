import React from 'react'

const AdminFooterComponent = () => {
  return (
    <footer className='admin-footer fixed-bottom'>
      &copy; Vintage Neighbors. All Rights Reserved.
    </footer>
  )
}

export default AdminFooterComponent