import React, { useEffect, useState } from 'react';
import 'swiper/css';
import { PreLoaderComponent2, SwiperComponent } from '../../components';
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import { LatestComponents, TrendingComponents } from './components';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { UserService, StorageService, AuthService } from '../../services';
import Swal from 'sweetalert2';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [nftType, setNftType] = useState('Trending');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location.state !== null) {
      if (location.state && location.state.tabs == 0) {
        setValue(0);
        window.scrollTo(0, 0);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      setIsLoading(true);
      UserService.checkUserActiveStatus()
        .then((res) => {
          setIsLoading(false);
          if (res.status === false) {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/');
              return window.location.reload();
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === 'User account deactivated'
          ) {
            setIsLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/');
              return window.location.reload();
            });
          }
        });
    }
  }, []);

  return (
    <>
      <div className='text-center'>
        <h1 className='theme-text-color heading-level-2 letter-space-2 line-hight-12'>
          Welcome, GM - Merge NFTs
        </h1>
      </div>

      <SwiperComponent nftType={nftType} />

      <div className='mx-auto'>
        <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { background: '#FFFF00' } }}
            inkbarstyle={{ background: '#FFFF00' }}
            centered
            aria-label='nfts-tab'
            className='fs-1_5rem pb-3'
          >
            <Tab
              label='Trending'
              onClick={() => setNftType('Trending')}
              {...a11yProps(0)}
              className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                value === 0 ? 'fw_bolder' : ''
              }`}
            />
            <Tab
              label='Latest'
              onClick={() => setNftType('Latest')}
              {...a11yProps(1)}
              className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                value === 1 ? 'fw_bolder' : ''
              }`}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <TrendingComponents />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LatestComponents />
        </TabPanel>
      </div>
    </>
  );
};

export default HomePage;
