import * as React from 'react';

import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import useScreen from '../hooks/useScreen';

const Input = styled(TextField)(({ theme }) => {
  const { width } = useScreen();
  return {
    '& label': {
      color: 'yellow !important',
      backgroundColor: '#1b1d21 !important',
      padding: '0px 8px !important',
      fontSize: '1rem !important',
      top:
        width > 1600
          ? '2px !important'
          : width < 1600 && width > 500
          ? '1px !important'
          : '-1px !important',
    },
    '& .Mui-error fieldset': {
      borderColor: '#f50057 !important',
    },
    '& .MuiInputBase-root': {
      width: '100% !important',
      padding: '2px 20px !important',
      /*   boxShadow: '0 0 0 0.1rem var(--yellow) !important', */
      borderRadius: '1rem !important',
      '& i': { color: 'yellow' },
      '&.MuiInputBase-root fieldset': {
        border: '2px solid var(--yellow) !important',
      },
      '& input::placeholder': {
        fontSize: '1.2rem !important',
      },
      '&.Mui-focused fieldset': {
        border: '0.3px solid var(--yellow) !important',
        boxShadow: '0 0 10px 2px rgba(255, 255, 0, 0.8) !important',
      },
      '&:hover fieldset': {
        borderColor: 'red !imortant',
      },
    },
    '& .MuiInputBase-input': {
      '::placeholder': { color: 'yellow !important' },
      position: 'relative !important',
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2b2b2b',
      border: 'none !important',
      paddingLeft: '4px !important',
      fontSize: '100% !important',
      //fontZize: '1rem',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    /*  '& .MuiInputBase-root i': {}, */
    '& textarea': {
      position: 'relative !important',
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2b2b2b',
      border: 'none !important',
      paddingLeft: '4px !important',
      fontSize: '100% !important',
      padding: '10px 0px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  };
});

const TextFieldWrapperSearch = (props) => {
  const { width } = useScreen();
  return (
    <Input
      {...props}
      size={
        width > 1600
          ? 'large'
          : width < 1600 && width > 500
          ? 'medium'
          : 'small'
      }
    />
  );
};

export default TextFieldWrapperSearch;
