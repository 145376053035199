import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Nfts, PersonalMerges } from './components';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import { DashboardService } from '../../services';
import { mergersIcon, Rank2 } from '../../utils/images';
import { StorageService, UserService } from '../../services';
import Swal from 'sweetalert2';
import { PreLoaderComponent2 } from '../../components';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DashboardPage = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [mergedTotal, setMergedTotal] = useState(0);
  const [rank, setRank] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getDashboardData = () => {
    DashboardService.getDashboardInfo()
      .then((res) => {
        if (res.status) {
          if (res.data.total_merge) {
            setMergedTotal(res.data.total_merge);
          }
          if (res.data.profile_rank) {
            setRank(res.data.profile_rank);
          }
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: `<p>${error.response.data.message}</p>`,
            });
          }
        }
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      navigate('/connect-to-wallet');
    }
    getDashboardData();
  }, []);

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      setIsLoading(true);
      UserService.checkUserActiveStatus()
        .then((res) => {
          setIsLoading(false);
          if (res.status === false) {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === 'User account deactivated'
          ) {
            setIsLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        });
    }
  }, []);

  return (
    <>
      {/* {
       isLoading && <PreLoaderComponent2 />
    } */}
      <section className='p-4'>
        <div className='d-flex flex-xs-column justify-content-start align-items-center py-2'>
          <div className='dash-counter d-flex justify-content-start align-items-center w-50 pt-2'>
            <img src={mergersIcon} className=' dash-icon' />
            <div className='d-flex flex-column justify-content-center align-items-start ps-2'>
              <h3>Mergers total</h3>
              <span className='theme-text-color'>{mergedTotal}</span>
            </div>
          </div>
          <div className='dash-counter d-flex justify-content-start align-items-center w-50 h-100 pt-2'>
            <img src={Rank2} className=' dash-icon' />
            <div className='d-flex flex-column justify-content-center align-items-start ps-2'>
              <h3>Rank</h3>
              <span className='theme-text-color'>#{rank}</span>
            </div>
          </div>
        </div>
        <div className='mx-auto'>
          <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{ style: { background: '#FFFF00' } }}
              inkbarstyle={{ background: '#FFFF00' }}
              centered
              aria-label='nfts-tab'
              className='fs-1_5rem'
            >
              <Tab
                label='Personal merges'
                {...a11yProps(0)}
                className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                  value === 0 ? 'fw_bolder' : ''
                }`}
              />
              <Tab
                label='NFTs'
                {...a11yProps(1)}
                className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                  value === 1 ? 'fw_bolder' : ''
                }`}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <PersonalMerges />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Nfts />
          </TabPanel>
        </div>
        <div className='fixed-bottom px-3 py-3 w-100 bg-dark border-top'>
          <button
            type='button'
            className='butn butn-primary w-100'
            onClick={() => navigate('/create-merge-request')}
          >
            Create New Merger
          </button>
        </div>
      </section>
    </>
  );
};

export default DashboardPage;
