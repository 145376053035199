import React, { useState } from 'react';
import { TextField, Box } from '@mui/material';
import { UserService } from '../../../services/admin';
import { StorageService } from '../../../services';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import FormValidator from '../../../utils/validation';
import { PreLoaderComponent } from '../../../components';
import useScreen from '../../../hooks/useScreen';
import TextFieldWrapper from '../../../components/TextFieldWrapper';

const AddUser = () => {
  const navigate = useNavigate();
  const { width } = useScreen();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setPassword('');
    setEmail('');
    setName('');
  };

  const submitHandler = (e) => {
    // e.preventDefault();

    FormValidator.handleOnSubmit('name', setErrors);
    FormValidator.handleOnSubmit('email', setErrors);
    FormValidator.handleOnSubmit('password', setErrors);

    // if (name === "") {
    //   let name_field = document.getElementById("name");
    //   return name_field.focus();
    // } else if (email === "") {
    //   let email_field = document.getElementById("email");
    //   return email_field.focus();
    // } else if (password === "") {
    //   let password_field = document.getElementById("password");
    //   return password_field.focus();
    // }
    // return storeNewAdmin(e)
  };

  const storeNewAdmin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let noOfErrors = 0;
    let firstKey;

    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      setIsLoading(false);
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      const data = {
        name: name.trim(),
        email: email.trim(),
        password: password.trim(),
      };

      UserService.addUser(data)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            Swal.fire('success', res.message, 'success');
            resetForm();
            return navigate('/admin/admin-users');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message === 'User account deactivated'
            ) {
              StorageService.clearSession();
              return navigate('/admin/signin');
            } else if (error.response.data.message) {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: `<p>${error.response.data.message}</p>`,
              });
            }
          }
        });
    }
  };

  return (
    <>
      <div className=' py-2 d-flex justify-content-center align-items-center w-100 min-h-100vh'>
        {isLoading ? (
          <PreLoaderComponent />
        ) : (
          <Box sx={{ width: '100%' }} className='' autoComplete='off'>
            <form
              className='px-4 w-100 h-100'
              // encType="multipart/form-data"
              onSubmit={(e) => storeNewAdmin(e)}
              autoComplete='off'
              noValidate
            >
              <h1 className='text-center text-yellow pb-3 fx-sh heading-level-2'>
                Add new Admin
              </h1>
              <div className=' theme-text-color formating py-3 '>
                <div className='theme-text-color'>
                  <TextFieldWrapper
                    fullWidth
                    className='wallet-select-field selected-text description-text'
                    onChange={(e) => {
                      setName(e.target.value.replace(/^[0-9]/, ''));
                      FormValidator.handleOnBlur(e, setErrors);
                    }}
                    value={name}
                    label='Name'
                    type='text'
                    // placeholder="Alias Name"
                    required
                    id='name'
                    onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                    // name="new-name"
                    // inputProps={{
                    //   minLength: 2,
                    //   maxLength: 20,
                    //   autocomplete: "new-name",
                    //   form: {
                    //     autocomplete: "off",
                    //   },
                    // }}
                    inputProps={{
                      maxLength: 20,
                      autoComplete: 'new-name',
                      form: {
                        autoComplete: 'off',
                      },
                    }}
                    size={width <= 320 ? 'small' : 'medium'}
                  />
                  {errors && errors.name && (
                    <div className='error ps-2'>
                      <span className='error-span mt-0 pb-0'>
                        <i className='fa-solid fa-circle-exclamation pe-1'></i>
                        {errors.name}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className=' theme-text-color formating py-3 '>
                <div className='theme-text-color'>
                  <TextFieldWrapper
                    fullWidth
                    className='wallet-select-field selected-text description-text'
                    onChange={(e) => {
                      setEmail(e.target.value);
                      FormValidator.handleOnBlur(e, setErrors);
                    }}
                    value={email}
                    label='Email'
                    type='email'
                    id='email'
                    // name="new-email"
                    // placeholder="Email"
                    required
                    onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                    inputProps={{
                      autoComplete: 'new-email',
                      form: {
                        autoComplete: 'off',
                      },
                      maxLength: 128,
                    }}
                    size={width <= 320 ? 'small' : 'medium'}
                  />
                  {errors && errors.email && (
                    <div className='error ps-2'>
                      <span className='error-span mt-0 pb-0'>
                        <i className='fa-solid fa-circle-exclamation pe-1'></i>
                        {errors.email}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className=' theme-text-color formating py-3 '>
                <div className='theme-text-color'>
                  <TextFieldWrapper
                    fullWidth
                    className='wallet-select-field selected-text description-text'
                    onChange={(e) => {
                      setPassword(e.target.value);
                      FormValidator.handleOnBlur(e, setErrors);
                    }}
                    value={password}
                    label='Password'
                    type='password'
                    id='password'
                    // placeholder="Password"
                    required
                    name='new-password'
                    onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                    // autoComplete="off"
                    inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autoComplete: 'off',
                      },
                      maxLength: 12,
                    }}
                    size={width <= 320 ? 'small' : 'medium'}
                  />
                  {errors && errors.password && (
                    <div className='error ps-2'>
                      <span className='error-span mt-0 pb-0'>
                        <i className='fa-solid fa-circle-exclamation pe-1'></i>
                        {errors.password}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className=' w-100 py-3'>
                <button
                  type='submit'
                  className='butn butn-lg-primary w-100'
                  disabled={isLoading}
                  onClick={(e) => submitHandler(e)}
                >
                  Submit
                </button>
              </div>
            </form>
          </Box>
        )}
      </div>
    </>
  );
};

export default AddUser;
