import API from "../config/api.config";

const getDashboardInfo = () => {
  return API.get("/user/dashboard").then((res) => {
    return res.data;
  });
};

const DashboardService = {
  getDashboardInfo,
};

export default DashboardService;
