import {
  AdminLayout,
  AuthLayout,
  MainLayout,
  walletLayout,
  NofoundLayout,
} from '../layouts';
import {
  HomePage,
  ArtworkPage,
  SettingDashboard,
  WalletsDashboard,
} from '../pages';
import {
  ConnectToWallets,
  DashboardPage,
  CreateMerger,
  PageNotFound,
} from '../pages';
import {
  AddUser,
  AdminUsers,
  Artworks,
  ChangePassword,
  Dashboard,
  Merge,
  UpdateProfile,
  User,
} from '../pages/dashboard/admin';
import {
  ForgotPassword,
  SigninDashboard,
  Verification,
} from '../pages/dashboard/auth';

export const AppRoutes = [
  {
    Component: MainLayout,
    children: [
      { path: '/', Component: HomePage },
      { path: '/upload-artwork', Component: ArtworkPage },
      { path: '/dashboard', Component: DashboardPage },
      { path: '/create-merge-request', Component: CreateMerger },
      { path: '/settings', Component: SettingDashboard },
      { path: '/wallets', Component: WalletsDashboard },
    ],
  },
  {
    Component: walletLayout,
    children: [{ path: '/connect-to-wallet', Component: ConnectToWallets }],
  },
  {
    Component: AdminLayout,
    children: [
      { path: '/admin', Component: Dashboard },
      { path: '/admin/add-user', Component: AddUser },
      { path: '/admin/update-profile', Component: UpdateProfile },
      { path: '/admin/change-password', Component: ChangePassword },
      { path: '/admin/merge-requests', Component: Merge },
      { path: '/admin/artworks', Component: Artworks },
      { path: '/admin/users', Component: User },
      { path: '/admin/admin-users', Component: AdminUsers },
    ],
  },
  {
    Component: AuthLayout,
    children: [
      { path: '/admin/signin', Component: SigninDashboard },
      { path: '/admin/verify-account', Component: Verification },
      { path: '/admin/forgot-password', Component: ForgotPassword },
    ],
  },
  {
    Component: NofoundLayout,
    children: [{ path: '*', Component: PageNotFound }],
  },
];
