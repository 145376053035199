import API from '../config/api.config';
import StorageService from './storage.service';
import axios from 'axios';

const token = StorageService.getToken() ? StorageService.getToken() : '';

const storeArtWork = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios
    .post(`${process.env.REACT_APP_API_URL}artwork/store-artwork`, data, config)
    .then((res) => {
      return res.data;
    });
};

const getArtWorks = (current_page = 1, limit = 10) => {
  return API.get(`/artwork/get-artworks/${current_page}/${limit}`).then(
    (res) => {
      return res.data;
    }
  );
};

const getArtWork = (artwork_id) => {
  return API.get(`/artwork/get-artwork/${artwork_id}`).then((res) => {
    return res.data;
  });
};

const ArtworkService = {
  getArtWork,
  storeArtWork,
  getArtWorks,
};

export default ArtworkService;
