import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthService, StorageService } from '../services';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const AuthLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Swal.close();
  }, [location]);

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      if (StorageService.getCurrentUser().role === 'admin') {
        navigate('/admin');
      } else {
        navigate('/dashboard');
      }
    }
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default AuthLayout;
