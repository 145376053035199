import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { DashboardService, StorageService } from '../../../services';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import useScreen from '../../../hooks/useScreen';

const Dashboard = () => {
  const navigate = useNavigate();
  const { width } = useScreen();
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalMergeRequests, setTotalMergeRequests] = useState(0);
  const [totalMergedNfts, setTotalMergedNfts] = useState(0);
  const [totalArtworks, setTotalArtworks] = useState(0);

  const getDashboardData = async () => {
    DashboardService.getDashboardInfo()
      .then((res) => {
        if (res?.status) {
          if (res?.data?.total_merge_requests) {
            setTotalMergeRequests(res.data.total_merge_requests);
          }
          if (res?.data?.total_merged_nfts) {
            setTotalMergedNfts(res?.data?.total_merged_nfts);
          }
          if (res.data.total_users) {
            setTotalUsers(res?.data?.total_users);
          }
          if (res?.data?.total_artworks) {
            setTotalArtworks(res?.data?.total_artworks);
          }
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === 'User account deactivated'
        ) {
          StorageService.clearSession();
          navigate('/admin/signin');
          return window.location.reload();
        } else if (err?.response?.data?.message === 'Account is inactive') {
          StorageService.clearSession();
          //resetForm();
          return navigate('/admin/signin');
        }
      });
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div style={{ maxHeight: '85vh', overflowY: 'auto' }}>
      <div>
        <h1 className='text-center ad-dash-main-heading heading-level-2'>
          Dashboard
        </h1>
      </div>
      {/* swiper for dashboard */}
      <div style={{ marginBottom: '4rem' }}>
        {width > 430 && (
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <SwiperSlide className='swipper-item '>
                <div
                  className={`d-flex flex-column bg-transparent justify-content-around my-4 cursor-pointer`}
                  onClick={() => navigate('merge-requests')}
                >
                  <Avatar
                    sx={{
                      backgroundColor: '#36373f',
                      color: '#ffff00',
                      width: '72px',
                      height: '72px',
                    }}
                    className='mx-auto my-2 countShadHov'
                  >
                    {totalMergeRequests}
                  </Avatar>
                  <h3 className='swipper-title text-level-1'>Merge Requests</h3>
                </div>
              </SwiperSlide>
            </Grid>
            <Grid item sm={6} xs={12}>
              <SwiperSlide className='swipper-item '>
                <div
                  className=' d-flex flex-column bg-transparent justify-content-around my-4  cursor-pointer'
                  onClick={() =>
                    navigate('merge-requests', {
                      state: { data: 'mergedNfts' },
                    })
                  }
                >
                  <Avatar
                    sx={{
                      backgroundColor: '#36373f',
                      color: '#ffff00',
                      width: '72px',
                      height: '72px',
                    }}
                    className='mx-auto my-2 countShadHov'
                  >
                    {totalMergedNfts}
                  </Avatar>
                  <h3 className='swipper-title'>Merged NFTs</h3>
                </div>
              </SwiperSlide>
            </Grid>
            <Grid item sm={6} xs={12}>
              <SwiperSlide className='swipper-item '>
                <div
                  className=' d-flex flex-column bg-transparent justify-content-around my-4  cursor-pointer'
                  onClick={() => navigate('users')}
                >
                  <Avatar
                    sx={{
                      backgroundColor: '#36373f',
                      color: '#ffff00',
                      width: '72px',
                      height: '72px',
                    }}
                    className='mx-auto my-2 countShadHov'
                  >
                    {totalUsers}
                  </Avatar>
                  <h3 className='swipper-title'>Users</h3>
                </div>
              </SwiperSlide>
            </Grid>
            <Grid item sm={6} xs={12}>
              <SwiperSlide className='swipper-item '>
                <div
                  className=' d-flex flex-column bg-transparent justify-content-around my-4  cursor-pointer'
                  onClick={() => navigate('artworks')}
                >
                  <Avatar
                    sx={{
                      backgroundColor: '#36373f',
                      color: '#ffff00',
                      width: '72px',
                      height: '72px',
                    }}
                    className='mx-auto my-2 countShadHov '
                  >
                    {totalArtworks}
                  </Avatar>
                  <h3 className='swipper-title'>Artwork</h3>
                </div>
              </SwiperSlide>
            </Grid>
          </Grid>
        )}
        {width <= 430 && (
          <Swiper
            className='mySwiper custom-rounded p-3 artwork-swipper '
            spaceBetween={50}
            slidesPerView={1}
            navigation={true}
            modules={[Navigation]}
          >
            <SwiperSlide className='swipper-item '>
              <div
                className=' d-flex flex-column bg-transparent justify-content-around my-4 cursor-pointer'
                onClick={() => navigate('merge-requests')}
              >
                <Avatar
                  sx={{
                    backgroundColor: '#36373f',
                    color: '#ffff00',
                    width: '72px',
                    height: '72px',
                  }}
                  className='mx-auto my-2 countShadHov'
                >
                  {totalMergeRequests}
                </Avatar>
                <h3 className='swipper-title text-level-1'>Merge Requests</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className='swipper-item '>
              <div
                className=' d-flex flex-column bg-transparent justify-content-around my-4  cursor-pointer'
                onClick={() =>
                  navigate('merge-requests', { state: { data: 'mergedNfts' } })
                }
              >
                <Avatar
                  sx={{
                    backgroundColor: '#36373f',
                    color: '#ffff00',
                    width: '72px',
                    height: '72px',
                  }}
                  className='mx-auto my-2 countShadHov'
                >
                  {totalMergedNfts}
                </Avatar>
                <h3 className='swipper-title'>Merged NFTs</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className='swipper-item '>
              <div
                className=' d-flex flex-column bg-transparent justify-content-around my-4  cursor-pointer'
                onClick={() => navigate('users')}
              >
                <Avatar
                  sx={{
                    backgroundColor: '#36373f',
                    color: '#ffff00',
                    width: '72px',
                    height: '72px',
                  }}
                  className='mx-auto my-2 countShadHov'
                >
                  {totalUsers}
                </Avatar>
                <h3 className='swipper-title'>Users</h3>
              </div>
            </SwiperSlide>
            <SwiperSlide className='swipper-item '>
              <div
                className=' d-flex flex-column bg-transparent justify-content-around my-4  cursor-pointer'
                onClick={() => navigate('artworks')}
              >
                <Avatar
                  sx={{
                    backgroundColor: '#36373f',
                    color: '#ffff00',
                    width: '72px',
                    height: '72px',
                  }}
                  className='mx-auto my-2 countShadHov '
                >
                  {totalArtworks}
                </Avatar>
                <h3 className='swipper-title'>Artwork</h3>
              </div>
            </SwiperSlide>
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
