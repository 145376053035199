import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { Modal, Box, Button, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UserService } from '../../../services/admin';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { StorageService } from '../../../services';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AdminUsers = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(5);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [userDetails, setUserDetails] = useState({});
  const [openUserInfo, toggleUserInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { field: 'id', headerName: 'id', minWidth: 70 },
    { field: 'sr_no', headerName: 'Sr #', minWidth: 70, flex: 0.2 },
    {
      field: 'details',
      headerName: 'Details',
      minWidth: 120,
      flex: 0.4,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleViewAllDetails = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setUserDetails(params.value);
          toggleUserInfo(true);
        };

        return (
          <Button
            variant='text'
            className='mui-grid-btn mx-auto text-yellow'
            onClick={(e) => handleViewAllDetails(e)}
          >
            <FullscreenIcon />
          </Button>
        );
      },
    },
    { field: 'email', headerName: 'Email', minWidth: 300, flex: 1 },
    { field: 'name', headerName: 'Name', minWidth: 150, flex: 1 },
    { field: 'role', headerName: 'Role', minWidth: 100, flex: 0.3 },
    {
      field: 'verification_code',
      headerName: 'Verfication Code',
      minWidth: 150,
      flex: 0.5,
    },
    { field: 'is_verified', headerName: 'Verified', minWidth: 130, flex: 0.4 },
    {
      field: 'resend_verification',
      headerName: 'Resend Verification Code',
      minWidth: 240,
      flex: 0.7,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const resendVerification = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setIsLoading(true);
          UserService.resendVerification(params.value)
            .then((res) => {
              setIsLoading(false);
              if (res.status) {
                Swal.fire('success', res.message, 'success');
                getUsers();
              }
            })
            .catch((error) => {
              setIsLoading(false);
              //handleClose();
              if (
                error.response &&
                error.response.data &&
                error.response.data.message === 'User account deactivated'
              ) {
                Swal.fire({
                  icon: 'warning',
                  title: 'Inactive account!',
                  html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
                }).then(() => {
                  StorageService.clearSession();
                  navigate('/admin/signin');
                  return window.location.reload();
                });
              } else if (error.response.data.message) {
                Swal.fire({
                  icon: 'error',
                  title: 'Error occurred',
                  html: `<p>${error.response.data.message}</p>`,
                });
              }
            });
        };

        return (
          <Button
            variant='text'
            className='mui-grid-btn mx-auto text-yellow'
            onClick={(e) => resendVerification(e)}
            disabled={params.row.is_verified}
          >
            <iconify-icon
              icon='mdi:email-resend-outline'
              style={{ fontSize: '1.75rem' }}
              className='butn-icon text-yellow'
            ></iconify-icon>
          </Button>
        );
      },
    },
    // {
    //   field: "is_active",
    //   headerName: "Active",
    //   minWidth: 160,
    //   headerAlign: "center",
    //   disableColumnMenu: true,
    //   sortable: false,
    //   renderCell: (params) => {
    //     const handleChangeStatus = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking
    //       setIsLoading(true);
    //       UserService.cahngeStatus(params.id)
    //         .then((res) => {
    //           setIsLoading(false);
    //           if (res.status) {
    //             Swal.fire("success", res.message, "success");
    //             getUsers();
    //           }
    //         })
    //         .catch((error) => {
    //           setIsLoading(false);
    //           if (
    //             err.response &&
    //             err.response.data &&
    //             err.response.data.message === "User account deactivated"
    //           ) {
    //             StorageService.clearSession();
    //             return navigate("/admin/signin");
    //           } else if (error.response.data.message) {
    //             Swal.fire({
    //               icon: "error",
    //               title: "Error occurred!",
    //               html: `<p>${error.response.data.message}</p>`,
    //             });
    //           }
    //         });
    //     };

    //     return (
    //       <>
    //         {params.value ? (
    //           <Button
    //             variant="text"
    //             className="mui-grid-btn mx-auto text-capitalize w-75px text-yellow"
    //             onClick={(e) => handleChangeStatus(e)}
    //           >
    //             Active
    //           </Button>
    //         ) : (
    //           <Button
    //             variant="text"
    //             className="mui-grid-btn-primary mx-auto text-capitalize w-75px"
    //             onClick={(e) => handleChangeStatus(e)}
    //             disabled={!params.row.is_verified}
    //           >
    //             inactive
    //           </Button>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  const style = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '4rem',
    width: '90%',
    bgcolor: '#ffff00',
    boxShadow: 24,
    p: 3,
    borderRadius: '15px',
    '& .MuiTextField-root': { my: 1, width: '25ch' },
  };

  const resetData = () => {
    setUsers([]);
    setFirstPage(1);
    // setCurrentPage(1);
    setLastPage(1);
  };

  const getUsers = () => {
    resetData();
    setIsLoading(true);
    UserService.getUsers(currentPage, limit)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          if (res.data) {
            setUsers(res.data);
            setFirstPage(res.pagenation.first_page);
            setLastPage(res.pagenation.last_page);
            setLimit(res.pagenation.limit);
            setTotalPages(
              Math.ceil(res.pagenation.total / res.pagenation.limit)
            );
            setTotalCount(res.pagenation.total);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === 'User account deactivated'
        ) {
          StorageService.clearSession();
          navigate('/admin/signin');
          return window.location.reload();
        }
      });
  };

  const getUserRows = () => {
    if (users && users.length > 0) {
      setIsLoading(true);
      const allRows = users.map((admin_user, index) => {
        return {
          id: admin_user._id,
          sr_no: ++index + (currentPage - 1) * limit,
          details: admin_user,
          email: admin_user.email,
          name: admin_user.name,
          role: admin_user.role,
          verification_code: admin_user.verification_code,
          is_verified: admin_user.is_verified,
          resend_verification: admin_user.email,
          is_active: admin_user.is_active,
        };
      });
      setRows(allRows);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserRows();
  }, [users]);

  const hanldePagenation = (pagenate_type) => {
    if (pagenate_type === 'first') {
      setCurrentPage(firstPage);
    } else if (pagenate_type === 'last') {
      setCurrentPage(lastPage);
    } else if (pagenate_type === 'next') {
      // handle next
      setCurrentPage(currentPage + 1);
    } else if (pagenate_type === 'prev') {
      // handle previous
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    getUsers();
  }, [currentPage]);

  return (
    <>
      <div className='d-flex xsm-d-col justify-content-between align-items-center py-2'>
        <h1 className='text-center ad-dash-main-heading heading-level-2'>
          Admins
        </h1>
        <Link
          to='/admin/add-user'
          className='shadow-xl btn btn-sm btn-warning theme-bg text-dark fw-bold border-0 pt-0 fs-lg'
        >
          Add New
        </Link>
      </div>

      <div>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          className='data-grid-border'
          sx={{
            borderColor: '#ffff00',
            backgroundColor: '#ffff00',
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          autoHeight={true}
          loading={isLoading}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
        <div className='d-flex justify-content-around align-items-center py-3'>
          {users.length > 0 && (
            <div className='d-flex justify-content-center align-items-center'>
              <div>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('first')}
                  disabled={currentPage == firstPage}
                >
                  <i className='fa-solid fa-angles-left mui-pagenate-icon'></i>
                </Button>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('prev')}
                  disabled={currentPage == firstPage}
                >
                  <i className='fa-solid fa-chevron-left mui-pagenate-icon'></i>
                </Button>
              </div>
              <div>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('next')}
                  disabled={currentPage == lastPage}
                >
                  <i className='fa-solid fa-chevron-right mui-pagenate-icon'></i>
                </Button>
                <Button
                  className='mui-icon-btn'
                  variant='text'
                  onClick={() => hanldePagenation('last')}
                  disabled={currentPage == lastPage}
                >
                  <i className='fa-solid fa-angles-right mui-pagenate-icon'></i>
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className='p-0 m-0'>
          {currentPage && lastPage ? (
            <p className='theme-text-color text-center'>
              {currentPage} of {lastPage} Page
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/*  view all the details of merge requests */}
      <div className=''>
        <Modal
          open={openUserInfo}
          onClose={(e) => toggleUserInfo(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-y-auto '
        >
          <Box sx={style} className=''>
            <div className='d-flex justify-content-between modal-header py-0 my-0'>
              <h3 className='modal-title'>Admin Details</h3>
              <button
                type='button'
                className='modal-butn'
                onClick={(e) => toggleUserInfo(false)}
              >
                x
              </button>
            </div>
            {Object.keys(userDetails).length > 0 && (
              <>
                <div className=' d-flex flex-column justify-content-center align-items-start pb-0 pt-5 fs-1_25rem '>
                  <div className='p-0 m-0'>
                    <h2 className=' text-dark p-0 m-0 text-level-3'>
                      Name:{' '}
                      <span className='fw_500 text-capitalize word-break-all'>
                        {userDetails.name}
                      </span>
                    </h2>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Email: <span className='fw_500 word-break-all'>{userDetails.email}</span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Role:{' '}
                      <span className='fw_500 text-capitalize word-break-all'>
                        {userDetails.role}
                      </span>
                    </p>
                    {/* <p className=' text-dark fw_700 fs-1_25rem p-0 m-0'>
                      Account Active:{' '}
                      <span className='fw_500'>
                        {userDetails.is_active ? 'Active' : 'Not Active'}
                      </span>
                    </p> */}
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Account Verified:{' '}
                      <span className='fw_500 word-break-all'>
                        {userDetails.is_verified ? 'Verified' : 'Un Verified'}
                      </span>
                    </p>
                  </div>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default AdminUsers;
