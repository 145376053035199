import API from "../config/api.config";
import StorageService from "./storage.service";
import axios from "axios";
const token = StorageService.getToken() ? StorageService.getToken() : "";

const storeMergeRequest = (data, formtType) => {
  if (formtType === "form") {
    const config = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    };
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}merge/store-merge-request`,
      data: data,
      headers: config,
    }).then((res) => {
      return res.data;
    });
  } else {
    return API.post("/merge/store-merge-request", data).then((res) => {
      return res.data;
    });
  }
};

const getAllMergedNfts = (data) => {
  return API.get("/merge/merged-nfts", data).then((res) => {
    return res.data;
  });
};

const getMergeRequests = (current_page = 1, limit = 10) => {
  return API.get(`/merge/get-all-requests/${current_page}/${limit}`).then(
    (res) => {
      return res.data;
    }
  );
};

const pendingMerges = (current_page = 1, limit = 10) => {
  return API.get(`/merge/get-pending-requests/${current_page}/${limit}`).then(
    (res) => {
      return res.data;
    }
  );
};

const getMergePreview = (merge_id) => {
  return API.get(`/merge/get-merge_preview/${merge_id}`).then((res) => {
    return res.data;
  });
};

const changeMergeStatus = (merge_id, data) => {
  if (data.status !== "completed") {
    return API.patch(`/merge/change-status/${merge_id}`, data).then((res) => {
      return res.data;
    });
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return axios
    .patch(
      `${process.env.REACT_APP_API_URL}merge/change-status/${merge_id}`,
      data,
      config
    )
    .then((res) => {
      return res.data;
    });
};

const MergeService = {
  getMergePreview,
  storeMergeRequest,
  getAllMergedNfts,
  getMergeRequests,
  changeMergeStatus,
  pendingMerges,
};

export default MergeService;
