import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import {
  PersonalDetailsComponents,
  WalletDetailsCompoentns,
} from './components';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import { UserService, StorageService } from '../../services';
import { PreLoaderComponent2 } from '../../components';
import Swal from 'sweetalert2';
import useScreen from '../../hooks/useScreen';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SettingDashboard = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const { width } = useScreen();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      navigate('/connect-to-wallet');
    }
  }, []);

  const connectToUserTweet = async () => {
    UserService.postTweet()
      .then((res) => {
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      setIsLoading(true);
      UserService.checkUserActiveStatus()
        .then((res) => {
          setIsLoading(false);
          if (res.status === false) {
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === 'User account deactivated'
          ) {
            setIsLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            }).then(() => {
              StorageService.clearSession();
              navigate('/connect-to-wallet');
              return window.location.reload();
            });
          }
        });
    }
  }, []);

  return (
    <>
      {isLoading && <PreLoaderComponent2 />}
      <div
        className='d-flex flex-column justify-content-between p-3'
        style={{
          minWidth: '100vw',
          minHeight:'86vh'
        }}
      >
        <div>
          <div className='mx-auto py-2'>
            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#FFFF00' } }}
                inkbarstyle={{ background: '#FFFF00' }}
                centered
                aria-label='nfts-tab'
                className='fs-1_5rem pb-3'
              >
                <Tab
                  label='Personal Info'
                  {...a11yProps(0)}
                  className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                    value === 0 ? 'fw_bolder' : ''
                  }`}
                />
                <Tab
                  label='Wallets'
                  {...a11yProps(1)}
                  className={`theme-text-color text-capitalize text-level-3 fw-5 tab ${
                    value === 1 ? 'fw_bolder' : ''
                  }`}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <PersonalDetailsComponents />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <WalletDetailsCompoentns />
            </TabPanel>
          </div>
        </div>
        <div className='w-100 bg-dark border-top mb-1'>
          <button
            type='button'
            className='butn butn-primary twitter-btn w-100'
            onClick={(e) => connectToUserTweet(e)}
            disabled={true}
          >
            <i className='fa-brands fa-twitter icon-shadow'></i>
            Connect to Twitter (comming soon)
          </button>
        </div>
      </div>
    </>
  );
};

export default SettingDashboard;
