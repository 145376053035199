import React, { useState, useEffect } from 'react';
import {
  NftService,
  MergedService,
  AuthService,
  StorageService,
} from '../../../services';
import Swal from 'sweetalert2';
import {
  Modal,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Avatar,
  AvatarGroup,
  InputAdornment,
  Autocomplete,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  makeStyles,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import PreLoaderComponent from './preLoader.component';
import ErrorFormat from '../../../utils/ErrorFormat;';
import useScreen from '../../../hooks/useScreen';
import TooltipWrapper from '../../../components/TooltipWrapper';
import TextFieldWrapper from '../../../components/TextFieldWrapper';
import TextFieldWrapperSearch from '../../../components/TextFieldWrapperSearch';
import moment from 'moment-js';
import TextFieldWrapperDark from '../../../components/TextFieldWrapperDark';
import { Close } from '@mui/icons-material';
import AutoCompleteWrapperDark from '../../../components/AutoCompleteWrapperDark';
import RadioWrapper from '../../../components/RadioWrapper';

const style = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: '4rem',
  width: '90%',
  bgcolor: '#ffff00',
  boxShadow: 24,
  p: 3,
  borderRadius: '15px',
  '& .MuiTextField-root': { m: 1, width: '25ch' },
};

const TrendingComponents = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useScreen();
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchNfts, setSearchNfts] = useState('');
  const [format, setFormat] = useState('');
  const [bgType, setBgType] = useState('');
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const toggleModal = () => setOpen(!open);
  const [likes, setLikes] = useState('-1');
  const [title, setTitle] = useState('');
  const [created_at, setCreated_at] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [isDataReset, setIsDataReset] = useState(false);
  const [reArrangeNFTs, setReArrangeNFTs] = useState(false);
  const [isLikeRequest, setIsLikeRequest] = useState(false);
  const [sortType, setSortType] = useState('title');

  const resetSortForm = () => {
    //setLikes('-1');
    setTitle('');
    setCreated_at('');
  };

  const resetFilterForm = () => {
    setFormat('');
    setBgType('');
  };

  const resetForm = () => {
    // setLikes('-1');
    setTitle('');
    setCreated_at('');
    setFormat('');
    setBgType('');
    setSearchNfts('');
  };

  const resetAllForm = () => {
    setFirstPage(1);
    setLastPage(1);
    setNfts([]);
  };

  const getNfts = (status = true) => {
    setIsLoading(status);
    status && resetAllForm();

    const data = {
      search_query: searchNfts.toLocaleLowerCase(),
    };
    if (likes !== '') data.likes = likes;
    if (title !== '') data.title = title;
    if (created_at !== '') data.created_at = created_at;

    if (format !== '' && format !== null) {
      data.format = format;
    }
    if (bgType !== '' && bgType !== null) {
      data.bg_type = bgType;
    }

    NftService.likedMergedNfts(data, currentPage, limit)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          if (res.data && res.data.length > 0) {
            setFirstPage(res.pagenation.first_page);
            setLastPage(res.pagenation.last_page);
            setNfts(res.data);
            setIsDataReset(false);
          } else {
            resetAllForm();
          }
        } else {
          resetAllForm();
        }
        setIsLikeRequest(false);
      })
      .catch((error) => {
        setIsLikeRequest(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isDataReset) {
      getNfts();
    }
  }, [isDataReset]);

  const searchByNftTitle = () => {
    setIsLoading(true);
    resetAllForm();
    const data = {
      search_query: searchNfts,
    };
    NftService.searchNfts(data, currentPage, limit)
      .then((res) => {
        setIsLoading(false);
        if (res.status && res.data.length > 0) {
          setFirstPage(res.pagenation.first_page);
          setLastPage(res.pagenation.last_page);
          setNfts(res.data);
        } else {
          resetForm();
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNfts();
  }, [currentPage]);

  useEffect(() => {
    if (searchNfts !== null && searchNfts !== '') {
      resetFilterForm();
      resetSortForm();
    } else {
      getNfts();
    }
  }, [searchNfts]);

  useEffect(() => {
    if (location.state && location.state.like_merge_id) {
      likeAndDislike(location.state.like_merge_id);
    }
  }, [location.state]);

  const likeAndDislike = (merge_id) => {
    setIsLikeRequest(true);
    if (!AuthService.isLoggedIn()) {
      setIsLoading(false);
      return navigate('/connect-to-wallet', {
        state: { like_merge_id: merge_id, prev_location: location.pathname },
      });
    } else {
      return MergedService.mergedLikeAndDisklike(merge_id)
        .then((res) => {
          if (res.status) {
            const nfts_Data = nfts.map((nft, index) => {
              if (nft._id === merge_id) {
                nft.is_liked = res.data.like;
                nft.likes = res.data.no_of_likes;
              }
            });

            if (location.state && location.state.like_merge_id) {
              navigate(location.pathname, { state: null });
            }
            getNfts(false);
          }
        })
        .catch((error) => {
          setIsLikeRequest(false);
          if (error.response.data.errors) {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: ErrorFormat.validationErrors(error.response.data.errors),
            });
          } else if (error.response.data.message) {
            if (error.response.data.message === 'User account deactivated') {
              Swal.fire({
                icon: 'warning',
                title: 'Inactive account!',
                html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
              }).then(() => {
                StorageService.clearSession();
                navigate('/connect-to-wallet');
                return window.location.reload();
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: `<p>${error.response.data.message}</p>`,
              });
            }
          }
        });
    }
  };

  const hanldePagenation = (pagenate_type) => {
    if (pagenate_type === 'first') {
      setCurrentPage(firstPage);
    } else if (pagenate_type === 'last') {
      setCurrentPage(lastPage);
    } else if (pagenate_type === 'next') {
      // handle next
      setCurrentPage(currentPage + 1);
    } else if (pagenate_type === 'prev') {
      // handle previous
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNFTsOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      getNfts();
    }
  };

  const cmp = function (x, y) {
    return x > y ? 1 : x < y ? -1 : 0;
  };

  const reSortNfts = () => {
    // setIsLoading(true)
    setReArrangeNFTs(false);
    nfts.sort((a, b) => {
      // return -cmp(a.likes, b.likes)
      return cmp(
        [
          -cmp(a.likes, b.likes),
          cmp(a.created_at, b.created_at),
          cmp(a.title, b.title),
        ],
        [
          -cmp(b.likes, a.likes),
          cmp(b.created_at, a.created_at),
          cmp(b.title, a.title),
        ]
      );
    });
    // setIsLoading(false)
  };

  useEffect(() => {
    if (reArrangeNFTs) {
      reSortNfts();
    }
  }, [reArrangeNFTs]);

  return (
    <>
      <section className='py-4 px-3'>
        <TextFieldWrapperSearch
          type='text'
          fullWidth
          //className='w-100 px-4 py-2 search-input '
          id='search-input'
          placeholder='Search NFTs'
          onChange={(e) => setSearchNfts(e.target.value)}
          value={searchNfts}
          onKeyDown={(e) => handleNFTsOnKeyDown(e)}
          maxLength={100}
          inputProps={{ maxLength: 100 }}
          InputProps={{
            endAdornment: (
              <i
                className='fa-solid fa-magnifying-glass cursor-pointer'
                onClick={() => getNfts()}
              ></i>
            ),
          }}
        />
        <div className='d-flex justify-content-between py-4 gap-4 btn-group'>
          <button
            type='button'
            className='border w-50 butn text-capitalize text-level-3'
            onClick={() => {
              toggleModal();
              setModalType('filter');
            }}
            disabled={isLoading}
          >
            Filter
          </button>
          <button
            type='button'
            className='border w-50 butn text-capitalize text-level-3 '
            onClick={() => {
              toggleModal();
              setModalType('sort');
            }}
            disabled={isLoading}
          >
            Sort
          </button>
        </div>

        {/* nfts cards */}
        <div className='py-4 cards-group row g-4 h-100 justify-content-center'>
          {isLoading ? (
            <PreLoaderComponent />
          ) : nfts.length > 0 ? (
            nfts.map((item, index) => (
              <div className='px-2 custom-column boxShadeHov' key={index}>
                <div className='card p-0'>
                  <div className='img-container'>
                    <img
                      src={
                        item.merge_type === 'nft'
                          ? `${process.env.REACT_APP_ASSET_URL}/${item.merged_image}`
                          : item.merge_thumnail_url
                      }
                      className='card-img-top'
                      alt={item.title ? item.title : 'merged nfts'}
                    />
                  </div>
                  <TooltipWrapper
                    title={
                      isLikeRequest
                        ? 'Previous request in progress please wait'
                        : ''
                    }
                  >
                    <button
                      type='button'
                      className={`butn love-butn ${
                        item.is_liked && 'liked'
                      } border-none`}
                      onClick={() =>
                        !isLoading && !isLikeRequest && likeAndDislike(item._id)
                      }
                      //disabled={isLoading}
                    >
                      <i className='fa-solid fa-heart '></i>
                      <span>{item.likes}</span>
                    </button>
                  </TooltipWrapper>
                  <div className='card-body line-hight-08'>
                    <h5 className='card-title one-line-text'>
                      {item.title[0].toUpperCase() + item.title.slice(1)}
                    </h5>
                    <div className='d-flex gap-2 justify-content-start align-items-center pe-2'>
                      <AvatarGroup max={6}>
                        {item.friend_tags.map((frnd_info, index) => {
                          return (
                            index < 6 && (
                              <TooltipWrapper title={frnd_info?.alias}>
                                {frnd_info.imgUrl !== '' ? (
                                  <Avatar
                                    className='border-yellow text-yellow bg-dark'
                                    key={frnd_info?._id}
                                    alt={frnd_info?.alias}
                                    src={`${process.env.REACT_APP_ASSET_URL}/${frnd_info.imgUrl}`}
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      position: 'static',
                                    }}
                                  />
                                ) : (
                                  <Avatar
                                    className='avatar-text border-yellow text-yellow bg-dark'
                                    key={frnd_info?._id}
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      position: 'static',
                                    }}
                                  >
                                    {frnd_info?.alias[0] + frnd_info?.alias[1]}
                                  </Avatar>
                                )}
                              </TooltipWrapper>
                            )
                          );
                        })}
                      </AvatarGroup>
                    </div>
                    {item.tags && item.tags.length > 0 && (
                      <p className='card-text pt-2 gap-2 one-line-text mb-1'>
                        {item.tags &&
                          item.tags.length > 0 &&
                          item.tags.map((tag, index) => (
                            <TooltipWrapper title={`#${tag}`}>
                              <span className='hash-tag px-1' key={index}>
                                {'#'}
                                {tag && tag.length > 5
                                  ? tag.substring(0, 5) + '...'
                                  : tag}
                              </span>
                            </TooltipWrapper>
                          ))}
                      </p>
                    )}

                    <div className='text-level-5 text-yellow text-end'>
                      {moment(item?.created_at).format('DD-MM-YYYY')}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='d-flex justify-content-center align-items-center'>
              <h4 className='theme-text-color text-level-5 '>No NFT Found</h4>
            </div>
          )}

          {!isLoading && nfts.length > 0 && (
            <div className='d-flex justify-content-around align-items-center py-3'>
              <div className='d-flex justify-content-center align-items-center'>
                <div>
                  <button
                    className='mui-icon-btn'
                    variant='text'
                    onClick={() => hanldePagenation('first')}
                    disabled={currentPage == firstPage}
                  >
                    <i className='fa-solid fa-angles-left mui-pagenate-icon'></i>
                  </button>
                  <button
                    className='mui-icon-btn'
                    variant='text'
                    onClick={() => hanldePagenation('prev')}
                    disabled={currentPage == firstPage}
                  >
                    <i className='fa-solid fa-chevron-left mui-pagenate-icon'></i>
                  </button>
                </div>
                <div>
                  <button
                    className='mui-icon-btn'
                    variant='text'
                    onClick={() => hanldePagenation('next')}
                    disabled={currentPage == lastPage}
                  >
                    <i className='fa-solid fa-chevron-right mui-pagenate-icon'></i>
                  </button>
                  <button
                    className='mui-icon-btn'
                    variant='text'
                    onClick={() => hanldePagenation('last')}
                    disabled={currentPage == lastPage}
                  >
                    <i className='fa-solid fa-angles-right mui-pagenate-icon'></i>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* modal */}
        <div>
          <Modal
            open={open}
            onClose={toggleModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className='modal-background overflow-auto'
            sx={{
              '& .MuiBox-root': {
                maxWidth: '700px !important',
                overflowY: 'auto',
              },
            }}
          >
            <Box sx={style} className='' autoComplete='off'>
              <div className='d-flex justify-content-between modal-header px-1'>
                <h3 className='modal-title fw_bolder'>
                  {modalType === 'filter' ? 'Filter ' : 'Sort '} NFTs
                </h3>
                <button
                  type='button'
                  className='modal-butn fw_bolder'
                  onClick={() => toggleModal()}
                >
                  x
                </button>
              </div>

              <div className='px-1'>
                {modalType === 'filter' ? (
                  <>
                    <div className=' d-flex flex-column  justify-content-center align-items-center pb-3 pt-5'>
                      <TextFieldWrapperDark
                        id='format'
                        value={format}
                        label='Select Format Type'
                        onChange={(e) => {
                          setFormat(e.target.value);
                        }}
                        className='selected-text'
                        select
                        InputProps={{
                          endAdornment: format !== '' && (
                            <i
                              className='fa-solid fa-xmark cursor-pointer mx-4'
                              onClick={() => setFormat('')}
                            ></i>
                          ),
                        }}
                      >
                        <MenuItem
                          value='square'
                          className='theme-text-color bg-dark  border-bottom'
                        >
                          Square
                        </MenuItem>
                      </TextFieldWrapperDark>

                      <TextFieldWrapperDark
                        id='bg_type'
                        value={bgType}
                        label='Select Background Type'
                        onChange={(e) => {
                          setBgType(e.target.value);
                        }}
                        className='selected-text'
                        select
                        InputProps={{
                          endAdornment: bgType !== '' && (
                            <i
                              className='fa-solid fa-xmark cursor-pointer mx-4'
                              onClick={() => setBgType('')}
                            ></i>
                          ),
                        }}
                      >
                        <MenuItem
                          value='color'
                          className='theme-text-color bg-dark  border-bottom'
                          size='small'
                        >
                          Color
                        </MenuItem>
                        <MenuItem
                          value='radiant'
                          className='theme-text-color bg-dark  border-bottom'
                        >
                          Radiant
                        </MenuItem>
                        <MenuItem
                          value='picture'
                          className='theme-text-color bg-dark  border-bottom'
                        >
                          Picture
                        </MenuItem>
                      </TextFieldWrapperDark>

                      <div className='d-flex gap-3 btn-group w-100'>
                        <div className='py-3 w-100'>
                          <button
                            type='submit'
                            className='butn w-100 text-level-3'
                            disabled={isLoading}
                            onClick={(e) => {
                              getNfts(e);
                              setCurrentPage(1);
                              setOpen(false);
                            }}
                          >
                            Apply Filter
                          </button>
                        </div>
                        {/* <div className='py-3 w-100'>
                          <button
                            type='button'
                            className='butn butn-outlined w-100 text-level-3'
                            disabled={isLoading}
                            onClick={() => {
                              resetFilterForm();
                              // setIsDataReset(true);
                            }}
                          >
                            Reset
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className=' d-flex flex-column  justify-content-center align-items-center pb-3 pt-5'>
                      <div className='w-100 mb-3'>
                        <FormControl>
                          <FormLabel
                            className='bg-yellow text-dark text-level-3 xs-fw-700'
                            id='demo-controlled-radio-buttons-group'
                          >
                            Sorting on:
                          </FormLabel>
                          <RadioGroup
                            className='bg-yellow'
                            row
                            aria-labelledby='demo-controlled-radio-buttons-group'
                            name='controlled-radio-buttons-group'
                            value={sortType}
                            onChange={(e) => {
                              setTitle('');
                              setLikes('-1');
                              setCreated_at('');
                              setSortType(e.target.value);
                            }}
                          >
                            {/* <FormControlLabel
                              className='bg-yellow'
                              value='likes'
                              control={<RadioWrapper />}
                              label='Likes'
                            /> */}
                            <FormControlLabel
                              className='bg-yellow'
                              value='title'
                              control={<RadioWrapper />}
                              label='Title'
                            />
                            <FormControlLabel
                              className='bg-yellow text-level-3'
                              value='date'
                              control={<RadioWrapper />}
                              label='Date'
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      {/* {sortType === 'likes' && (
                        <div className='w-100'>
                          <FormControl>
                            <FormLabel
                              className='bg-yellow text-dark text-level-3'
                              id='demo-controlled-radio-buttons-group'
                            >
                              Choose Sort Option:
                            </FormLabel>
                            <RadioGroup
                              className='bg-yellow'
                              row
                              aria-labelledby='demo-controlled-radio-buttons-group'
                              name='controlled-radio-buttons-group'
                              value={likes}
                              onChange={(e) => setLikes(e.target.value)}
                            >
                              <FormControlLabel
                                className='bg-yellow'
                                value='1'
                                control={<RadioWrapper />}
                                label='Assending'
                              />
                              <FormControlLabel
                                className='bg-yellow'
                                value='-1'
                                control={<RadioWrapper />}
                                label='Desending'
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      )} */}

                      {sortType === 'title' && (
                        <div className='w-100'>
                          <FormControl>
                            <FormLabel
                              className='bg-yellow text-dark text-level-3 xs-fw-700'
                              id='demo-controlled-radio-buttons-group'
                            >
                              Choose Sort Option:
                            </FormLabel>
                            <RadioGroup
                              className='bg-yellow'
                              row
                              aria-labelledby='demo-controlled-radio-buttons-group'
                              name='controlled-radio-buttons-group'
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            >
                              <FormControlLabel
                                className='bg-yellow'
                                value=''
                                control={<RadioWrapper />}
                                label='None'
                              />
                              <FormControlLabel
                                className='bg-yellow'
                                value='1'
                                control={<RadioWrapper />}
                                label='Assending'
                              />
                              <FormControlLabel
                                className='bg-yellow'
                                value='-1'
                                control={<RadioWrapper />}
                                label='Desending'
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      )}

                      {sortType === 'date' && (
                        <div className='w-100'>
                          <FormControl>
                            <FormLabel
                              className='bg-yellow text-dark text-level-3 xs-fw-700'
                              id='demo-controlled-radio-buttons-group'
                            >
                              Choose Sort Option:
                            </FormLabel>
                            <RadioGroup
                              className='bg-yellow'
                              row
                              aria-labelledby='demo-controlled-radio-buttons-group'
                              name='controlled-radio-buttons-group'
                              value={created_at}
                              onChange={(e) => setCreated_at(e.target.value)}
                            >
                              <FormControlLabel
                                className='bg-yellow'
                                value=''
                                control={<RadioWrapper />}
                                label='None'
                              />
                              <FormControlLabel
                                className='bg-yellow'
                                value='1'
                                control={<RadioWrapper />}
                                label='Assending'
                              />
                              <FormControlLabel
                                className='bg-yellow'
                                value='-1'
                                control={<RadioWrapper />}
                                label='Desending'
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      )}

                      {/* <TextFieldWrapperDark
                        id='title'
                        value={title}
                        label='Sort By Title'
                        onChange={(e) => setTitle(e.target.value)}
                        className='selected-text'
                        select
                        InputProps={{
                          endAdornment: title !== '' && (
                            <i
                              className='fa-solid fa-xmark cursor-pointer mx-4'
                              onClick={() => setTitle('')}
                            ></i>
                          ),
                        }}
                      >
                        <MenuItem
                          value='1'
                          className='theme-text-color bg-dark  border-bottom'
                        >
                          Ascending
                        </MenuItem>
                        <MenuItem
                          value='-1'
                          className='theme-text-color bg-dark  border-bottom'
                        >
                          Descending
                        </MenuItem>
                      </TextFieldWrapperDark>

                      <TextFieldWrapperDark
                        id='date'
                        value={created_at}
                        label='Sort By Date'
                        onChange={(e) => setCreated_at(e.target.value)}
                        select
                        InputProps={{
                          endAdornment: created_at !== '' && (
                            <i
                              className='fa-solid fa-xmark cursor-pointer mx-4'
                              onClick={() => setCreated_at('')}
                            ></i>
                          ),
                        }}
                      >
                        <MenuItem
                          value='1'
                          className='theme-text-color bg-dark  border-bottom'
                        >
                          Ascending
                        </MenuItem>
                        <MenuItem
                          value='-1'
                          className='theme-text-color bg-dark  border-bottom'
                        >
                          Descending
                        </MenuItem>
                      </TextFieldWrapperDark> */}

                      <div className='d-flex gap-3 btn-group w-100'>
                        <div className='py-3 w-100'>
                          <button
                            type='submit'
                            className='butn w-100 text-level-3'
                            disabled={isLoading}
                            onClick={(e) => {
                              getNfts(e);
                              setCurrentPage(1);
                              setOpen(false);
                            }}
                          >
                            Apply Sort
                          </button>
                        </div>
                        {/* <div className='py-3 w-100'>
                          <button
                            type='button'
                            className='butn butn-outlined w-100 text-level-3'
                            disabled={isLoading}
                            onClick={() => {
                              resetSortForm();
                            }}
                          >
                            Reset
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Box>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default TrendingComponents;
