const isEmpty = (value, id) => {
  if (!value) {
    document.getElementById(id).style.borderColor = 'red';
    return 'Field is required';
  }
  document.getElementById(id).style.borderColor = '#ced4da';
  return '';
};

const maxLengthCheck = (e) => {
  if (e.target.value.length > e.target.max) {
    return `length not be greater than ${e.target.max} digits`;
  }
  return e.target.value;
};

const maxCharChecker = (inputValue, maxValue) => {
  if (inputValue.length <= maxValue) {
    return '';
  }
  return `Please enter at least ${maxValue} characters`;
};

const minCharChecker = (inputValue, minValue) => {
  if (inputValue.length >= minValue) {
    return '';
  }
  return `Please enter at least ${minValue} characters`;
};

const rangeChecker = (inputValue, minValue, maxValue) => {
  if (inputValue.length < minValue || inputValue.length > maxValue) {
    return `Please enter ${minValue}-${maxValue} characters`;
  }
  return '';
};

const email = (email, id) => {
  // let regex =
  //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  //let regex = /^\w+([\.-]?\w+)*@[a-z0-9]+([\-]?[a-z0-9]+)+.[a-z]{1,3}$/;
  //let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  //let regex = /^[a-zA-Z]([.-]?\w)*[a-zA-Z0-9]+@[^\s@]{2,14}\.[^\s@]{2,3}$/;
  let regex = /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,5}$/i;
  if (!email) {
    document.getElementById(id).style.borderColor = 'red';
    return 'Field is required';
  } else if (!regex.test(email)) {
    document.getElementById(id).style.borderColor = 'red';
    return 'Incorrect email format';
  } else {
    document.getElementById(id).style.borderColor = '#ced4da';
    return '';
  }
};

const email2 = (email, id) => {
  // let regex =
  //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // let regex =
  //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  // let regex = /^\w+([\.-]?\w+)*@[a-z0-9]+([\-]?[a-z0-9]+)+.[a-z]{1,3}$/;
  //let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  let regex = /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,5}$/i;
  if (!email) {
    document.getElementById(id).style.borderColor = '#ced4da';
    return '';
  } else if (!regex.test(email)) {
    document.getElementById(id).style.borderColor = 'red';
    return 'Incorrect email format';
  } else {
    document.getElementById(id).style.borderColor = '#ced4da';
    return '';
  }
};

const password = (password, id) => {
  const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,12}$/;
  ///^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,12}$/;
  if (!password) {
    document.getElementById(id).style.borderColor = 'red';
    return 'Field is required';
  } else if (!passwordPattern.test(password)) {
    document.getElementById(id).style.borderColor = 'red';
    // return "Password must contains at least 1 numeric, uppercase, lowercase, special character and 8-12 character long!";
    return 'Password must be 8-12 characters and consist of 1 digit(0-9), upper & lower case(Aa-Zz), a symbol!';
  } else {
    document.getElementById(id).style.borderColor = '#ced4da';
    return '';
  }
};

const checkFirstDigit = (value, id) => {
  let regExp = /^[1-9]/;

  const check = regExp.test(value);

  if (check) {
    document.getElementById(id).style.borderColor = 'red';
    return 'First character is not acceptable as a number';
  } else {
    document.getElementById(id).style.borderColor = '#ced4da';
    return '';
  }
};

const textContainsSymbols = (value, id) => {
  let regExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/~`?]+/;

  const check = regExp.test(value);

  if (!check) {
    document.getElementById(id).style.borderColor = '#ced4da';
    return '';
  } else {
    document.getElementById(id).style.borderColor = 'red';
    return 'symbols and special characters are not allowed';
  }
};

const checkBothPasswords = (confirm_password, id, password_value) => {
  if (confirm_password != password_value) {
    document.getElementById(id).style.borderColor = 'red';
    return 'Both Passwords must be same';
  } else {
    document.getElementById(id).style.borderColor = '#ced4da';
    return '';
  }
};

const checkOldAndNewPasswords = (new_password, id, password_value) => {
  if (new_password == password_value) {
    document.getElementById(id).style.borderColor = 'red';
    return 'Both Passwords not be same!';
  } else {
    document.getElementById(id).style.borderColor = '#ced4da';
    return '';
  }
};

const url = (url, id) => {
  // const regExp =
  //   /^(https?):\/\/+(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
  // const regExp =
  //   /^(http?|https?):\/\/+(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
  // const regExp =/\b(https?:\/\/.*?\.[a-z]{2,4}\b)/g ;
  // const regExp = /\b(http?)s?:\/\/.*?\.[a-z]{2,4}\b\S*/g ;
  // const regExp = /^(https?:\/\/www\.)?(^(https?:\/\/www\.)[0-9A-Za-z]+\.+[a-z]{2,5})/
  // const regExp = /^\s*(http\:\/\/)?([a-z\d\-]{1,63}\.)*[a-z\d\-]{1,255}\.[a-z]{2,6}\s*$/
  // const regExp = /^(?!www | www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&=\?_:;-]+$/

  // const regExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  const regExp =
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/g;

  // return new RegExp(regExp).test(url);

  if (!url) {
    document.getElementById(id).style.borderColor = 'red';
    return '';
  } else if (new RegExp(regExp).test(url)) {
    // document.getElementById(id).style.borderColor = "red";
    // return "Please enter right url format like example.com";
    document.getElementById(id).style.borderColor = '#ced4da';
    return '';
  } else {
    document.getElementById(id).style.borderColor = 'red';
    return 'Please enter right url format like example.com';
  }
};

const handleOnBlur = (e, setErrors, password_value) => {
  let message = '';

  if (e.target.id === 'name') {
    message = isEmpty(e.target.value, e.target.id);
    if (message === '') {
      message = textContainsSymbols(e.target.value, e.target.id);
      if (message === '') {
        message = minCharChecker(e.target.value, 1);
      }
    }
    setErrors((old) => {
      return { ...old, name: message };
    });
  }
  if (e.target.id === 'name2') {
    message = isEmpty(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, name2: message };
    });
  } else if (e.target.id === 'wallet_alias') {
    message = isEmpty(e.target.value, e.target.id);
    if (message === '') {
      message = textContainsSymbols(e.target.value, e.target.id);
      if (message === '') {
        message = minCharChecker(e.target.value, 2);
      }
    }
    setErrors((old) => {
      return { ...old, wallet_alias: message };
    });
  } else if (e.target.id === 'email') {
    message = email(e.target.value.trim(), e.target.id);
    setErrors((old) => {
      return { ...old, email: message };
    });
  } else if (e.target.id === 'email2') {
    message = email2(e.target.value.trim(), e.target.id);
    setErrors((old) => {
      return { ...old, email2: message };
    });
  } else if (e.target.id === 'admin_email') {
    message = isEmpty(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, admin_email: message };
    });
    if (message === '') {
      message = email(e.target.value.trim(), e.target.id);
      setErrors((old) => {
        return { ...old, admin_email: message };
      });
    }
  } else if (e.target.id === 'password') {
    message = isEmpty(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, password: message };
    });
    if (message === '') {
      message = password(e.target.value, e.target.id);
      setErrors((old) => {
        return { ...old, password: message };
      });
    }
  } else if (e.target.id === 'new_password') {
    message = password(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, new_password: message };
    });
    if (message === '') {
      message = checkOldAndNewPasswords(
        e.target.value,
        e.target.id,
        password_value
      );
      setErrors((old) => {
        return { ...old, new_password: message };
      });
    }
  } else if (e.target.id === 'password2') {
    message = isEmpty(e.target.value, e.target.id);
    // if (message === "") {
    //   message = minCharChecker(e.target.value, 8)
    // }
    setErrors((old) => {
      return { ...old, password2: message };
    });
  } else if (e.target.id === 'admin_email2') {
    message = isEmpty(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, admin_email2: message };
    });
  } else if (e.target.id === 'url') {
    message = url(e.target.value.trim(), e.target.id);
    setErrors((old) => {
      return { ...old, url: message };
    });
  } else if (e.target.id === 'url2') {
    message = isEmpty(e.target.value.trim(), e.target.id);
    setErrors((old) => {
      return { ...old, url2: message };
    });
    if (message === '') {
      message = url(e.target.value.trim(), e.target.id);
      setErrors((old) => {
        return { ...old, url2: message };
      });
    }
  } else if (e.target.id === 'title') {
    message = isEmpty(e.target.value, e.target.id);
    if (message === '') {
      message = textContainsSymbols(e.target.value[0], e.target.id);
      if (message === '') {
        message = rangeChecker(e.target.value, 5, 42);
      } else {
        message = 'First character must be Alphabet!';
      }
    }
    return setErrors((old) => {
      return { ...old, title: message };
    });
  } else if (e.target.id === 'description') {
    if (e.target.value == null || e.target.value == '') {
      message = '';
    } else {
      message = rangeChecker(e.target.value, 10, 200);
    }
    return setErrors((old) => {
      return { ...old, description: message };
    });
  } else if (e.target.id === 'name_on_card') {
    message = isEmpty(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, name_on_card: message };
    });
    if (message === '') {
      message = checkFirstDigit(e.target.value, e.target.id);
      setErrors((old) => {
        return { ...old, name_on_card: message };
      });
    }
  } else if (e.target.id === 'c_password') {
    message = password(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, c_password: message };
    });
    if (message === '') {
      message = checkBothPasswords(e.target.value, e.target.id, password_value);
      setErrors((old) => {
        return { ...old, c_password: message };
      });
    }
  } else if (e.target.id === 'card_number') {
    message = isEmpty(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, card_number: message };
    });
    if (message === '') {
      if (e.target.value > 16 || e.target.value < 16) {
        message = 'card number must be 16 digits long';
      } else {
        message = '';
      }

      setErrors((old) => {
        return { ...old, card_number: message };
      });
    }
  } else if (e.target.id === 'expiry') {
    message = isEmpty(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, expiry: message };
    });
  } else if (e.target.id === 'cvv') {
    message = isEmpty(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, expiry: message };
    });
    if (message === '') {
      message = maxCharChecker(e.target.value, 3);
      setErrors((old) => {
        return { ...old, cvv: message };
      });
    }
  } else if (e.target.id === 'verificationCode') {
    message = isEmpty(e.target.value, e.target.id);
    setErrors((old) => {
      return { ...old, verificationCode: message };
    });
    if (message === '') {
      message = minCharChecker(e.target.value, 6);
      setErrors((old) => {
        return {
          ...old,
          verificationCode: 'Verification Code must be 6 digits long',
        };
      });
      if (message === '') {
        message = maxCharChecker(e.target.value, 6);
        setErrors((old) => {
          return {
            ...old,
            verificationCode: 'Verification must contains 6 digits long!',
          };
        });
      }
    }
  } else if (e.target.id === 'new_wallet_address') {
    message = isEmpty(e.target.value, e.target.id);
    if (message === '') {
      message = textContainsSymbols(e.target.value, e.target.id);
      if (message === '') {
        message = minCharChecker(e.target.value, 20);
      }
    }
    setErrors((old) => {
      return { ...old, new_wallet_address: message };
    });
  } else if (e.target.id === 'verificationCode2') {
    message = isEmpty(e.target.value, e.target.id);
    if (message === '') {
      message = minCharChecker(e.target.value, 4);
    }
    setErrors((old) => {
      return { ...old, verificationCode2: message };
    });
  }
};

const handleOnSubmit = (id, setErrors, password_value) => {
  let message = '';

  let element = document.getElementById(id);

  if (id === 'name') {
    message = isEmpty(element.value, id);
    if (message === '') {
      message = textContainsSymbols(element.value, id);
      if (message === '') {
        message = minCharChecker(element.value, 2);
      }
    }
    setErrors((old) => {
      return { ...old, name: message };
    });
  } else if (id === 'title') {
    message = isEmpty(element.value, id);
    if (message === '') {
      message = textContainsSymbols(element.value[0], id);
      if (message === '') {
        message = rangeChecker(element.value, 5, 42);
      } else {
        message = 'First character must be Alphabet!';
      }
    }
    return setErrors((old) => {
      return { ...old, title: message };
    });
  } else if (id === 'url') {
    message = url(element.value.trim(), id);
    setErrors((old) => {
      return { ...old, url: message };
    });
  } else if (id === 'url2') {
    message = isEmpty(element.value.trim(), id);
    setErrors((old) => {
      return { ...old, url2: message };
    });
    if (message === '') {
      message = url(element.value.trim(), id);
      setErrors((old) => {
        return { ...old, url2: message };
      });
    }
  } else if (id === 'description') {
    if (element.value == null || element.value == '') {
      message = '';
    } else {
      message = rangeChecker(element.value, 10, 200);
    }
    return setErrors((old) => {
      return { ...old, description: message };
    });
  } else if (id === 'email2') {
    message = email2(element.value.trim(), id);
    setErrors((old) => {
      return { ...old, email2: message };
    });
  } else if (id === 'email') {
    message = email(element.value.trim(), id);
    setErrors((old) => {
      return { ...old, email: message };
    });
  } else if (id === 'wallet_alias') {
    message = isEmpty(element.value, id);
    if (message === '') {
      message = textContainsSymbols(element.value, id);
      if (message === '') {
        message = minCharChecker(element.value, 2);
      }
    }
    setErrors((old) => {
      return { ...old, wallet_alias: message };
    });
  } else if (id === 'new_wallet_address') {
    message = isEmpty(element.value, id);
    if (message === '') {
      message = textContainsSymbols(element.value, id);
      if (message === '') {
        message = minCharChecker(element.value, 20);
      }
    }
    setErrors((old) => {
      return { ...old, new_wallet_address: message };
    });
  } else if (id === 'password2') {
    message = isEmpty(element.value, id);
    // if (message === "") {
    //   message = minCharChecker(element.value, 8);
    // }
    setErrors((old) => {
      return { ...old, password2: message };
    });
  } else if (id === 'new_password') {
    message = password(element.value, id);
    if (message === '') {
      message = checkOldAndNewPasswords(element.value, id, password_value);
    }
    setErrors((old) => {
      return { ...old, new_password: message };
    });
  } else if (id === 'password') {
    message = isEmpty(element.value, id);
    if (message === '') {
      message = password(element.value, id);
    }
    setErrors((old) => {
      return { ...old, password: message };
    });
  } else if (id === 'admin_email2') {
    message = isEmpty(element.value, id);
    setErrors((old) => {
      return { ...old, admin_email2: message };
    });
  } else if (id === 'admin_email') {
    message = isEmpty(element.value, id);
    if (message === '') {
      message = email(element.value.trim(), id);
    }
    setErrors((old) => {
      return { ...old, admin_email: message };
    });
  } else if (id === 'verificationCode2') {
    message = isEmpty(element.value, id);
    if (message === '') {
      message = minCharChecker(element.value, 4);
    }
    setErrors((old) => {
      return { ...old, verificationCode2: message };
    });
  }
  return message === '';
};

const FormValidator = {
  isEmpty,
  maxLengthCheck,
  email,
  password,
  handleOnBlur,
  maxCharChecker,
  handleOnSubmit,
};

export default FormValidator;
