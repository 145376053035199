import React, { useState } from 'react';
import { TextField, Box, Backdrop, CircularProgress } from '@mui/material';
import { AuthService } from '../../../services/admin';
import { StorageService } from '../../../services';
import ErrorFormat from '../../../utils/ErrorFormat;';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import FormValidator from '../../../utils/validation';
import { PreLoaderComponent, PreLoaderComponent2 } from '../../../components';
import useScreen from '../../../hooks/useScreen';
import TextFieldWrapper from '../../../components/TextFieldWrapper';

const SigninDashboard = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const { width } = useScreen();
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setPassword('');
    setEmail('');
  };

  const submitHandler = async (e) => {
    //e.preventDefault();
    FormValidator.handleOnSubmit('admin_email2', setErrors);
    FormValidator.handleOnSubmit('password2', setErrors);

    let noOfErrors = 0;
    let firstKey;
    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      setIsLoading(true);
      const data = {
        email: email,
        password: password,
      };

      AuthService.signIn(data)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            resetForm();
            if (res?.data?.role === 'admin') {
              StorageService.setSession(res?.token, res?.data);
              navigate('/admin');
              return window.location.reload();
            } else {
              return window.location.reload();
            }
          } else {
            setPassword('');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            setPassword('');
            // let passwordElement = document.getElementById("password2");
            if (error?.response?.data?.errors) {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: ErrorFormat.validationErrors(
                  error?.response?.data?.errors
                ),
              }).then(() => {
                return null;
              });
            } else if (error?.response?.data?.message) {
              Swal.fire({
                icon: 'error',
                title: 'Error occurred',
                html: `<p>${error?.response?.data?.message}</p>`,
              }).then((value) => {
                document.getElementById('password2').focus();
                if (error?.response?.data?.message === 'Account not verified') {
                  navigate('/admin/verify-account', {
                    state: { email: email },
                  });
                } else if (
                  error?.response?.data?.message === 'Account is inactive'
                ) {
                  resetForm();
                }
              });
            }
          }
        });
    }
  };

  return (
    <>
      <div className='px-4 py-4 d-flex justify-content-center align-items-center w-100 min-h-100vh'>
        {/*  {isLoading ? (
       
            <PreLoaderComponent />
       
        ) : ( */}

        <Box sx={{ width: '100%' }} className='' autoComplete='on'>
          <form
            className='signin-form px-4 py-4 w-100 h-100'
            encType='multipart/form-data'
            onSubmit={(e) => submitHandler(e)}
            autoComplete='on'
            noValidate
          >
            <h1 className='text-center ad-dash-main-heading heading-level-2 pb-3 fx-sh'>
              Sign In
            </h1>
            <div className=' theme-text-color formating py-3 '>
              <div className='theme-text-color'>
                <TextFieldWrapper
                  fullWidth
                  //className='wallet-select-field selected-text description-text'
                  className='w-100'
                  onChange={(e) => {
                    setEmail(e.target.value.trim());
                    FormValidator.handleOnBlur(e, setErrors);
                  }}
                  required
                  value={email.trim()}
                  label='Email'
                  type='email'
                  name='admin_email2'
                  id='admin_email2'
                  //placeholder='Email'
                  autoFocus={true}
                  onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  inputProps={{
                    maxLength: 128,
                  }}
                  size={width <= 320 ? 'small' : 'medium'}
                  // inputProps={{
                  //   autoComplete: "new-email",
                  //   form: {
                  //     autoComplete: "off",
                  //   },
                  // }}
                />
                {errors && errors.admin_email2 && (
                  <div className='error ps-2'>
                    <span className='error-span mt-0 pb-0 '>
                      <i className='fa-solid fa-circle-exclamation pe-1'></i>
                      {errors.admin_email2}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className=' theme-text-color formating py-3 '>
              <div className='theme-text-color'>
                <TextFieldWrapper
                  className='w-100'
                  onChange={(e) => {
                    setPassword(e.target.value.trim());
                    FormValidator.handleOnBlur(e, setErrors);
                  }}
                  required
                  value={password.trim()}
                  label='Password'
                  type='password'
                  name='password2'
                  id='password2'
                  //placeholder='Password'
                  onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  inputProps={{
                    maxLength: 100,
                  }}
                  size={width <= 320 ? 'small' : 'medium'}
                />
                {errors && errors.password2 && (
                  <div className='error ps-2'>
                    <span className='error-span mt-0 pb-0'>
                      <i className='fa-solid fa-circle-exclamation pe-1'></i>
                      {errors.password2}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className=' w-100 py-3'>
              <button
                //type='submit'
                type='button'
                onClick={submitHandler}
                className='butn butn-lg-primary w-100 text-level-4'
                disabled={isLoading}
              >
                Sign In
              </button>
              <div className='theme-text-color d-flex sm-flex-d justify-content-center align-items-center pt-3'>
                <div
                  onClick={() =>
                    navigate('/admin/forgot-password', {
                      state: { email },
                    })
                  }
                  className='text-center cursor-pointer theme-text-color pt-2 fs-lg fw_500 fx-sh text-level-5'
                >
                  Forgot Password
                </div>
              </div>
            </div>
          </form>
        </Box>
        {/* )} */}
        {isLoading && <PreLoaderComponent2 />}
      </div>
    </>
  );
};

export default SigninDashboard;
