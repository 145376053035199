import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { MergeService, NftService, StorageService } from '../../../services';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import {
  Button,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Modal,
  TextField,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ViewInArRoundedIcon from '@mui/icons-material/ViewInArRounded';
import dateFormat from 'dateformat';
import { PreLoaderComponent } from '../../../components';
import useScreen from '../../../hooks/useScreen';
import TextFieldWrapperDark from '../../../components/TextFieldWrapperDark';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Merge = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const { width } = useScreen();
  const [mergeRequests, setMergeRequests] = useState([]);
  const [status, setStatus] = useState('');
  const [feedback, setFeedback] = useState('');
  const [mergedImage, setMergedImage] = useState({});
  const [mergedPreview, setMergedPreview] = useState(null);
  const [mergeRequestId, setMergeRequestId] = useState('');
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };
  const [showBgInfo, setShowBgInfo] = useState(false);
  const mergeStatus = ['pending', 'rejected', 'completed'];
  const [bgInfo, setBgInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [mergeDetails, setMergeDetails] = useState({});
  const [viewMergeDetails, ToggleMergeDetails] = useState(false);
  const [mergePreview, setMergePreview] = useState('');
  const [mergedType, setMergedType] = useState('');
  const [mergeTitle, setMergeTitle] = useState('Merge Requests');
  const [mergePreviewTitle, setMergePreviewTitle] = useState('');
  const [errors, setErrors] = useState({});
  const [mergePreviewLoad, setMergePreviewLoad] = useState(false);
  const [modalType, setModalType] = useState('');

  useEffect(() => {
    setCurrentPage(1);
    setFirstPage(1);
    setLastPage(1);
  }, [location.state]);

  const getMergePreview = async (merged_id) => {
    setMergePreviewLoad(true);

    MergeService.getMergePreview(merged_id)
      .then((res) => {
        if (res.status) {
          setMergePreview(res.data.merge_preview);
        } else {
        }

        setMergePreviewLoad(false);
      })
      .catch((error) => {
        setMergePreviewLoad(false);
      });
  };

  const columns = [
    { field: 'id', headerName: 'id', minWidth: 200 },
    { field: 'sr_no', headerName: 'Sr #', minWidth: 70, flex: 0.2 },
    { field: 'user', headerName: 'User Id', minWidth: 300 },
    {
      field: 'details',
      headerName: 'Details',
      minWidth: 120,
      flex: 0.3,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleViewAllDetails = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          setMergeDetails(params.value);

          ToggleMergeDetails(true);
          getMergePreview(params.row.id);
        };

        return (
          <Button
            variant='text'
            className='mui-grid-btn mx-auto text-yellow'
            onClick={(e) => handleViewAllDetails(e)}
          >
            <FullscreenIcon className='text-yellow' />
          </Button>
        );
      },
    },
    { field: 'alias', headerName: 'Alias', minWidth: 160, flex: 0.5 },
    { field: 'format', headerName: 'Format', minWidth: 200, flex: 0.7 },
    { field: 'merge_type', headerName: 'Merge Type', minWidth: 160, flex: 0.5 },
    {
      field: 'background',
      headerName: 'Background',
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      minWidth: 180,
      flex: 0.6,
      renderCell: (params) => {
        const viewBackgroundInfo = (e) => {
          setShowBgInfo(true);
          setBgInfo(params.row.background);
          setModalType('bg');
        };

        return (
          <Button
            variant='text'
            className='mui-grid-btn mx-auto text-yellow'
            onClick={(e) => viewBackgroundInfo(e)}
          >
            <WallpaperIcon className='text-yellow' />
          </Button>
        );
      },
    },
    {
      field: 'merge_preview',
      headerName: 'Merged Preview',
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      minWidth: 200,
      flex: 0.7,
      renderCell: (params) => {
        const viewBackgroundInfo = (e) => {
          setShowBgInfo(true);
          getMergePreview(params.row.id);
          setMergePreviewTitle(params.row.title);
          setModalType('mp');
        };

        return (
          <Button
            variant='text'
            className='mui-grid-btn mx-auto text-yellow'
            onClick={(e) => viewBackgroundInfo(e)}
          >
            <ViewInArRoundedIcon className='text-yellow' />
          </Button>
        );
      },
    },
    { field: 'feedback', headerName: 'Feedback', minWidth: 250, flex: 0.8 },
    { field: 'status', headerName: 'Merge Status', minWidth: 200, flex: 0.7 },
    {
      field: 'nfts',
      headerName: 'NFTs Download',
      minWidth: 200,
      flex: 0.7,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleDownlaod = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const mergeNfts = params.row.nfts;
          for (let i = 0; i < mergeNfts.length; i++) {
            saveAs(mergeNfts[i].nft, 'nfts-' + mergeNfts[i].id + '.jpg');
          }
        };

        return (
          <Button
            variant='text'
            className='mui-grid-btn mx-auto text-yellow'
            onClick={(e) => handleDownlaod(e)}
          >
            <CloudDownloadIcon className='text-yellow' />
          </Button>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 100,
      flex: 0.4,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleMergeStatus = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          handleOpen();
          setStatus(params.value);
          setMergeRequestId(params.id);
          setMergedType(params.row.merge_type);
          // getPendingMergeRequest();
          setMergePreview(null);
        };

        return (
          <Button
            variant='text'
            className={`mui-grid-btn mx-auto ${
              params.row.merge_type === 'picture' || params.value !== 'pending'
                ? 'text-index-yellow'
                : 'text-yellow'
            }`}
            onClick={(e) => handleMergeStatus(e)}
            disabled={
              params.row.merge_type === 'picture' || params.value !== 'pending'
            }
          >
            <EditIcon />
          </Button>
        );
      },
    },
  ];

  const resetData = () => {
    setMergeRequests([]);
    setRows([]);
    setFirstPage(1);
    // setCurrentPage(1);
    // setLastPage(1);
  };

  const resetPagination = () => {
    setFirstPage(1);
    setLastPage(1);
    setLimit(5);
    setCurrentPage(1);
  };

  const getAllMergeRequest = () => {
    resetData();
    setIsLoading(true);
    MergeService.getMergeRequests(currentPage, limit)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          if (res.message === 'Failed to authenticate token.') {
            StorageService.clearSession();
            navigate('/admin');
            return window.location.reload();
          } else if (res.message !== 'No merge request found!') {
            setMergeRequests(res.data);
            setFirstPage(res.pagenation.first_page);
            setLastPage(res.pagenation.last_page);
            setLimit(res.pagenation.limit);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === 'User account deactivated'
        ) {
          StorageService.clearSession();
          navigate('/admin/signin');
          return window.location.reload();
        }
      });
  };

  const getMergedNfts = () => {
    resetData();
    setIsLoading(true);
    NftService.allMergedNfts(currentPage, limit)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          if (res.message === 'Failed to authenticate token.') {
            StorageService.clearSession();
            navigate('/admin');
            return window.location.reload();
          } else if (res.message !== 'No NFT found!') {
            setMergeRequests(res.data);
            setFirstPage(res.pagenation.first_page);
            setLastPage(res.pagenation.last_page);
            setLimit(res.pagenation.limit);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === 'User account deactivated'
        ) {
          StorageService.clearSession();
          navigate('/admin/signin');
          return window.location.reload();
        }
      });
  };

  const getPendingMergeRequest = (isPreviousPage = 0) => {
    setIsLoading(true);
    NftService.pendingMerges(currentPage, limit)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          if (res.message === 'Failed to authenticate token.') {
            StorageService.clearSession();
            navigate('/admin');
            return window.location.reload();
          } else if (res.message !== 'No merge request found!') {
            setMergeRequests(res.data);
            setFirstPage(res.pagenation.first_page);
            setLastPage(res.pagenation.last_page);
            setLimit(res.pagenation.limit);
            setCurrentPage(
              isPreviousPage === 1 && parseInt(res.pagenation.current_page) > 1
                ? parseInt(res.pagenation.current_page) - 1
                : parseInt(res.pagenation.current_page)
            );
          } else {
            resetPagination();
            resetData();
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === 'User account deactivated'
        ) {
          StorageService.clearSession();
          navigate('/admin/signin');
          return window.location.reload();
        }
      });
  };

  useEffect(() => {
    if (
      location.state &&
      location.state.data &&
      location.state.data === 'mergedNfts'
    ) {
      getMergedNfts();
      setMergeTitle('Merged NFTs');
    } else if (
      location.state &&
      location.state.data &&
      location.state.data === 'pendingMergeRequest'
    ) {
      getPendingMergeRequest();
      setMergeTitle('Pending Merges');
    } else {
      getAllMergeRequest();
      setMergeTitle('Merge Requests');
    }
  }, [currentPage, location.state]);

  const getAllMergeRows = () => {
    if (mergeRequests.length > 0) {
      setIsLoading(true);
      const allRows = mergeRequests.map((merge, index) => {
        return {
          id: merge._id,
          sr_no: ++index + (currentPage - 1) * limit,
          user: merge.user._id,
          details: merge,
          alias: merge.user.alias,
          wallet: merge.wallets[0],
          background: merge,
          format: merge.format,
          status: merge.status,
          nfts: merge.nfts,
          feedback:
            merge.feedback && merge.feedback !== ''
              ? merge.feedback
              : 'no feedback',
          action: merge.status,
          merge_type: merge.merge_type,
          merge_preview: merge.merge_thumnail_url,
          merged_image: merge.merged_image,
          created_at: merge.created_at,
          tags: merge.tags,
          friend_tags: merge.friend_tags,
          title: merge.title,
          description: merge.description,
        };
      });
      setRows(allRows);
      setIsLoading(false);
    }
  };

  const style = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '4rem',
    width: '90%',
    bgcolor: '#ffff00',
    boxShadow: 24,
    p: 3,
    borderRadius: '15px',
    '& .MuiTextField-root': { my: 1, width: '25ch' },
  };

  const handleFileInput = (e) => {
    // get size in MBs
    if (e.target.files.length === 0) {
      return;
    }
    const fileSize = e.target.files[0].size / (1024 * 1024);
    if (fileSize <= 3) {
      if (
        e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/jpg' ||
        e.target.files[0].type === 'image/jpeg'
      ) {
        // uploaded image must be less than 2MB
        var image = new Image();
        image.onload = function () {
          if (image.width == 1000 && image.height == 1000) {
            setErrors((old) => {
              return { ...old, merged_image: '' };
            });
            setMergedImage(e.target.files[0]);
            const myFile = e.target.files[0];
            setMergedPreview(URL.createObjectURL(e.target.files[0]));
            return URL.createObjectURL(e.target.files[0]);
          } else {
            setMergedImage({});
            setMergedPreview(null);
            setErrors((old) => {
              return {
                ...old,
                merged_image: 'Uploaded NFT must be 1000 x 1000 pixel',
              };
            });
            e.target.files = undefined;
            return (e.target.value = null);
          }
        };
        image.src = URL.createObjectURL(e.target.files[0]);
      } else {
        setMergedImage({});
        setMergedPreview(null);
        setErrors((old) => {
          return {
            ...old,
            merged_image: 'Only png, jpg, jpeg extensions are allowed',
          };
        });
        e.target.files = undefined;
        return (e.target.value = null);
      }
    } else if (
      e.target.files[0].type !== 'image/png' &&
      e.target.files[0].type !== 'image/jpg' &&
      e.target.files[0].type !== 'image/jpeg'
    ) {
      setMergedImage({});
      setMergedPreview(null);
      setErrors((old) => {
        return {
          ...old,
          merged_image: 'Only png, jpg, jpeg extensions are allowed',
        };
      });
      e.target.files = undefined;
      return (e.target.value = null);
    } else {
      setMergedImage({});
      setMergedPreview(null);
      setErrors((old) => {
        return {
          ...old,
          merged_image: 'Maxiumum NFT size is upto 3MB',
        };
      });
      e.target.files = undefined;
      return (e.target.value = null);
    }
  };

  const resetForm = () => {
    setStatus('');
    setFeedback('');
    setMergedImage({});
    setMergedPreview(null);
    setMergeRequestId('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (
      status === mergeStatus[2] &&
      (mergedPreview === null ||
        (errors.merged_image && errors.merged_image !== ''))
    ) {
      const uploadFileBtn = document.getElementById('merged-image');
      setIsLoading(false);
      return uploadFileBtn.click();
    }

    const formData = {
      status: status,
      feedback: feedback.trim(),
    };

    if (mergedImage) {
      formData.merged_image = mergedImage;
    }

    MergeService.changeMergeStatus(mergeRequestId, formData)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          Swal.fire('success', res.message, 'success');
          resetForm();
          handleClose();
          mergeTitle === 'Pending Merges'
            ? getPendingMergeRequest(rows.length)
            : mergeTitle === 'Merge Requests'
            ? getAllMergeRequest()
            : mergeTitle === 'Merged NFTs' && getMergedNfts();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleClose();
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === 'User account deactivated'
        ) {
          StorageService.clearSession();
          navigate('/admin/signin');
          return window.location.reload();
        } else if (error.response.data.message) {
          Swal.fire({
            icon: 'error',
            title: 'Error occurred',
            html: `<p>${error.response.data.message}</p>`,
          });
        }
      });
  };

  const hanldePagenation = (pagenate_type) => {
    if (pagenate_type === 'first') {
      setCurrentPage(firstPage);
    } else if (pagenate_type === 'last') {
      setCurrentPage(lastPage);
    } else if (pagenate_type === 'next') {
      // handle next
      setCurrentPage(Number(currentPage) + 1);
    } else if (pagenate_type === 'prev') {
      // handle previous
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  useEffect(() => {
    getAllMergeRows();
  }, [mergeRequests]);

  const downloadImage = (image_for_download, title) => {
    saveAs(image_for_download, title + '.jpg');
  };

  return (
    <>
      <div>
        <h1 className='text-center ad-dash-main-heading heading-level-2'>
          {mergeTitle}
        </h1>
      </div>
      <div className='overflow-scroll w-100 h-100'>
        <DataGrid
          rows={rows}
          pageSize={10}
          rowsPerPageOptions={[10]}
          className='data-grid-border '
          sx={{
            borderColor: '#ffff00',
            backgroundColor: '#ffff00',
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          autoHeight={true}
          columns={[...columns]}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                user: false,
              },
            },
          }}
          loading={isLoading}
        />
      </div>
      <div className='d-flex justify-content-around align-items-center py-3'>
        {mergeRequests && mergeRequests.length > 0 ? (
          <div className='d-flex m-0 justify-content-center align-items-center'>
            <div>
              <Button
                className='mui-icon-btn'
                variant='text'
                onClick={() => hanldePagenation('first')}
                disabled={currentPage == firstPage}
              >
                <i className='fa-solid fa-angles-left mui-pagenate-icon'></i>
              </Button>
              <Button
                className='mui-icon-btn'
                variant='text'
                onClick={() => hanldePagenation('prev')}
                disabled={currentPage == firstPage}
              >
                <i className='fa-solid fa-chevron-left mui-pagenate-icon'></i>
              </Button>
            </div>
            <div>
              <Button
                className='mui-icon-btn'
                variant='text'
                onClick={() => hanldePagenation('next')}
                disabled={currentPage == lastPage}
              >
                <i className='fa-solid fa-chevron-right mui-pagenate-icon'></i>
              </Button>
              <Button
                className='mui-icon-btn'
                variant='text'
                onClick={() => hanldePagenation('last')}
                disabled={currentPage == lastPage}
              >
                <i className='fa-solid fa-angles-right mui-pagenate-icon'></i>
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='p-0 m-0'>
        {currentPage && lastPage ? (
          <p className='theme-text-color text-center'>
            {currentPage} of {lastPage} Page
          </p>
        ) : (
          <></>
        )}
      </div>

      {/* update merge status */}
      <div className=''>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-y-auto '
        >
          <Box
            sx={style}
            className=''
            autoComplete='off'
            component='form'
            onSubmit={(e) => handleSubmit(e)}
            encType='multipart/form-data'
            noValidate
          >
            <div className='d-flex justify-content-between modal-header py-0 my-0'>
              <h3 className='modal-title '>Merge Status</h3>
              <button
                type='button'
                className='modal-butn'
                onClick={() => {
                  handleClose();
                  setErrors((old) => {
                    return { ...old, merged_image: '' };
                  });
                }}
              >
                x
              </button>
            </div>
            {mergedType === 'picture' ? (
              <div className=' d-flex flex-column  justify-content-center align-items-center pb-3 pt-5'>
                <h3 className='bg-heading text-capitalize text-center'>
                  Merged Completed
                </h3>
              </div>
            ) : (
              <div className=' d-flex flex-column  justify-content-center align-items-center pb-3 pt-5'>
                {/* <FormControl
                  fullWidth
                  className='status-select-field my-2 w-100'
                  size={width <= 320 ? 'small' : 'medium'}
                >
                  <InputLabel id='merge-status' className='input-label '>
                    Change Merge Status *
                  </InputLabel>
                  <Select
                    labelId='merge-status'
                    id='merge-status'
                    value={status}
                    label='Merge Status'
                    onChange={(e) => setStatus(e.target.value)}
                    className='selected-text'
                  >
                    {mergeStatus &&
                      mergeStatus.length > 0 &&
                      mergeStatus.map((merge_Status, index) => (
                        <MenuItem
                          value={merge_Status}
                          className='theme-text-color bg-dark  border-bottom'
                          key={index}
                        >
                          {merge_Status[0].toUpperCase() +
                            merge_Status.substring(1)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}

                <TextFieldWrapperDark
                  id='merge-status'
                  value={status}
                  className='w-100'
                  label='Change Merge Status *'
                  onChange={(e) => setStatus(e.target.value)}
                  select
                >
                  {mergeStatus &&
                    mergeStatus.length > 0 &&
                    mergeStatus.map((merge_Status, index) => (
                      <MenuItem
                        value={merge_Status}
                        className='theme-text-color bg-dark  border-bottom'
                        key={index}
                      >
                        {merge_Status[0].toUpperCase() +
                          merge_Status.substring(1)}
                      </MenuItem>
                    ))}
                </TextFieldWrapperDark>
                {status && status !== '' && status !== null ? (
                  <TextFieldWrapperDark
                    id='feedback'
                    label='Feedback'
                    fullWidth
                    className='w-100'
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    inputProps={{ maxLength: 200 }}
                  />
                ) : (
                  <></>
                )}
                {status &&
                status !== '' &&
                status !== null &&
                status === mergeStatus[2] ? (
                  <>
                    <input
                      type='file'
                      ref={fileInput}
                      onChange={(e) => handleFileInput(e)}
                      hidden
                      //value={mergedPreview}
                      name='merge-image'
                      id='merge-image'
                      accept='image/png, image/jpg, image/jpeg'
                    />

                    <div className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row justify-content-start align-items-center w-100'>
                      <div className='d-flex flex-column justify-content-start align-items-start w-100'>
                        {/* <div className='eer-flex mb-3'> */}
                        <button
                          id='merged-image'
                          type='button'
                          className='upload-profile-btn w-100'
                          onClick={() => fileInput.current.click()}
                        >
                          <i className='fa-solid fa-circle-plus pe-2'></i>
                          Upload Merged NFT
                        </button>
                        <div style={{ alignSelf: 'start' }}>
                          {errors.merged_image && errors.merged_image != '' ? (
                            <div className='error resposError'>
                              <span className='error-span'>
                                <i className='fa-solid fa-circle-exclamation pe-1'></i>
                                {errors.merged_image}
                              </span>
                            </div>
                          ) : (
                            <div className='displayInstructions '>
                              {' '}
                              <p className=' text-dark'>
                                NFT format: JPG, JPEG, PNG. Size should be
                                1000 x 1000 px
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* {errors.merged_image && errors.merged_image != '' && (
                          <div className='error '>
                            <span className='error-span'>
                              <i className='fa-solid fa-circle-exclamation pe-1'></i>
                              {errors.merged_image}
                            </span>
                          </div>
                        )} */}
                      {/* </div> */}

                      {mergedPreview && (
                        <img
                          src={mergedPreview}
                          className='w-25 '
                          alt='merged image'
                          style={{
                            boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                            border: '1px solid rgba(0,0,0,0.35)',
                            borderRadius: '0.2rem',
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className='py-3 w-100'>
                  <button
                    type='submit'
                    className='butn w-100'
                    disabled={isLoading | (status === 'pending')}
                  >
                    Change Merge Status
                  </button>
                </div>
              </div>
            )}
          </Box>
        </Modal>
      </div>

      {/* show bg info & merge preview */}
      <div className=''>
        <Modal
          open={showBgInfo}
          onClose={() => {
            setShowBgInfo(false);
            setMergePreview('');
            setBgInfo({});
          }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-y-auto '
        >
          <Box sx={style} className=''>
            <div className='d-flex justify-content-between modal-header py-0 my-0'>
              <h3 className='modal-title'>
                {modalType === 'mp' ? 'Merged Preview' : 'Background'}
              </h3>
              <button
                type='button'
                className='modal-butn'
                onClick={() => {
                  setShowBgInfo(false);
                  setMergePreview('');
                  setBgInfo({});
                }}
              >
                x
              </button>
            </div>
            <div className=' d-flex flex-column justify-content-center align-items-center pb-3 pt-5 '>
              {modalType === 'mp' ? (
                <>
                  {mergePreviewLoad ? (
                    <PreLoaderComponent color='dark' />
                  ) : (
                    <>
                      <img
                        src={mergePreview}
                        alt='merge preview'
                        className='nft-prevew-dimensions mx-auto '
                        style={{
                          boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                          border: '1px solid rgba(0,0,0,0.35)',
                          borderRadius: '0.2rem',
                        }}
                      />
                      <div className='py-3 w-100'>
                        <button
                          type='button'
                          className='butn w-100 '
                          onClick={() =>
                            downloadImage(mergePreview, mergePreviewTitle)
                          }
                          disabled={mergePreviewLoad}
                        >
                          Download{' '}
                          {mergePreview !== '' ? 'Preview' : 'Background'}
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <h3 className='bg-heading text-capitalize'>
                    Background Type:{' '}
                    {bgInfo.bg_type === 'radiant'
                      ? bgInfo.gradient_type + ' ' + bgInfo.bg_type
                      : '' + ' ' + bgInfo.bg_type}
                  </h3>
                  {bgInfo.bg_type === 'color' ? (
                    <div className='py-2 w-100'>
                      <div
                        className='color-plate w-100'
                        style={{
                          background: bgInfo.background_color,
                          boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                          border: '1px solid rgba(0,0,0,0.35)',
                        }}
                      >
                        {bgInfo.background_color}
                      </div>
                    </div>
                  ) : bgInfo.bg_type === 'radiant' ? (
                    <div className='d-flex justify-content-center align-items-center flex-wrap py-2'>
                      {bgInfo.gradient_colors &&
                      bgInfo.gradient_colors.length > 0 ? (
                        bgInfo.gradient_colors.map((clr, index) => (
                          <div className='p-1' key={index}>
                            <div
                              className='color-plate p-2'
                              style={{
                                background: clr,
                                boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                                border: '1px solid rgba(0,0,0,0.35)',
                              }}
                            >
                              {clr === '#000000' ? (
                                <p className='p-0 m-0 text-white'>{clr}</p>
                              ) : (
                                clr
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : bgInfo.bg_type === 'picture' ? (
                    <div className='w-100 py-2'>
                      <img
                        src={bgInfo.artist_image.artwork_uri}
                        alt='background'
                        className='nft-prevew-dimensions mx-auto'
                        style={{
                          boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                          border: '1px solid rgba(0,0,0,0.35)',
                          borderRadius: '0.2rem',
                        }}
                      />
                      <div className='py-3 w-100'>
                        <button
                          type='button'
                          className='butn w-100 '
                          onClick={() =>
                            downloadImage(
                              bgInfo.artist_image.artwork_uri,
                              'background Image - ' + bgInfo.title
                            )
                          }
                        >
                          Download Background
                        </button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </Box>
        </Modal>
      </div>

      {/*  view all the details of merge requests */}
      <div className=''>
        <Modal
          open={viewMergeDetails}
          onClose={(e) => ToggleMergeDetails(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-background overflow-y-auto '
        >
          <Box sx={style} className=''>
            <div className='d-flex justify-content-between modal-header py-0 my-0'>
              <h3 className='modal-title'>Merge Details</h3>
              <button
                type='button'
                className='modal-butn'
                onClick={(e) => ToggleMergeDetails(false)}
              >
                x
              </button>
            </div>

            {Object.keys(mergeDetails).length > 0 && (
              <>
                <div className=' d-flex flex-column justify-content-center align-items-start pb-3 pt-5 fs-1_25rem '>
                  <div className=''>
                    <h2 className=' text-dark fs-1_25rem text-level-3'>
                      Alias:{' '}
                      <span className='fw_500 text-capitalize'>
                        {mergeDetails.user.alias}
                      </span>
                    </h2>
                    <h3 className='text-dark text-level-3'>Selected Wallets</h3>
                    <ul className='text-level-3'>
                      {mergeDetails.wallets.length > 0 &&
                        mergeDetails.wallets.map((mergeWallet, index) => (
                          <li key={index} style={{ overflowWrap: 'anywhere' }}>
                            {mergeWallet?.alias +
                              ', ' +
                              mergeWallet?.wallet_address}
                          </li>
                        ))}
                    </ul>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Date:{' '}
                      <span className='fw_500 text-capitalize'>
                        {dateFormat(mergeDetails.created_at, 'dd') +
                          '/' +
                          dateFormat(mergeDetails.created_at, 'mm') +
                          '/' +
                          dateFormat(mergeDetails.created_at, 'yy')}
                      </span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Merge Format:{' '}
                      <span className='fw_500 text-capitalize'>
                        {mergeDetails.format}
                      </span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Merge Type:{' '}
                      <span
                        className={`fw_500 ${
                          mergeDetails.merge_type === 'nft'
                            ? 'text-uppercase'
                            : 'text-capitalize'
                        }`}
                      >
                        {mergeDetails.merge_type}
                      </span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Merge Status:{' '}
                      <span className='fw_500 text-capitalize m-0 p-0'>
                        {mergeDetails.status}
                      </span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      No of Likes:{' '}
                      <span className='fw_500 text-capitalize'>
                        {mergeDetails.likes}
                      </span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Merge Feedback:{' '}
                      <span className='fw_500 '>
                        {mergeDetails.feedback && mergeDetails.feedback !== ''
                          ? mergeDetails.feedback
                          : 'No feedback'}
                      </span>
                    </p>
                    <h3 className='text-dark text-level-3'>HashTags:</h3>
                    <ul className='text-level-3'>
                      {mergeDetails.tags.length > 0 ? (
                        mergeDetails.tags.map((tag, index) => (
                          <li key={index} style={{ overflowWrap: 'anywhere' }}>
                            {tag}
                          </li>
                        ))
                      ) : (
                        <>No hashtag</>
                      )}
                    </ul>
                    <h3 className='text-dark text-level-3'>Friend Tags:</h3>
                    <ul className='text-level-3'>
                      {mergeDetails.friend_tags.length > 0 ? (
                        mergeDetails.friend_tags.map((frnd_tag, index) => (
                          <li
                            key={index}
                            style={{ overflowWrap: 'anywhere' }}
                            className='text-capitalize'
                          >
                            {frnd_tag.alias}
                          </li>
                        ))
                      ) : (
                        <>No Friend Tag</>
                      )}
                    </ul>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Title:{' '}
                      <span className='fw_500 text-capitalize'>
                        {mergeDetails.title}
                      </span>
                    </p>
                    <p className=' text-dark fw_700 fs-1_25rem p-0 m-0 text-level-3'>
                      Description:{' '}
                      <span className='fw_500 text-capitalize'>
                        {mergeDetails.description
                          ? mergeDetails.description
                          : 'No Description'}
                      </span>
                    </p>
                  </div>
                </div>
                <div className=' d-flex flex-column justify-content-center align-items-center pb-3  fs-1_25rem pt-3'>
                  <h3 className='bg-heading text-capitalize text-level-2'>
                    Background Type:{' '}
                    {mergeDetails.bg_type === 'radiant'
                      ? mergeDetails.gradient_type + ' ' + mergeDetails.bg_type
                      : '' + ' ' + mergeDetails.bg_type}
                  </h3>

                  {mergeDetails.bg_type === 'color' ? (
                    <div className='py-2 w-100'>
                      <div
                        className='color-plate w-100 '
                        style={{
                          background: mergeDetails.background_color,
                          boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                          border: '1px solid rgba(0,0,0,0.35)',
                        }}
                      >
                        {mergeDetails.background_color}
                      </div>
                    </div>
                  ) : mergeDetails.bg_type === 'radiant' ? (
                    <div className='d-flex justify-content-center align-items-center flex-wrap py-2'>
                      {mergeDetails.gradient_colors &&
                      mergeDetails.gradient_colors.length > 0 ? (
                        mergeDetails.gradient_colors.map((clr, index) => (
                          <div className='p-1' key={index}>
                            <div
                              className='color-plate p-2'
                              style={{
                                background: clr,
                                boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                                border: '1px solid rgba(0,0,0,0.35)',
                              }}
                            >
                              {clr === '#000000' ? (
                                <p className='p-0 m-0 text-white'>{clr}</p>
                              ) : (
                                clr
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : mergeDetails.bg_type === 'picture' ? (
                    <div className='w-100 py-2'>
                      <img
                        src={mergeDetails.artist_image.artwork_uri}
                        alt='background'
                        className='nft-prevew-dimensions mx-auto'
                        style={{
                          boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                          border: '1px solid rgba(0,0,0,0.35)',
                          borderRadius: '0.2rem',
                        }}
                      />
                      <div className='py-3 w-100'>
                        <button
                          type='button'
                          className='butn w-100 '
                          onClick={() =>
                            downloadImage(
                              mergeDetails.artist_image.artwork_uri,
                              'background Image - ' + mergeDetails.title
                            )
                          }
                        >
                          Download Background
                        </button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className='w-100 py-2 pt-3'>
                    <h3 className='bg-heading text-capitalize text-center py-2 text-level-3'>
                      Merged Preview
                    </h3>

                    {mergePreviewLoad ? (
                      <PreLoaderComponent color='dark' />
                    ) : (
                      <>
                        <img
                          src={mergePreview}
                          alt='background'
                          className='nft-prevew-dimensions mx-auto '
                          style={{
                            boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                            border: '1px solid rgba(0,0,0,0.35)',
                            borderRadius: '0.2rem',
                          }}
                        />
                        <div className='py-3 w-100'>
                          <button
                            type='button'
                            className='butn w-100 '
                            onClick={() =>
                              downloadImage(
                                mergePreview,
                                'background preview - ' + mergeDetails.title
                              )
                            }
                          >
                            Download Preview
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  {mergeDetails.merged_image && (
                    <div className='w-100 py-2 pt-3'>
                      <h3 className='bg-heading text-capitalize text-center py-2'>
                        Merged NFT
                      </h3>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${mergeDetails.merged_image}`}
                        alt='background'
                        className='nft-prevew-dimensions mx-auto'
                        style={{
                          boxShadow: '0 0 16px rgba(0,0,0,0.35)',
                          border: '1px solid rgba(0,0,0,0.35)',
                          borderRadius: '0.2rem',
                        }}
                      />
                      <div className='py-3 w-100'>
                        <button
                          type='button'
                          className='butn w-100 '
                          onClick={() =>
                            downloadImage(
                              `${process.env.REACT_APP_API_URL}/${mergeDetails.merged_image}`,
                              'Merged NFT'
                            )
                          }
                        >
                          Download NFT
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Merge;
