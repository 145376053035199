import CryptoService from "./crypto.service";

// setSession in local storage
const setSession = (token, user) => {
  localStorage.setItem("token", token);
  const userString = JSON.stringify(user);
  // encrypt user data before store it on the localstorage
  const enc_data = CryptoService.encrypUsertData(userString);
  localStorage.setItem("user", enc_data);
};

// update info
const updateUser = (user) => {
  const userString = JSON.stringify(user);
  // encrypt user data before store it on the localstorage
  const enc_data = CryptoService.encrypUsertData(userString);
  localStorage.setItem("user", enc_data);
};

// clear session of local storage
const clearSession = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

const getCurrentUser = () => {
  const dec_user = localStorage.getItem("user");
  let user;

  if (dec_user && dec_user !== null) {
    user = JSON.parse(CryptoService.dcryptUserData(dec_user));
  }

  return user;
};

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

const StorageService = {
  updateUser,
  setSession,
  clearSession,
  getToken,
  getCurrentUser,
};

// export all functions
export default StorageService;
