import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthService, StorageService } from '../services';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const WalletLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Swal.close();
  }, [location]);

  useEffect(() => {
    if (
      AuthService.isLoggedIn() &&
      StorageService.getCurrentUser().role === 'admin'
    ) {
      navigate('/admin');
    }
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};

export default WalletLayout;
