import React, { useEffect, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Images } from '../constants';
import {
  StorageService,
  WalletService,
  ThirdPartyService,
  NftService,
} from '../services';
import { trustwallet } from '../utils/images';
import Swal from 'sweetalert2';
import useScreen from '../hooks/useScreen';

const NftSwiper = ({
  selectedWallets,
  setSelectedWallets,
  allSelectedWallets,
}) => {
  const [wallets, setWallets] = useState([]);
  const { width } = useScreen();

  const getAllWallets = async () => {
    WalletService.getAllWallets()
      .then((res) => {
        if (res.status) {
          setWallets(res.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAllWallets();
  }, []);

  const displayWalletAdress = (wallet_address) => {
    let wallet_part_a = '',
      wallet_part_b = '';
    let wallet = '';
    for (let i = 0; i < wallet_address.length; i++) {
      if (i <= 6) {
        wallet_part_a += wallet_address[i];
      } else if (i >= wallet_address.length - 7) {
        wallet_part_b += wallet_address[i];
      }
    }
    wallet = wallet_part_a + '...' + wallet_part_b;
    return wallet;
  };

  const storePersonalWalletNfts = (wallet_info, wallet_address) => {
    ThirdPartyService.refreshNFT(wallet_address).then((nfts) => {
      if (nfts) {
        const data = {
          wallet_info: wallet_info,
          wallet_address: wallet_address,
          nfts: nfts,
        };
        NftService.storeNFTList(data)
          .then((res) => {
            if (res.status) {
              //console.info(res.message);
            }
          })
          .catch((error) => {});
      }
    });
  };

  useEffect(() => {
    for (let wallet of wallets) {
      storePersonalWalletNfts('personal', wallet.wallet_address);
    }
  }, [wallets]);

  const handleSelectedWallet = (wallet) => {
    if (allSelectedWallets.length > 0) {
      if (allSelectedWallets.length <= 5) {
        let myWallets = [];
        let isWalletAdressExist = false;
        for (let selectedWalletAddress of selectedWallets) {
          if (selectedWalletAddress === wallet.wallet_address) {
            isWalletAdressExist = true;
          } else if (selectedWalletAddress !== wallet.wallet_address) {
            myWallets.push(selectedWalletAddress);
          }
        }
        if (isWalletAdressExist) {
          return setSelectedWallets(myWallets);
        } else {
          return allSelectedWallets.length < 5
            ? setSelectedWallets((old) => [...old, wallet.wallet_address])
            : Swal.fire(
                'Alert',
                'Oh no! you are trying to exceed wallets limit',
                'info'
              );
        }
      }
    } else {
      return setSelectedWallets((old) => [...old, wallet.wallet_address]);
    }
  };

  const isWalletSelected = (wallet) => {
    let walletExist = false;
    if (selectedWallets.length > 0) {
      for (let mywallet of selectedWallets) {
        if (mywallet === wallet.wallet_address) {
          walletExist = true;
        }
      }
    }
    return walletExist;
  };

  return (
    <div className='px-0 py-3'>
      <Swiper
        className='walletSwiper custom-rounded p-3 h-75'
        spaceBetween={50}
        slidesPerView={1}
        navigation={true}
        modules={[Navigation]}
      >
        {wallets &&
          wallets.length > 0 &&
          wallets.map((wallet, index) => (
            <div key={index}>
              <SwiperSlide
                className={`swipper-item bg-dark swipeWallet cursor-pointer ${
                  isWalletSelected(wallet) ? 'active' : ''
                }`}
                onClick={() => handleSelectedWallet(wallet)}
              >
                <div className=' d-flex flex-column bg-transparent justify-content-around '>
                  <div className='wallet-logo swiper-slide-transparent d-flex flex-column gap-3'>
                    <img
                      src={
                        wallet.wallet_type === 'MetaMask'
                          ? Images.MetaMask
                          : wallet.wallet_type === 'Trust'
                          ? trustwallet
                          : Images.UnkownWallet
                      }
                      alt={wallet.wallet_type}
                      className='wallet-logo-dimension mx-auto'
                    />
                    <div className='d-flex flex-column align-items-center'>
                      {/* text-gray */}

                      <div className='text-level-4 line-hight-14'>
                        {wallet.wallet_type + ' Account Wallet 1'}
                      </div>
                      <div className='sm-d-none text-level-5 text-gray line-hight-1 mb-3'>
                        {wallet.wallet_address.trim()}
                      </div>
                      <div className={`d-none sm-d-block text-level-6 text-gray line-hight-1`}>
                        {displayWalletAdress(wallet.wallet_address.trim())}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          ))}
      </Swiper>
      {allSelectedWallets.length === 5 && (
        <p className=' formating-content text-center text-level-6 text-gray'>
           Max wallet limit reached
        </p>
      )}
    </div>
  );
};

export default NftSwiper;
