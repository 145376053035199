import API from "../../config/api.config";
import axios from "axios";

const signIn = (data) => {
  return API.post("/admin/signin", data).then((res) => {
    return res.data;
  });
};

const forgotPassword = (email) => {
  return API.patch(`/admin/forgot-password/${email}`).then((res) => {
    return res.data;
  });
};

const resetPassword = (data) => {
  return API.patch("/admin/reset-password", data).then((res) => {
    return res.data;
  });
};

const AuthService = {
  signIn,
  resetPassword,
  forgotPassword,
};

export default AuthService;
