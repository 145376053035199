import React, { useState, useEffect, useRef } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NftService, ArtworkService } from '../services';
import PropTypes from 'prop-types';
import { Typography, Box, Modal, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ErrorFormat from '../utils/ErrorFormat;';
import FormValidator from '../utils/validation';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import PreLoaderComponent from './preLoader.component';
import TextFieldWrapper from './TextFieldWrapper';
import TooltipWrapper from './TooltipWrapper';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const SwiperComponent = (props) => {
  const [nfts, setNfts] = useState([]);
  const nftType = props.nftType;
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [alias, setAlias] = useState('');
  const [url, setUrl] = useState('');
  const [email, setEmail] = useState('');
  const [artwork, setArtwork] = useState({});
  const [artworkPreview, setArtworkPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const mergedNfts = async () => {
    setIsLoading(true);
    NftService.allMergedNfts(1, 4)
      .then((res) => {
        setIsLoading(false);
        setNfts(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const likedMergedNfts = async () => {
    setIsLoading(true);
    NftService.allLikedMergedNfts(1, 4)
      .then((res) => {
        setIsLoading(false);
        setNfts(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    nftType === 'Trending' ? likedMergedNfts() : mergedNfts();
  }, [nftType]);

  const style = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '4rem',
    width: '90%',
    bgcolor: '#ffff00',
    boxShadow: 24,
    p: 3,
    borderRadius: '15px',
    '& .MuiTextField-root': { m: 1, width: '25ch' },
  };

  const handleFileInput = (e) => {
    setArtwork(e.target.files[0]);
    setArtworkPreview(URL.createObjectURL(e.target.files[0]));
  };

  const resetForm = () => {
    setAlias('');
    setUrl('');
    setArtwork({});
    setEmail('');
    setArtworkPreview(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let noOfErrors = 0;
    let firstKey;

    Object.values(errors).map((objValue) => {
      if (objValue !== '' && objValue !== null) {
        ++noOfErrors;
      }
    });

    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== '' &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }

    if (noOfErrors > 0) {
      let getFirstErrorElement = document.getElementById(firstKey);
      return getFirstErrorElement.focus();
    } else {
      setIsLoading(true);
      const data = {
        alias: alias,
        website: url,
        email: email,
        artwork: artwork,
      };

      ArtworkService.storeArtWork(data)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            Swal.fire('success', res.message, 'success');
            resetForm();
            handleClose();
            setTimeout(() => {
              navigate('/dashboard');
            }, 2000);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.data.errors) {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: ErrorFormat.validationErrors(error.response.data.errors),
            });
          } else if (error.response.data.message) {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: `<p>${error.response.data.message}</p>`,
            });
          }
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <PreLoaderComponent color='yellow' />
      ) : (
        <div className='p-1'>
          <Swiper
            className='mySwiper custom-rounded artwork-swipper'
            spaceBetween={50}
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
          >
            {nfts &&
              nfts.length > 0 &&
              nfts.map((nft, index) => (
                <div key={index}>
                  <SwiperSlide
                    className='bg-image swiping-item'
                    // style={{
                    //   background: `url(${
                    //     nft.merge_type === "nft"
                    //       ? `${process.env.REACT_APP_ASSET_URL}/${nft.merged_image}`
                    //       : nft.merge_thumnail_url
                    //   })`,
                    // }}
                  >
                    <img
                      src={
                        nft.merge_type === 'nft'
                          ? `${process.env.REACT_APP_ASSET_URL}/${nft.merged_image}`
                          : nft.merge_thumnail_url
                      }
                      alt={nft.title ? nft.title : 'merged nfts'}
                      className='w-100 h-100'
                    />
                    <div className='position-absolute description text-level-5'>
                      <div className='title px-2' data-swiper-parallax='-300'>
                        {nft.title
                          ? nft.title.length > 24
                            ? nft.title.substr(0, 22).trim() + '...'
                            : nft.title.trim()
                          : 'merged nfts'}
                      </div>
                      {/* <div
                        className='subtitle px-2 text-level-6'
                        data-swiper-parallax='-200'
                      >
                        {nft.tags &&
                          nft.tags.length > 0 &&
                          nft.tags.map((tag, index) => (
                            <TooltipWrapper title={tag}>
                              <text>
                                {index < 4
                                  ? `#${
                                      tag && tag.length > 5
                                        ? tag.substring(0, 5) + '...'
                                        : tag
                                    } `
                                  : ''}
                              </text>
                            </TooltipWrapper>
                          ))}
                      </div> */}
                    </div>
                  </SwiperSlide>
                </div>
              ))}
          </Swiper>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
              className='modal-background overflow-y-auto'
            >
              <Box
                sx={style}
                className=''
                autoComplete='off'
                component='form'
                onSubmit={(e) => handleSubmit(e)}
                encType='multipart/form-data'
              >
                <div className='d-flex justify-content-between modal-header py-0 my-0'>
                  <h3 className='modal-title fw_bolder'>Add Artwork</h3>
                  <button
                    type='button'
                    className='modal-butn fw_bolder'
                    onClick={() => handleClose()}
                  >
                    x
                  </button>
                </div>
                <div className='px-1 py-4 '>
                  <p className='modal-p'>
                    Lorem ipsum dolar sit amet. lorem ipsum dolar sit amet.
                    lorem ipsum dolar sit amet. lorem sit amet.
                  </p>
                </div>
                <input
                  type='file'
                  ref={fileInput}
                  onChange={(e) => handleFileInput(e)}
                  hidden
                  name='artwork'
                  id='artwork'
                  required={true}
                  accept='image/png, image/jpg, image/jpeg'
                />
                <div className='d-flex flex-column justify-content-center align-items-center '>
                  <button
                    type='button'
                    className='upload-profile-btn w-100 mb-3'
                    onClick={() => fileInput.current.click()}
                  >
                    <i className='fa-solid fa-circle-plus pe-2'></i>
                    Upload Artwork
                  </button>
                  <TextFieldWrapper
                    id='name'
                    name='name'
                    label='Alias'
                    //placeholder='Tell us Artistname'
                    className='text-field mui-modal-input'
                    value={alias}
                    onChange={(e) => {
                      setAlias(e.target.value.replace(/^[0-9]/, ''));
                      FormValidator.handleOnBlur(e, setErrors);
                    }}
                    required={true}
                    onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  />
                  {errors && errors.name && (
                    <div className='error ps-2'>
                      <span className='error-span'>
                        <i className='fa-solid fa-circle-exclamation pe-1'></i>
                        {errors.name}
                      </span>
                    </div>
                  )}
                  <TextFieldWrapper
                    id='url2'
                    name='url2'
                    label='URL'
                    className='text-field mui-modal-input'
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                      FormValidator.handleOnBlur(e, setErrors);
                    }}
                    //placeholder='Artist Website'
                    required={true}
                    onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  />
                  {errors && errors.url2 && (
                    <div className='error ps-2'>
                      <span className='error-span'>
                        <i className='fa-solid fa-circle-exclamation pe-1'></i>
                        {errors.url2}
                      </span>
                    </div>
                  )}
                  <TextFieldWrapper
                    id='email'
                    name='email'
                    label='E-Mail (optional)'
                    defaultValue='E-Mail'
                    className='text-field mui-modal-input'
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      FormValidator.handleOnBlur(e, setErrors);
                    }}
                    //placeholder='E-Mail'
                    onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                  />
                  {errors && errors.email && (
                    <div className='error ps-2'>
                      <span className='error-span'>
                        <i className='fa-solid fa-circle-exclamation pe-1'></i>
                        {errors.email}
                      </span>
                    </div>
                  )}

                  {artworkPreview && (
                    <div className='py-3'>
                      <img
                        src={artworkPreview}
                        alt='artwork preview'
                        className='artwork-preview p-1'
                      />
                    </div>
                  )}

                  <div className='py-3 w-100'>
                    <button
                      type='submit'
                      className='butn btn-f-small w-100'
                      disabled={isLoading}
                    >
                      Upload Artwork
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default SwiperComponent;
