import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import StorageService from '../services/storage.service';
import AuthService from '../services/auth.service';
import logo from '../logo.svg';
import {
  Button,
  Drawer,
  Box,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SegmentRoundedIcon from '@mui/icons-material/SegmentRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import WalletRoundedIcon from '@mui/icons-material/WalletRounded';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';

const StickyHeaderComponent = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const logout = () => {
    StorageService.clearSession();
    navigate('/connect-to-wallet');
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
      }}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <NavLink
          to={'/'}
          state={{ tabs: 0 }}
          onClick={() => toggleDrawer(anchor, false)}
        >
          <ListItem
            disablePadding
            // onClick={() => {
            //   // navigate("/", {state: {tabs:0}});
            //   toggleDrawer(anchor, false);
            // }}
          >
            <ListItemButton>
              <ListItemIcon>
                <img
                  src={logo}
                  className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root mui-border'
                />
              </ListItemIcon>
              <ListItemText primary='Home' className='mui-side-text' />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink to={'/dashboard'} onClick={() => toggleDrawer(anchor, false)}>
          <ListItem
            disablePadding
            // onClick={() => {
            //   navigate("/dashboard");
            //   toggleDrawer(anchor, false);
            // }}
          >
            <ListItemButton>
              <ListItemIcon>
                <GridViewRoundedIcon />
              </ListItemIcon>
              <ListItemText
                primary='Personal Dashboard'
                className='mui-side-text'
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink
          to={'/create-merge-request'}
          onClick={() => toggleDrawer(anchor, false)}
        >
          <ListItem
            disablePadding
            // onClick={(e) => {
            //   navigate("/create-merge-request");
            //   toggleDrawer(anchor, false);
            // }}
          >
            <ListItemButton>
              <ListItemIcon>
                <WalletRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Create Merge' className='mui-side-text' />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink
          to={'/upload-artwork'}
          onClick={() => toggleDrawer(anchor, false)}
        >
          <ListItem
            disablePadding
            // onClick={(e) => {
            //   navigate("/upload-artwork");
            //   toggleDrawer(anchor, false);
            // }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ArtTrackIcon />
              </ListItemIcon>
              <ListItemText
                primary='Upload Artwork'
                className='mui-side-text'
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink to={'/settings'} onClick={() => toggleDrawer(anchor, false)}>
          <ListItem
            disablePadding
            onClick={(e) => {
              navigate('/settings');
              toggleDrawer(anchor, false);
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Setting' className='mui-side-text' />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        <NavLink to={'/wallets'} onClick={() => toggleDrawer(anchor, false)}>
          <ListItem
            disablePadding
            // onClick={(e) => {
            //   navigate("/wallets");
            //   toggleDrawer(anchor, false);
            // }}
          >
            <ListItemButton>
              <ListItemIcon>
                <AccountBalanceWalletRoundedIcon />
              </ListItemIcon>
              <ListItemText
                primary='Wallet Address Book'
                className='mui-side-text'
              />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <Divider />
        <ListItem
          disablePadding
          onClick={(e) => {
            logout();
            toggleDrawer(anchor, false);
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Logout' className='mui-side-text' />
          </ListItemButton>
        </ListItem>
        <Divider />
      </List>
    </Box>
  );

  return (
    <div
      className='page-title page-title-fixed px-4 justify-content-between align-items-center w-100 bg-dark'
      style={{ zIndex: 1000 }}
    >
      <div
        className='w-100 cursor-pointer flex-grow-1 '
        onClick={() => navigate('/', { state: { tabs: 0 } })}
      >
        <h3 style={{marginBottom:'0px'}} className='theme-text-color fw-light d-flex justify-content-start align-items-center logo-content'>
          <img
            src={logo}
            height='40px'
            width='40px'
            style={{ paddingRight: '6px' }}
          />
          <span className="sm-hidden theme-text-color">Vintage Neighbors</span>
        </h3>
      </div>
      {AuthService.isLoggedIn() ? (
        <div className='d-flex justify-content-end'>
          <Button onClick={toggleDrawer('right', true)}>
            <SegmentRoundedIcon className='mui-nav-icon-user' />
          </Button>
          <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
          >
            {list('right')}
          </Drawer>
        </div>
      ) : (
        <div className='w-100 d-flex justify-content-end'>
          <Link
            to='/connect-to-wallet'
            className='shadow-xl btn btn-warning btn-sm theme-bg text-dark fw-bold border-0 py-2'
          >
            Connect wallet
          </Link>
        </div>
      )}
    </div>
  );
};

export default StickyHeaderComponent;
