import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { trustwallet } from '../../../utils/images';
import { useNavigate } from 'react-router-dom';
import { WalletService, AuthService, StorageService } from '../../../services';
import { Images } from '../../../constants';
import ErrorFormat from '../../../utils/ErrorFormat;';
import Swal from 'sweetalert2';
import { PreLoaderComponent } from '../../../components';

const WalletDetailsCompoentns = () => {
  const navigate = useNavigate();
  const [wallets, setWallets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      navigate('/', { replace: true });
    }
  }, []);

  const getAllWallets = async () => {
    setIsLoading(true);
    WalletService.getAllWallets()
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          setWallets(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            StorageService.clearSession();
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            });
            navigate('/connect-to-wallet');
            return window.location.reload();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: `<p>${error.response.data.message}</p>`,
            });
          }
        }
      });
  };

  useEffect(() => {
    getAllWallets();
  }, []);

  const removeWallet = (wallet) => {
    setIsLoading(true);
    const data = {
      wallet_type: wallet.wallet_type,
      wallet_address: wallet.wallet_address,
    };

    WalletService.removeWallet(data)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          Swal.fire('success', res.message, 'success');
          getAllWallets();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.data.errors) {
          Swal.fire({
            icon: 'error',
            title: 'Error occurred!',
            html: ErrorFormat.validationErrors(error.response.data.errors),
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === 'User account deactivated') {
            StorageService.clearSession();
            Swal.fire({
              icon: 'warning',
              title: 'Inactive account!',
              html: `<p>Please contact info@nftneighbors.com to activate your account.</p>`,
            });
            navigate('/connect-to-wallet');
            return window.location.reload();
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Unable to delete wallet!',
              html: 'At least one wallet is required to login again.' /* `<p>${error.response.data.message}</p>`, */,
            });
          }
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <PreLoaderComponent />
      ) : (
        <div className='d-flex justify-content-center'>
          {/* <Swiper
            className='mySwiper custom-rounded p-3'
            spaceBetween={50}
            slidesPerView={1}
            navigation={true}
            modules={[Navigation]}
          >
            {wallets &&
              wallets.length > 0 &&
              wallets.map((wallet, index) => (
                <div key={index}>
                  <SwiperSlide className='swipper-item'>
                    <div className='h-100 w-100 d-flex flex-column bg-transparent justify-content-around '>
                      <div className='wallet-logo swiper-slide-transparent'>
                        <img
                          src={
                            wallet.wallet_type === 'MetaMask'
                              ? Images.MetaMask
                              : wallet.wallet_type === 'Trust'
                              ? trustwallet
                              : Images.UnkownWallet
                          }
                          alt={wallet.wallet_type}
                          className='mx-auto'
                        />
                      </div>
                      <h3 className='swipper-title text-level-2'>
                        {wallet.wallet_type}
                      </h3>
                      <button
                        className='butn mb-3'
                        onClick={() => removeWallet(wallet)}
                      >
                        Remove Wallet
                      </button>
                    </div>
                  </SwiperSlide>
                </div>
              ))}
          </Swiper> */}
          <Swiper
            className='square-swiper'
            spaceBetween={50}
            slidesPerView={1}
            navigation={true}
            modules={[Navigation]}
          >
            {wallets &&
              wallets.length > 0 &&
              wallets.map((wallet, index) => (
                <div key={index}>
                  <SwiperSlide className='swipper-item '>
                    <div className='square-swiper h-100 d-flex flex-column bg-transparent justify-content-around'>
                      <div className='wallet-logo swiper-slide-transparent square-swiper'>
                        <img
                          src={
                            wallet.wallet_type === 'MetaMask'
                              ? Images.MetaMask
                              : wallet.wallet_type === 'Trust'
                              ? trustwallet
                              : Images.UnkownWallet
                          }
                          alt={wallet.wallet_type}
                          className='mx-auto'
                          style={{width:'60%'}}
                        />
                      </div>
                      <div className='d-flex justify-content-center flex-column'>
                        <h3 className='swipper-title text-level-2'>
                          {wallet.wallet_type}
                        </h3>
                        <button
                          className='butn'
                          onClick={() => removeWallet(wallet)}
                        >
                          Remove Wallet
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
              ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default WalletDetailsCompoentns;
